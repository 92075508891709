import React, { useContext, useEffect, useMemo, useReducer, useState } from "react";
import { ActivityIndicator, Image, Platform, StyleSheet, Text, TouchableOpacity, View } from "react-native";

import { createBottomTabNavigator } from "@react-navigation/bottom-tabs";

import MainBottomNavBar from "../components/MainBottomNavBar";
import StackContent from "./StackContent";
import StackForum from "./StackForum";
import StackProfile from "./StackProfile";

import { Ionicons } from "@expo/vector-icons";
import { Audio } from "expo-av";
import * as Haptics from "expo-haptics";
import { LinearGradient } from "expo-linear-gradient";
import * as Animatable from "react-native-animatable";

import AuthContext from "../context/AuthContext";
import PointsContext from "../context/PointsContext";

import Portal from "@burstware/react-native-portal";
import { getFirestore, increment, updateDoc } from "firebase/firestore";
import SecureImage from "../components/common/SecureImage";
import HeaderWeb from "../components/HeaderWeb";
import StackSmartNew from "./StackSmartNew";

const Tab = createBottomTabNavigator();

export default function App() {
	const { userData, tenantSelector, authFunctions, tenant, tenants, dimensions, dispatchUserData } = useContext(AuthContext);
	const db = getFirestore()
	const [state, dispatch] = useReducer(
		(prevState, action) => {
			switch (action.type) {
				case "POINTS":
					return {
						...prevState,
						showPoints: action?.showPoints,
						message: action?.message,
						points: action?.points,
						userPoints: action?.userPoints,
					};
				case "ADVANCE":
					return {
						...prevState,
						advance: [...prevState.advance, ...action?.advance],
						advanceRef: action?.advanceRef,
						courseRef: action?.courseRef,
					};
			}
		},
		{
			showPoints: null,
			message: "",
			points: 0,
			advance: [],
			advanceRef: "",
			courseRef: "",
		}
	);
	const [error, setError] = useState(false);
	const [loading, setLoading] = useState(true);

	const pointsContext = useMemo(
		() => ({
			givePoints: async (data) => {
				Platform.OS !== "web" && Haptics.impactAsync("medium");
				Platform.OS !== "web" && pointsSound();
				updateDoc(userData.ref, {
					points: increment(data.points)
				}).then(() => {
					dispatch({ type: "POINTS", showPoints: true, points: data.points, message: data.message });
				});
				setTimeout(() => {
					dispatch({ type: "POINTS", showPoints: null });
				}, 3000);
			},
		}),
		[]
	);


	useEffect(() => {
		tenant && setLoading(false);
	}, [tenant]);


	const checkUserTrophies = (trophies, userPoints) => {
		const tenantTrophies = [...trophies];
		const updatedUserTrophies = tenantTrophies.filter((trophy) => parseInt(trophy.pointsToAchieve) <= parseInt(userPoints)).map((trophy) => (!userData.achievements.includes(trophy) ? trophy : null));
		const userTrophies = [...userData?.achievements, ...updatedUserTrophies];
		return userTrophies;
	};

	const pointsSound = async () => {
		const soundObject = new Audio.Sound();
		try {
			await soundObject.loadAsync(require("../assets/sounds/Points.mp3"));
			await soundObject.playAsync();
			// Your sound is playing!
		} catch (error) {
			// An error occurred!
		}
	};


	return (
		<Portal.Host>
			<PointsContext.Provider value={{ pointsContext, state, dispatch, givePoints: pointsContext.givePoints }}>
				{loading ? (
					<View style={{ flex: 1, alignItems: "center", justifyContent: "center" }}>
						<ActivityIndicator size="large" />
					</View>
				) : error ? (
					<>
						<HeaderWeb index={0} onlyLogout />
						<View style={{ flex: 1, alignItems: "center", justifyContent: "center" }}>
							<Image source={{ uri: "https://media.tenor.com/images/b276eb1262c2ae17a7d94929051d7a9d/tenor.gif" }} style={{ width: 300, height: 300, resizeMode: "contain" }} />
							<Text style={{ fontFamily: "DemiBold", fontSize: 22 }}>Ha ocurrido un error al cargar tu itinerario</Text>
						</View>
					</>
				) : (
					tenant ? (
						<Tab.Navigator tabBar={(props) => (Platform.OS === "web" && dimensions.width > 1000 ? null : <MainBottomNavBar titles={false} {...props} />)}>
							{/* <Tab.Screen name="StackPublic" component={StackPublic} /> */}
							<Tab.Screen name="StackSmartNew" component={StackSmartNew} />
							<Tab.Screen name="StackContent" component={StackContent} />
							<Tab.Screen name="StackForum" component={StackForum} />
							<Tab.Screen name="StackProfile" component={StackProfile} />
						</Tab.Navigator>) : (
						null
					)
				)}

				{tenantSelector && (
					<>
						<Animatable.View duration={200} animation="fadeIn" style={{ backgroundColor: "rgba(0,0,0,.6)", position: 'absolute', top: 0, left: 0, right: 0, bottom: 0 }} />
						<Animatable.View duration={300} animation={tenantSelector ? "fadeInDown" : "fadeOutUp"} style={styles.popup}>

							<View style={{ flex: 1 }}>
								<Text style={{ fontFamily: "DemiBold", fontSize: 22 }}>Selecciona tu espacio de trabajo</Text>
								{tenants?.map((tenant, index) => (
									<TouchableOpacity key={index} onPress={() => authFunctions.changeTenant(tenant, userData)}>
										<View style={{ flexDirection: "row", alignItems: "center", padding: 10, borderBottomWidth: 1, borderBottomColor: "#ccc" }}>
											<View style={{ backgroundColor: tenant?.customization?.mainColor, padding: 10, width: 50, height: 50, borderRadius: 25, marginRight: 15 }}>
												<SecureImage uri={tenant?.customization?.mainLogo || "https://images.pexels.com/photos/2047905/pexels-photo-2047905.jpeg?auto=compress&cs=tinysrgb&dpr=2&h=750&w=1260"} resizeMode="contain" style={{ resizeMode: "contain", flex: 1 }} />
											</View>
											<Text style={{ fontFamily: "DemiBold", fontSize: 18 }}>{tenant.name}</Text>
										</View>
									</TouchableOpacity>
								))}
							</View>
						</Animatable.View>
					</>
				)}
				{state.showPoints !== null && (
					<Animatable.View duration={300} animation={state?.showPoints === true ? "fadeInDown" : "fadeOutUp"} style={styles.popup}>
						<LinearGradient colors={["#F49D44", "#FED25A"]} style={{ marginRight: 10, height: 30, width: 30, borderRadius: 15, backgroundColor: "#FCB84F", alignItems: "center", justifyContent: "center" }}>
							<Ionicons name={"ios-star"} size={19} style={{ color: "white" }} />
						</LinearGradient>
						<View style={{ flex: 1 }}>
							<Text style={{ fontFamily: "DemiBold", fontSize: 16 }}>{`Ganaste: ${state.points} puntos`}</Text>
							<Text style={{ fontFamily: "DemiBold", fontSize: 16 }}>{state.message}</Text>
						</View>
					</Animatable.View>
				)}
			</PointsContext.Provider>
		</Portal.Host>
	);
}

const styles = StyleSheet.create({
	popup: {
		flexDirection: "row",
		position: "absolute",
		top: 50,
		backgroundColor: "white",
		alignItems: "center",
		width: "95%",
		maxWidth: 400,
		marginLeft: "2.5%",
		paddingVertical: 15,
		paddingHorizontal: 10,
		borderRadius: 10,
		shadowColor: "#000",
		shadowOffset: {
			width: 0,
			height: 6,
		},
		shadowOpacity: 0.39,
		shadowRadius: 8.3,

		elevation: 13,
	},
});
