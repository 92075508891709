import { Platform } from 'react-native'
import React from 'react'
import Header from '../components/AppHeader'

import { createStackNavigator } from '@react-navigation/stack';
const Stack = createStackNavigator();

import ProfileHome from "../screens/stackProfile/ProfileHome";
import EditProfile from "../screens/stackProfile/EditProfile";
import MyReviews from "../screens/stackProfile/MyReviews";
import HeaderWeb from '../components/HeaderWeb';

const StackProfile = () => {
    return (
        <Stack.Navigator
            headerMode="screen"
            detachInactiveScreens={true}
            screenOptions={{
                cardStyle: { flex: 1 },
                header: ({ scene, previous, navigation }) => {
                    // console.log("scene", previous)
                    return (
                        Platform.OS === "web" ?
                            <HeaderWeb navigation={navigation} />
                            :
                            <Header
                                navigation={navigation}
                                logo={previous ? false : true}
                                backCallback={() => navigation.goBack()}
                                backButton={previous ? true : false}
                                editProfile={true}
                                logout={true}
                            />
                    )
                }
            }}
        >
            <Stack.Screen name="ProfileHome" component={ProfileHome} options={{ title: "Mi Perfil" }} />
            <Stack.Screen name="MyReviews" component={MyReviews} options={{ header: ({ scene, previous, navigation }) => <Header navigation={navigation} backCallback={() => navigation.goBack()} backButton={true} /> }} />
            <Stack.Screen name="EditProfile" component={EditProfile} />
        </Stack.Navigator>
    );
}

export default StackProfile;