import { LinearGradient } from 'expo-linear-gradient';
import React from 'react';
import { Animated, View } from 'react-native';
// import console = require('console');

const LinearBarIndicator = ({ animationSpeed = 700, styles, height = 10, rounded = 5, width = null, percentage = 0, backgroundColor = "gainsboro", fillColor = "green", gradient = null }) => {

    // const [value] = useState(new Animated.Value(0))

    // useEffect(() => {
    //     Animated.timing(value, {
    //         toValue: percentage,
    //         duration: animationSpeed,
    //     }).start()
    // }, [percentage])

    // const animatedWidth = value.interpolate({
    //     inputRange: [0, 100],
    //     outputRange: ['0%', '100%'],
    // })

    // console.log(animatedWidth)

    return (
        <View
            style={[{
                // flex: 1, 
                backgroundColor: backgroundColor,
                height: height,
                width: width,
                borderRadius: rounded
            }, { ...styles }]}>
            {gradient === null && <Animated.View style={{ height: height, width: animatedWidth, borderRadius: rounded, backgroundColor: fillColor }} />}
            {gradient !== null && <Animated.View style={{ width: (percentage || 0) + "%", height: height, borderRadius: rounded }}><LinearGradient start={[1, 1]} end={[0, 0]} colors={gradient} style={{ flex: 1, borderRadius: rounded }} /></Animated.View>}
        </View>
    )
}

export default LinearBarIndicator;