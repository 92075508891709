
import { collection, getDocs, getFirestore, limit, orderBy, query } from 'firebase/firestore';
import React, { useContext, useEffect, useState } from 'react';
import { TouchableOpacity, View, StyleSheet } from 'react-native';
import AuthContext from '../../context/AuthContext';
import StyledText from '../common/StyledText';

const CourseRanking = ({ id }) => {

    const { tenant } = useContext(AuthContext)
    const [ranking, setRanking] = useState([])

    const db = getFirestore()

    useEffect(() => {
        if (id) {
            const q = query(collection(db, `${tenant.path}/courses/${id}/courseavs/`), orderBy("points", "desc"), limit(5))

            getDocs(q).then((querySnapshot) => {
                const ranking = querySnapshot.docs.map(doc => ({ ...doc.data(), id: doc.id }))
                setRanking(ranking)
            },
                (err) => {
                    console.log("ERROR", err)
                }
            );
        }
    }, [id])

    return (
        <>
            <View style={{ flexDirection: "row", alignItems: "center", justifyContent: "space-between", marginTop: 30, marginBottom: 10 }}>

                <StyledText style={{ fontFamily: "Bold", fontSize: 20 }}>Ranking del curso</StyledText>

                {/* <TouchableOpacity onPress={() => navigation.navigate("StackForum", { screen: "SingleForum", params: { id: userData.course?.forumRef.id } })} style={{ borderWidth: 3, borderRadius: 6, alignItems: "center", justifyContent: "center", width: 100, height: 35 }}>
                    <StyledText style={{ fontFamily: "Bold" }}>Todos</StyledText>
                </TouchableOpacity> */}
            </View>


            {/* RANKINGS */}
            <View style={[styles.shadow, { padding: 15, paddingHorizontal: 20, backgroundColor: "white", borderRadius: 15 }]}>
                {ranking?.length === 0 ? (
                    <View style={{ minHeight: 100, width: "100%", alignItems: "center", justifyContent: "center" }}>
                        <StyledText style={{ opacity: 0.5, fontSize: 16, fontFamily: "DemiBold" }}>No hay usuarios en el ranking</StyledText>
                    </View>
                ) : (
                    ranking?.map((el, i) => {
                        // console.log(el.points)
                        return (
                            <View key={i} style={{ flexDirection: "row", alignItems: "center" }}>
                                <View style={{ width: 40 }}>
                                    <StyledText style={{ fontSize: 34, fontFamily: "Bold" }}>{i + 1}</StyledText>
                                </View>
                                <View style={{ position: "relative", flex: 1 }}>
                                    <StyledText style={{ fontSize: 16, fontFamily: "DemiBold" }}>
                                        {el.user?.name?.trim()} {el?.user?.surname}
                                    </StyledText>
                                </View>
                                <View style={{ backgroundColor: "#f2f2f2", alignItems: "flex-end", paddingHorizontal: 10, justifyContent: "center", borderRadius: 7, height: 30 }}>
                                    <StyledText style={{ fontSize: 14, fontFamily: "DemiBold" }}>{el.points}</StyledText>
                                </View>
                            </View>
                        );
                    })
                )}
            </View>
        </>
    );
}

export default CourseRanking;

const styles = StyleSheet.create({
    shadow: {
        shadowColor: "#000",
        shadowOffset: {
            width: 0,
            height: 5,
        },
        shadowOpacity: 0.1,
        shadowRadius: 6.68,

        elevation: 11,
    },
});