import React, { useState, useEffect } from "react";
import { ImageBackground, View, Image } from "react-native";
import { getDownloadURL, getStorage, ref } from "firebase/storage";

const ImageRel = ({ source, background = false, imageStyle = {}, placeholder = "", backgroundColor = "gainsboro", style, ratio, children, resizeMode = "cover", onLoad, onLoadEnd }) => {

  const [signed, setSigned] = useState("data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAAEAAAABCAQAAAC1HAwCAAAAC0lEQVR42mNkYAAAAAYAAjCB0C8AAAAASUVORK5CYII=")
  const storage = getStorage()

  useEffect(() => {
    if (source) {
      if (source.includes("http")) {
        setSigned(source)
      } else {
        const imageRef = ref(storage, source)
        getDownloadURL(imageRef).then(url => {
          setSigned(url)
        }).catch(err => {
          setSigned(placeholder)
        })
      }
    }
  }, [source])

  if (background) {
    return (
      <ImageBackground
        key={signed}
        imageStyle={{ ...imageStyle }}
        style={{ ...style, backgroundColor: backgroundColor }}
        source={{ uri: signed ? signed : "data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAAEAAAABCAQAAAC1HAwCAAAAC0lEQVR42mNkYAAAAAYAAjCB0C8AAAAASUVORK5CYII=" }}
      >
        {children}
      </ImageBackground>
    );
  } else {
    return (
      <View style={{ ...style, aspectRatio: ratio, backgroundColor: backgroundColor }}>
        <Image
          // {...props}
          style={{ ...style, flex: 1, resizeMode: resizeMode }}
          key={signed}
          onLoadEnd={onLoadEnd}
          onLoad={onLoad}
          source={{ uri: signed ? signed : "data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAAEAAAABCAQAAAC1HAwCAAAAC0lEQVR42mNkYAAAAAYAAjCB0C8AAAAASUVORK5CYII=" }}
        />
      </View>
    )
  }
};

export default ImageRel;
