import React, { useState, useContext } from "react";
import * as Navigation from "@react-navigation/native";
import CreateTopicForum from "../../components/forumComponents/CreateTopicForm";

import AuthContext from "../../context/AuthContext";
import { addDoc, collection, doc, getFirestore, serverTimestamp } from "firebase/firestore";

const CreateTopicPage = ({ id, filtro, navigation, route }) => {


    const { userData, tenant } = useContext(AuthContext)
    const [body, setBody] = useState("")
    const [loading, setLoading] = useState(false)
    const [title, setTitle] = useState("")
    const { navigate } = Navigation.useNavigation();
    const forumID = route.params.id

    const db = getFirestore()

    const sendNewTopic = () => {
        setLoading(true)
        const variables = {
            title,
            body,
            user: {
                id: userData.id,
                name: userData.name,
                image: `${tenant.id}/users/${userData.id}/profilePicture`
            },
            tenant: tenant,
            responses: 1,
            forum: doc(db, `${tenant.path}/forums/${forumID}`),
            lastActive: serverTimestamp(),
        }
        console.log(variables)
        const colRef = collection(db, `${tenant.path}/forums/${forumID}/topics`)

        addDoc(colRef, variables)
            .then(ev => {
                navigate("SingleTopic", {
                    id: ev.id,
                    forumID
                })
                setLoading(false)
            })
            .catch(err => {
                alert("Ha ocurrido un error al crear este hilo")
                setLoading(false)
            })
    }

    return <CreateTopicForum title={title} setTitle={title => setTitle(title)} loading={loading} body={body} setBody={body => setBody(body)} sendNewTopic={() => sendNewTopic()} styles={styles} />;
};

const styles = {
    btn: {
        height: 35,
        width: 100,
        borderWidth: 1,
        borderColor: "gainsboro",
        borderRadius: 4,
        display: "flex",
        justifyContent: "center",
        alignItems: "center"
    }
}

export default CreateTopicPage