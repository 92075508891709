import * as ImagePicker from 'expo-image-picker';
import React, { useContext, useEffect, useState } from "react";
import {
    ActivityIndicator, Alert, KeyboardAvoidingView, Platform, ScrollView, StyleSheet, TextInput,
    TouchableOpacity, View
} from "react-native";
import AuthContext from '../../context/AuthContext';

import { useSafeAreaInsets } from 'react-native-safe-area-context';


import { Ionicons } from "@expo/vector-icons";
import { getDoc, getFirestore, serverTimestamp, updateDoc } from "firebase/firestore";
import { getStorage, ref, uploadBytes } from "firebase/storage";
import Button from "../../components/common/Button";
import SecureImage from "../../components/common/SecureImage";
import StyledText from "../../components/common/StyledText";

const EditProfile = ({ navigation }) => {


    const { userData, tenant, dispatchUserData, customization } = useContext(AuthContext)
    const db = getFirestore()
    const storage = getStorage()

    const [pass, setPass] = useState({});
    const [loadingImage, setLoadingImage] = useState(false);
    const [form, setForm] = useState({});
    const [data, setData] = useState({});
    const [formPass, setFormPass] = useState({})
    const [loading, setLoading] = useState({})
    const [saving, setSaving] = useState(false)
    const [tab, setTab] = useState("Profile")
    const inset = useSafeAreaInsets()

    useEffect(() => {
        setLoading(true)

        getDoc(userData.ref).then(async (doc) => {
            setData({ id: doc.id, ...doc.data(), ref: doc.ref })
            setForm({ id: doc.id, ...doc.data(), ref: doc.ref })
            setLoading(false)
        })
    }, [])


    const editPersonalProfile = () => {
        setSaving(true)
        const variables = {
            ...(form.team !== data.team && { team: form.team }),
            ...(form.position !== data.position && { position: form.position }),
            ...(form.bio !== data.bio && { bio: form.bio }),
            ...(form.skills !== data.skills && { skills: form.skills }),
            ...(form.hobbies !== data.hobbies && { hobbies: form.hobbies })
        }
        form.image !== data.image ?
            uploadImage(form.image).then(url => {
                updateDoc(userData.ref, {
                    ...variables,
                    image: `${userData.ref.path}/profilePicture`,
                    updatedAt: serverTimestamp()
                }).then(res => {
                    setSaving(false)
                    dispatchUserData({
                        type: "UPDATE_DATA", data: {
                            ...userData, ...variables,
                            image: `${userData.ref.path}/profilePicture`,
                        }
                    });
                    navigation.goBack()
                })
            })
            :
            updateDoc(userData.ref, variables).then(res => {
                setSaving(false)
                dispatchUserData({
                    type: "UPDATE_DATA", data: {
                        ...userData, ...variables
                    }
                });
                navigation.goBack()
            })


    }

    const changeImage = async () => {
        setLoadingImage(true)
        let result = await ImagePicker.launchImageLibraryAsync({
            mediaTypes: ImagePicker.MediaTypeOptions.All,
            allowsEditing: true,
            aspect: [4, 3],
            quality: 1,
        });

        if (!result.cancelled) {
            setForm({ ...form, image: result.uri })
        } else {
            Alert.alert("Ha ocurrido un error cargando la imagen")
        }
        setLoadingImage(false)
    };

    const uploadImage = async (uri) => {
        let result = await fetch(uri)
        const blob = await result.blob();

        var storageRef = ref(storage, `${userData.ref.path}/profilePicture`)
        uploadBytes(storageRef, blob).then((snapshot) => snapshot.ref.fullPath).catch(err => err);
        return data
    }


    if (loading) {
        return (
            <View style={{ flex: 1, alignItems: "center", justifyContent: "center" }}>
                <ActivityIndicator size="large" />
            </View>
        );
    }
    return (
        <View style={{
            flex: 1,
            backgroundColor: "white"
        }}>
            <KeyboardAvoidingView behavior="position" style={{ flex: 1 }}>
                <ScrollView showsVerticalScrollIndicator={false} contentContainerStyle={{ flexGrow: 1, width: "100%", maxWidth: 600, alignSelf: "center", paddingBottom: inset.bottom }}>

                    <View style={{ flex: 1, paddingHorizontal: 10, paddingTop: 0, paddingBottom: 30 }}>
                        <TouchableOpacity
                            onPress={() => changeImage()}
                            style={[
                                styles.shadow,
                                {
                                    backgroundColor: "white",
                                    marginVertical: 10,
                                    borderRadius: 15,
                                    width: Platform.OS === "web" ? "100%" : null,
                                    maxWidth: 900
                                }
                            ]}
                        >
                            <SecureImage style={{ height: 200, borderRadius: 15 }} uri={form.image} />
                            <View
                                style={{
                                    position: "absolute",
                                    alignItems: "center",
                                    justifyContent: "center",
                                    top: 0,
                                    left: 0,
                                    right: 0,
                                    bottom: 0,
                                    borderRadius: 15,
                                    backgroundColor: "rgba(0,0,0,.4)"
                                }}
                            >
                                <View style={{ alignItems: "center" }}>
                                    {loadingImage ?
                                        <ActivityIndicator color="white" size="large" />
                                        :
                                        <>
                                            <Ionicons size={60} color={"white"} name={"md-images"} />
                                            <StyledText style={{ fontFamily: "DemiBold", color: "white", fontSize: 18 }}>Cambiar Imagen</StyledText>
                                        </>
                                    }
                                </View>
                            </View>
                        </TouchableOpacity>

                        <View style={{ borderRadius: 20, backgroundColor: "#f5f5f5", paddingHorizontal: 10, paddingVertical: 20 }}>

                            <StyledText style={{ marginLeft: 10, fontSize: 16, fontFamily: "DemiBold", marginBottom: 10 }}>Equipo</StyledText>
                            <TextInput
                                placeholder="Equipo"
                                value={form.team}
                                onChangeText={team => setForm({ ...form, team })}
                                style={styles.input}
                            />
                            <StyledText style={{ marginLeft: 10, fontSize: 16, fontFamily: "DemiBold", marginVertical: 10 }}>Puesto</StyledText>
                            <TextInput
                                placeholder="Puesto"
                                value={form.position}
                                onChangeText={position => setForm({ ...form, position })}
                                style={styles.input}
                            />
                            <StyledText style={{ marginLeft: 10, fontSize: 16, fontFamily: "DemiBold", marginVertical: 10 }}>Sobre Mi</StyledText>
                            <TextInput
                                placeholder="bio"
                                value={form.bio}
                                // textAlignVertical="top"
                                multiline
                                maxLength={250}
                                onChangeText={bio => setForm({ ...form, bio })}
                                style={[styles.input, { borderRadius: 10, height: 130, paddingTop: 15, lineHeight: 20 }]}
                            />
                            <StyledText style={{ fontFamily: "Regular", fontSize: 10, textAlign: "right", marginTop: 5, color: form.bio ? form.bio.length <= 200 ? "gray" : form.bio.length >= 225 ? "red" : "orange" : "gray", marginRight: 10 }}>{form.bio ? form.bio.length : 0} de 250</StyledText>
                        </View>

                    </View>
                    <View style={{ flexDirection: "row", alignItems: "center", justifyContent: "space-between", paddingBottom:30 }}>

                        <Button
                            label="Volver"
                            color={"gainsboro"}
                            style={{borderRadius:7}}
                            round={7}
                            reverse
                            onPress={() => navigation.goBack()} />
                        <Button
                            label="Guardar"
                            color={customization.mainColor}
                            style={{borderRadius:7}}
                            round={7}
                            onPress={editPersonalProfile} />
                    </View>
                </ScrollView>
            </KeyboardAvoidingView>
        </View>

    );
};

export default EditProfile;


const styles = StyleSheet.create({
    input: {
        borderWidth: 1,
        borderColor: "#C6C6C6",
        height: 50,
        backgroundColor: "white",
        borderRadius: 25,
        alignItems: "center",
        paddingHorizontal: 15,
        marginVertical: 5,
    },
    shadow: {
        shadowColor: "rgba(0, 0, 0, 0.3)",
        shadowOffset: { width: 0, height: 2 },
        shadowOpacity: 0.4,
        shadowRadius: 3,
        elevation: 10,
        backgroundColor: "white",
        borderRadius: 15
    }
});