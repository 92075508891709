import { Ionicons } from '@expo/vector-icons';
import { useNavigation } from '@react-navigation/native';
import { collection, getDocs, getFirestore, limit, query } from 'firebase/firestore';
import moment from 'moment';
import React, { useEffect, useState } from 'react';
import { StyleSheet, TouchableOpacity, View } from 'react-native';
import SecureImage from '../common/SecureImage';
import StyledText from '../common/StyledText';

const CourseFeaturedForums = ({ id, forumRef }) => {

    const [data, setData] = useState({});
    const navigation = useNavigation()
    const db = getFirestore()
    useEffect(() => {
        if (forumRef) {
            fetchData()
        }
    }, [forumRef])

    const fetchData = async () => {
        const q = query(collection(db, `${forumRef.path}/topics`), limit(1))
        const d = await getDocs(q).then(q => {
            const res = q.docs[0]
            if (res) {
                return { ...res.data(), id: res.id, ref: res.ref }
            } else {
                return {}
            }
        }).catch(err => { })
        setData(d)
    }

    return (
        <View style={{ marginTop: 30 }}>
            <View style={{ flexDirection: "row", alignItems: "center", justifyContent: "space-between" }}>
                <StyledText style={{ marginRight: 10, fontFamily: "Bold", fontSize: 20 }}>Foro del Itinerario</StyledText>
                {data.id && (
                    <TouchableOpacity onPress={() => navigation.navigate("StackSmartNew", { screen: "SingleForum", params: { id: forumRef.id } })} style={{ borderWidth: 3, borderRadius: 6, alignItems: "center", justifyContent: "center", width: 100, height: 35 }}>
                        <StyledText style={{ fontFamily: "Bold" }}>Ver Foro</StyledText>
                    </TouchableOpacity>
                )}
            </View>

            {data.id ? (
                <TouchableOpacity
                    onPress={() => navigation.navigate("StackSmartNew", { screen: "SingleTopic", params: { courseID: forumRef.id, id: data.id } })}
                    style={[{ paddingVertical: 10, paddingHorizontal: 15, borderRadius: 10, width: "100%", backgroundColor: "white", marginTop: 20 }, styles.shadow]}>
                    <StyledText style={{ marginRight: 10, fontFamily: "Bold", fontSize: 18, marginTop: 10 }}>{data?.title}</StyledText>
                    <View style={{ marginTop: 15, flexDirection: "row", alignItems: "center", justifyContent: "space-between", width: "100%" }}>
                        <View style={{ flexDirection: "row", alignItems: "center", marginRight: 15 }}>

                            <SecureImage
                                uri={data?.user?.image ? data?.user?.image : "https://runrunes.org/wp-content/uploads/2014/06/venezuela.jpg"}
                                style={{ resizeMode: "cover", width: 40, height: 40, borderRadius: 20, backgroundColor: "gainsboro" }}
                            />
                            <StyledText style={{ marginLeft: 5, fontFamily: "Bold", color: "grey", fontSize: 14 }}>{data?.user?.name}</StyledText>
                        </View>
                        <View style={{ flexDirection: "row", alignItems: "center" }}>
                            <View style={{ marginRight: 10, flexDirection: "row", alignItems: "center", marginRight: 15 }}>
                                <Ionicons color="grey" name="chatbox-outline" size={25} />
                                <StyledText style={{ marginLeft: 5, fontFamily: "Bold", color: "grey", fontSize: 14 }}>{data?.responses}</StyledText>
                            </View>

                            <StyledText style={{ fontSize: 14, fontFamily: "Bold" }}>Hace {moment(data?.lastActive ? data.lastActive.toDate() : "").fromNow("mm:mm")}</StyledText>
                        </View>
                    </View>
                </TouchableOpacity>
            ) : (
                <View style={[{ paddingVertical: 10, height: 120, justifyContent: "center", alignItems: "center", paddingHorizontal: 15, borderRadius: 10, width: "100%", backgroundColor: "white", marginTop: 20 }, styles.shadow]}>
                    <StyledText style={{ fontFamily: "DemiBold", opacity: 0.5 }}>Sin hilos activos en el itinerario</StyledText>
                </View>
            )}
        </View>
    );
}

export default CourseFeaturedForums;

const styles = StyleSheet.create({
    shadow: {
        shadowColor: "#000",
        shadowOffset: {
            width: 0,
            height: 5,
        },
        shadowOpacity: 0.1,
        shadowRadius: 6.68,

        elevation: 11,
    },
});
