import React, { useContext, useEffect, useRef, useState } from "react";
import { PanResponder, Platform, ScrollView, Text, TouchableOpacity, View } from "react-native";

import HTML from "react-native-render-html";

import { Ionicons } from "@expo/vector-icons";
import { useNavigation } from "@react-navigation/native";
import * as WebBrowser from "expo-web-browser";

import PointsContext from "../../context/PointsContext";


import AuthContext from "../../context/AuthContext";

import { addDoc, arrayRemove, arrayUnion, collection, doc, getFirestore, increment, onSnapshot, serverTimestamp, updateDoc } from "firebase/firestore";
import WebView from "react-native-webview";
import MediaViewerManager from "../../components/common/MediaViewerManager";

const preview = { uri: "data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAAEAAAABCAYAAAAfFcSJAAAADUlEQVR42mNk+M9QDwADhgGAWjR9awAAAABJRU5ErkJggg==" };

const Post = ({ navigation, route }) => {


	const { userData, tenant, dimensions, customization } = useContext(AuthContext);
	const db = getFirestore()
	const postID = route.params?.id;
	const smart = route.params?.smart;
	const { givePoints } = useContext(PointsContext);

	const { navigate } = useNavigation();
	const [loading, setLoading] = useState(true);
	const [data, setData] = useState({});
	const [image, setImage] = useState(null);
	const [media, setMedia] = useState(null);
	const [liked, setLiked] = useState(false);
	const [embedded, setEmbedded] = useState(null);

	const loadP = { 0: { opacity: 1 }, 0.5: { opacity: 0.4 }, 1: { opacity: 1 } };

	useEffect(() => {
		let unsubscribe = () => null
		setLoading(true);

		if (userData && postID) {
			const analyticsRef = collection(db, `${tenant.path}/analytics`)
			addDoc(analyticsRef, {
				type: "POST",
				userid: userData?.id,
				username: userData?.name,
				usersurname: userData?.surname,
				useremail: userData?.email,
				userimage: userData?.image,
				createdAt: serverTimestamp(),
				page: "POST",
				pageId: postID,
				usergroups: userData?.usergroups,
			});
			const docRef = doc(db, `${tenant.path}/smartcontents`, postID)
			unsubscribe = onSnapshot(docRef, (doc) => {

				doc.data().likes && doc.data().likes.includes(userData.id) && setLiked(true);

				setData(doc.data());

				navigation.setOptions({ title: "Contenido - " + doc.data().title });
			});
		}
		return () => unsubscribe();
	}, [postID]);

	useEffect(() => {
		if (data?.contentType === "EMBEDDED") {
			const a = new URL(data?.embedded);
			setEmbedded(a.searchParams.get("v"));
		}
	}, [data]);

	useEffect(() => {
		if (data?.title) {
			setLoading(false);
		}
	});

	const giveLike = async () => {
		if (liked) {
			const analyticsRef = collection(db, `${tenant.path}/analytics`)
			addDoc(analyticsRef, {
				type: "DISLIKE",
				userid: userData?.id,
				username: userData?.name,
				usersurname: userData?.surname,
				useremail: userData?.email,
				userimage: userData?.image,
				createdAt: serverTimestamp(),
				page: "POST",
				pageId: postID,
				usergroups: userData?.usergroups,
			});
			// await Analytics.logEvent("dislike", {
			// 	contentType: "post",
			// 	itemId: postID,
			// 	method: "app",
			// });
			setLiked(!liked);
			const docRef = doc(db, `${tenant.path}/smartcontents`, postID)
			updateDoc(docRef, {
				likesCount: increment(-1),
				likes: arrayRemove(userData.id),
			});
			// firebase
			// 	.firestore()
			// 	.collection("users")
			// 	.doc(userData.id)
			// 	.update({
			// 		likes: arrayRemove(postID),
			// 	});
		} else {
			const analyticsRef = collection(db, `${tenant.path}/analytics`)
			addDoc(analyticsRef, {
				type: "LIKE",
				userid: userData?.id,
				username: userData?.name,
				usersurname: userData?.surname,
				userimage: userData?.image,
				useremail: userData?.email,
				createdAt: serverTimestamp(),
				page: "POST",
				pageId: postID,
				usergroups: userData?.usergroups,
			});
			// await Analytics.logEvent("like", {
			// 	contentType: "post",
			// 	itemId: postID,
			// 	method: "app",
			// });
			setLiked(!liked);
			const docRef = doc(db, `${tenant.path}/smartcontents`, postID)
			updateDoc(docRef, {
				likesCount: increment(1),
				likes: arrayUnion(userData.id),
			});
			// firebase
			// 	.firestore()
			// 	.collection("users")
			// 	.doc(userData.id)
			// 	.update({
			// 		likes: arrayUnion(postID),
			// 	});
			// givePoints({
			// 	showPoints: true,
			// 	message: "Conseguiste 10 Puntos al dar like a este contenido",
			// 	points: 10,
			// });
		}
	};

	const panResponder = useRef(
		PanResponder.create({
			onStartShouldSetPanResponder: (evt, gestureState) => true,
		})
	).current;

	// console.log(data);

	if (loading)
		return (
			<View style={{ flex: 1, marginTop: 7, borderRadius: 15, alignItems: "center", width: "100%", maxWidth: 900 }}>
				<View style={{ aspectRatio: 1, width: "100%", backgroundColor: "gainsboro", marginBottom: 30 }} />
				<View style={{ paddingHorizontal: 10 }}>
					{/* <Animatable.View duration={1000} animation={loadP} easing="linear" iterationCount="infinite" style={{ height: 50, borderRadius: 7, marginRight: 10, backgroundColor: "gainsboro" }} />
					<Animatable.View duration={1000} animation={loadP} easing="linear" iterationCount="infinite" style={{ height: 20, borderRadius: 7, marginTop: 10, marginLeft: 10, backgroundColor: "gainsboro" }} />
					<Animatable.View duration={1000} animation={loadP} easing="linear" iterationCount="infinite" style={{ height: 20, borderRadius: 7, marginTop: 10, width: "90%", backgroundColor: "gainsboro" }} />
					<Animatable.View duration={1000} animation={loadP} easing="linear" iterationCount="infinite" style={{ height: 20, borderRadius: 7, marginTop: 10, marginLeft: 10, backgroundColor: "gainsboro" }} />
					<Animatable.View duration={1000} animation={loadP} easing="linear" iterationCount="infinite" style={{ height: 20, borderRadius: 7, marginTop: 10, marginLeft: 10, width: "80%", backgroundColor: "gainsboro" }} /> */}
				</View>
			</View>
		);

	return (
		<ScrollView contentContainerStyle={{ flexGrow: 1, maxWidth: 900, width: "100%", alignSelf: "center" }} showsVerticalScrollIndicator={false}>
			{data?.contentType === "EMBEDDED" ?
				(!Platform.OS === "web" ? (
					<WebView
						// ref={this.webViewRef}
						allowsFullscreenVideo
						useWebKit
						// onLoad={this.webViewLoaded}
						allowsInlineMediaPlayback
						mediaPlaybackRequiresUserAction
						javaScriptEnabled
						scrollEnabled={false}
						source={{ uri: "https://surajmdurgad.medium.com/embedding-youtube-videos-in-a-react-native-app-8c556a18fd76" }}
						style={{ height: 405, width: "100%", alignSelf: "center" }}
					/>
				) : (
					<iframe style={{ alignSelf: "center" }} width="100%" height="405" src={`https://www.youtube.com/embed/${embedded}?rel=0`} frameBorder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowFullScreen></iframe>
				))
				:
				(data?.media || data?.image) && (
					<MediaViewerManager
						useNativeControls={true}
						maxHeight={600}
						type={data?.contentType}
						url={data?.media}
						cover={data?.image}
						videoProps={{
							onPlaybackStatusUpdate: (ev) => null,
						}}
					/>

				)}


			{/* BOTONES LIKE Y COMENTAR */}
			{/* <View
				style={{
					flexDirection: "row",
					marginTop: Platform.OS === "web" ? 20 : 0,
					height: 50,
					maxWidth: Platform.OS === "web" ? 400 : null,
					borderRadius: Platform.OS === "web" ? 25 : 0,
					backgroundColor: "#f2f2f2",
				}}>
				<TouchableOpacity
					onPress={() => giveLike()}
					// disabled={liking}
					style={{ flexDirection: "row", flex: 1, alignItems: "center", justifyContent: "center" }}>
					<Ionicons name={liked ? "ios-heart" : "heart-outline"} size={20} style={{ marginRight: 10, color: "#ef0000" }} />
					<Text style={{ fontFamily: "DemiBold", fontSize: 14, color: "gray" }}>{data.likes ? data.likes.length : 0} Likes</Text>
				</TouchableOpacity>

				<TouchableOpacity onPress={() => navigate("Comments", { id: postID, feed: "contents" })} style={{ flexDirection: "row", flex: 1, borderLeftWidth: 1, borderLeftColor: "gainsboro", alignItems: "center", justifyContent: "center" }}>
					<Ionicons name="chatbox" size={20} style={{ marginRight: 10, color: "gray" }} />
					<Text style={{ fontFamily: "DemiBold", fontSize: 14, color: "gray" }}>{data.comments ? data.comments.length : 0} Comentarios</Text>
				</TouchableOpacity>
			</View> */}

			{/* CUERPO */}
			<View
				style={{
					flex: 1,
					paddingHorizontal: 10,
					marginBottom: 20,
					maxWidth: 900,
				}}>
				<Text
					style={{
						fontSize: 30,
						marginVertical: 20,
						fontFamily: "DemiBold",
					}}>
					{data.title}
				</Text>

				{data.content !== "" && (
					<HTML
						baseFontStyle={{
							fontFamily: "Regular",
							fontSize: 16,
							color: "#303030",
						}}
						html={data.content ? data.content : "<p></p>"}
						onLinkPress={async (evt, href) => await WebBrowser.openBrowserAsync(href.toLowerCase().includes("://") ? href : `https://${href}`)}
					/>
				)}
				{data?.contentType === "LINK" && (
					<TouchableOpacity onPress={() => {
						Platform.OS === "web" ?
							window.open(data?.link)
							:
							WebBrowser.openBrowserAsync(data.link.toLowerCase().includes("://") ? data.link : `https://${data.link}`)
					}} style={{ marginTop: 15, flexDirection: "row", height: 50, alignSelf: "flex-start", backgroundColor: customization.mainColor, paddingHorizontal: 20, borderRadius: 8, alignItems: "center", justifyContent: "center" }}>
						<Ionicons name="link" size={20} style={{ color: "white", marginRight: 10, }} />
						<Text style={{ color: "white", fontFamily: "DemiBold", fontSize: 14, }}>Ir al Enlace</Text>
					</TouchableOpacity>
				)}
			</View>
		</ScrollView>
	);
};

// Post.navigationOptions = {
// 	tabBarVisible: false,
// };

export default Post;
