import React, { useContext, useEffect, useState } from "react";
import { Dimensions, Image, StyleSheet, TouchableOpacity, useWindowDimensions, View } from "react-native";

// import FeaturedCourseCard from '../../components/FeaturedCourseCard'

import { ScrollView } from "react-native-gesture-handler";

import AuthContext from "../../context/AuthContext";

import { Ionicons } from "@expo/vector-icons";
import * as Localization from "expo-localization";
import i18n from "i18n-js";
import translations from "../../locale/translations";

import { useNavigation, useRoute } from "@react-navigation/native";
import { doc, getFirestore } from "firebase/firestore";
import StyledText from "../../components/common/StyledText";
import PathContainer from "../../components/itineraryComponentsNew/PathContainer";
import CourseFeaturedContent from "../../components/smartComponents/CourseFeaturedContent";
import CourseFeaturedForums from "../../components/smartComponents/CourseFeaturedForums";
import CourseHorizontalCard from "../../components/smartComponents/CourseHorizontalCard";
import CourseRanking from "../../components/smartComponents/CourseRanking";

i18n.translations = translations;
i18n.locale = Localization.locale;
i18n.fallbacks = true;

const screenWidth = Dimensions.get("window").width;

const SmartHome = ({ id, company }) => {
	const { userData, tenant, dimensions, dispatchUserData } = useContext(AuthContext);
	const navigation = useNavigation();
	const params = useRoute().params
	const [courseSelected, setCourseSelected] = useState(null)
	const sc = useWindowDimensions()
	const db = getFirestore()

	useEffect(() => {
		setCourseSelected(null)
	}, [tenant])

	useEffect(() => {
		if (userData?.courses?.length === 1) {
			setCourseSelected(0)
		}
		if (params?.course) {
			if (userData.courses) {
				const i = userData?.courses?.findIndex(course => course.id === params.course)
				i !== -1 ? setCourseSelected(i) : navigation.setParams({ course: undefined })
			}
		}
	}, [params, userData?.courses])


	const getAdvance = (course) => {
		const advance = userData?.advances?.find(el => el.courseRef.id === course.id)
		const completed = advance?.advance?.filter(el => el.completed).length
		const total = course?.contentDetails?.reduce((acc, cur) => acc + cur.contents.length, 0)
		return { completed: completed, total: total, percentage: Math.round(completed / total * 100) }
	}

	return (
		<ScrollView style={{ flex: 1 }} contentContainerStyle={{ maxWidth: 1200, width: "100%", alignSelf: "center" }}>
			<View style={{ flexDirection: "row" }}>
				<View style={{ width: (courseSelected !== null && userData?.courses?.length > 0 && dimensions.width > 1000) ? "60%" : "100%", paddingTop: 20, padding: 20 }}>
					{courseSelected !== null && userData?.courses?.length > 1 &&
						<TouchableOpacity style={{ flexDirection: "row", alignItems: "center", marginBottom: 15, }} onPress={() => {
							setCourseSelected(null)
							navigation.setParams({ course: undefined });
						}}>
							<Ionicons name="ios-arrow-back" size={40} color="black" />
							<StyledText style={{ fontSize: 22 }}>{i18n.t("Go Back")}</StyledText>
						</TouchableOpacity>
					}
					{(courseSelected !== null && userData.courses) ?
						<PathContainer course={userData.courses[courseSelected]} />
						:
						userData.courses?.length === 0 ?
							<View style={{ height: 300, borderRadius: 10, backgroundColor: "white", alignItems: "center", justifyContent: "center" }}>
								<StyledText style={{ fontSize: 20, fontWeight: "bold", marginTop: 20 }}>{i18n.t("No Courses Available")}</StyledText>
							</View>
							:
							userData.courses?.map((el, i) => {
								const { completed, total, percentage } = getAdvance(el)
								return (
									<CourseHorizontalCard
										key={i}
										el={el}
										course={el}
										advance={userData?.advances?.find(x => x.courseRef.id === el.id) || {}}
										onPress={() => {
											setCourseSelected(i)
											navigation.setParams({ course: el.id })
										}} />
								)
							})
					}
				</View>

				{courseSelected !== null && userData?.courses?.length > 0 && sc?.width > 1000 && (
					<View style={{ width: "40%", borderLeftColor: "gainsboro", paddingVertical: 20, borderLeftWidth: 1, paddingHorizontal: 20 }}>
						{userData.courses && userData.courses[courseSelected] && userData.courses[courseSelected].showRankings && <View style={[styles.shadow, { flexDirection: "row", padding: 15, paddingHorizontal: 20, backgroundColor: "white", borderRadius: 15, alignItems: "center" }]}>
							<View style={{ flex: 1, flexDirection: "row", alignItems: "center" }}>
								<Image style={{ width: 80, height: 80, marginRight: 20 }} source={require("../../assets/images/pointscourses.png")} />
								<View>
									<StyledText style={{ fontSize: 45, fontWeight: "bold" }}>{userData?.advances[courseSelected]?.points ? userData?.advances[courseSelected]?.points : 0} <StyledText style={{ fontSize: 20 }}>Puntos</StyledText></StyledText>
									<StyledText style={{ fontSize: 22 }}>{getAdvance(userData.courses[courseSelected]).percentage}% Completado</StyledText>
								</View>
							</View>
						</View>}
						{userData.courses && userData.courses[courseSelected] && userData.courses[courseSelected].showRankings && <CourseRanking id={userData.courses[courseSelected]?.id} />}
						{userData.courses && userData.courses[courseSelected] && userData.courses[courseSelected].aditionalMaterials?.length > 0 && <CourseFeaturedContent relatedContent={userData.courses[courseSelected]?.aditionalMaterials && userData.courses[courseSelected]?.aditionalMaterials[0]?.ref} id={userData.courses[courseSelected]?.id} />}
						{userData.courses && userData.courses[courseSelected] && userData.courses[courseSelected]?.hasForum && <CourseFeaturedForums forumRef={doc(db, `${userData.courses[courseSelected]?.ref?.path}`)} id={userData.courses[courseSelected]?.id} />}


					</View>
				)}
			</View>
		</ScrollView>
	);
};

export default SmartHome;

const styles = StyleSheet.create({
	shadow: {
		shadowColor: "#000",
		shadowOffset: {
			width: 0,
			height: 5,
		},
		shadowOpacity: 0.1,
		shadowRadius: 6.68,

		elevation: 11,
	},
});
