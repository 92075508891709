import React, { useState, useEffect, useContext } from "react";
import { View, Text, Image, TouchableOpacity, ScrollView, ActivityIndicator } from "react-native";
import { useNavigation } from "@react-navigation/native";
import { Ionicons } from "@expo/vector-icons";
import ImageRel from "../../components/common/ImageRel";
import moment from "moment";
import Header from "../../components/AppHeader";

// import firebase from "firebase/app";
import AuthContext from "../../context/AuthContext";
import Button from "../../components/common/Button";
import { collection, doc, getDoc, getDocs, getFirestore, orderBy, query } from "firebase/firestore";
import SecureImage from "../../components/common/SecureImage";

const SingleForum = ({ id, route }) => {
	const { customization, tenant, userData } = useContext(AuthContext);

	const db = getFirestore()
	const navigation = useNavigation();
	const foroID = route.params?.id;
	const foroName = route.params?.foroName;

	const [refreshing, refetching] = useState(true);
	const { navigate } = useNavigation();
	const [loadingMore, setLoadingMore] = useState(false);
	const [isMoreContent, setIsMoreContent] = useState(false);
	const [limit, setLimit] = useState(4);
	const [data, setData] = useState([]);
	const [forumData, setForumData] = useState({});
	const [loading, setLoading] = useState([]);

	// console.log(userData);
	useEffect(() => {
		setLoading(true);
		if (foroID) {
			getForumData();
		}
	}, [foroID]);

	const getForumData = () => {
		const docRef = doc(db, `${tenant.path}/forums`, foroID)
		getDoc(docRef).then((doc) => {
			if (doc.exists()) {
				setForumData({ ...doc.data(), id: doc.id, ref: doc.ref });
				fetchTopics(doc.ref)
			}
		});
	};

	const fetchTopics = (ref) => {
		const q = query(collection(db, `${ref.path}/topics`), orderBy("lastActive", "desc"));
		getDocs(q).then((querySnapshot) => {
			const d = querySnapshot.docs.map((doc) => ({ ...doc.data(), id: doc.id, ref: doc.ref }));
			setData(d);
			setLoading(false);
		});
	}

	if (loading)
		return (
			<View style={{ flex: 1, alignItems: "center", justifyContent: "center" }}>
				<ActivityIndicator size="large" />
			</View>
		);

	return (
		<View style={{ backgroundColor: "white", flex: 1 }}>
			{/* {Platform.OS === "web" && <HeaderWeb />} */}
			<ScrollView
				showsVerticalScrollIndicator={false}
				contentContainerStyle={{
					paddingBottom: 100,
				}}>
				<View>
					<SecureImage uri={forumData.image} />
					<ImageRel
						source={forumData.image}
						background={true}
						style={{
							width: "100%",
							overflow: "hidden",
							alignItems: "center",
						}}>
						<View style={{ flex: 1, backgroundColor: "rgba(0,0,0,0.5)", position: "absolute", top: 0, left: 0, bottom: 0, right: 0 }} />
						<View style={{ padding: 20 }}>
							<Text
								style={{
									color: "white",
									textAlign: "center",
									fontFamily: "DemiBold",
									zIndex: 100,
									fontSize: 22,
									padding: 20,
								}}>
								{forumData.name}
							</Text>
						</View>
					</ImageRel>
				</View>
				{/* <View style={{ alignItems: "center", paddingHorizontal: 10 }} >
					<TouchableOpacity
					onPress={() =>
						navigate("CreateTopic", {
						foroID: data.forum && data.forum.id,
						foroName: data.forum && data.forum.name
						})
					} style={{ ...styles.btn }}
					>
					<Ionicons style={{ marginRight: 10 }} name="md-add-circle" size={30} color="white" />
					<Text style={{ fontFamily: "DemiBold", fontSize: 18, color: "white" }}>Crear Nuevo Hilo</Text>
					</TouchableOpacity>
				</View> */}

				<View style={{ maxWidth: 800, width: "100%", alignSelf: "center" }}>
					{data.map((h, i) => {
						return (
							<TouchableOpacity
								key={"topic-" + h.id}
								onPress={() =>
									navigate("SingleTopic", {
										id: h.id,
										forumID: h.forum.id,
									})
								}>
								<View
									style={{
										...styles.topicBox,
										borderTopWidth: i === 0 ? 1 : 0,
									}}>
									{/* <Image source={{ uri: h.user && h.user.image && (URL.URL_API + h.user.image.url) }} style={styles.imageRedond}/> */}

									<View style={{ flex: 1 }}>
										<View style={{ flexDirection: "row", justifyContent: "space-between" }}>
											<View style={{ flex: 1 }}>
												<Text style={{ fontSize: 22, fontFamily: "DemiBold" }}>{h.title}</Text>
											</View>
											<View style={{ width: 50, marginTop: 5 }}>
												<Text style={{ fontSize: 12, textAlign: "right", color: "#aaa" }}>{moment(h.lastActive ? h.lastActive.toDate() : "").fromNow("mm:mm")}</Text>
											</View>
										</View>

										<View style={{ flexDirection: "row", justifyContent: "space-between", alignItems: "center", marginTop: 20 }}>
											<View style={{ flexDirection: "row", alignItems: "center", borderRadius: 15, height: 30, paddingRight: 15, backgroundColor: "#f2f2f2" }}>
												<ImageRel placeholder={"https://picsum.photos/200/300/?blur=2"} source={h.user && h.user.image} style={{ width: 35, height: 35, borderRadius: 18, marginRight: 10 }} />
												<Text style={{ color: "grey", fontFamily: "Regular" }}>
													{h.user.name}
													{/* {moment(h.createdAt).format("DD/MM/YYYY")} */}
												</Text>
											</View>

											<View
												style={{
													marginLeft: 30,
													display: "flex",
													flexDirection: "row",
													alignItems: "center",
													backgroundColor: "#f2f2f2",
													height: 30,
													borderRadius: 15,
													paddingHorizontal: 15,
												}}>
												<Ionicons name="chatbox" size={15} color="gray" style={{ marginRight: 5 }} />
												<Text style={{ fontSize: 12, fontFamily: "DemiBold" }}>{h.responses}</Text>
											</View>
										</View>
									</View>
								</View>
							</TouchableOpacity>
						);
					})}
					<View style={{ flexDirection: "row", justifyContent: "space-between", alignItems: "center", marginTop: 20 }}>
						{
							isMoreContent ? (
								<Button
									loading={loadingMore}
									label="Más Hilos"
									color={customization.mainColor}
									onPress={() => {
										setLoadingMore(true);
										setLimit(limit + 4);
									}}
								/>
							) : (
								<View />
							)

							// <View style={{ marginTop: 35, marginBottom: 15, alignItems: "center" }}>
							//   <TouchableOpacity onPress={() => {

							//     setLoadingMore(true)
							//     setLimit(limit + 4)

							//   }} style={{ alignItems: "center", justifyContent: "center", height: 50, width: 250, borderRadius: 25, backgroundColor: customization.buttonMainColor }}>
							//     {!loadingMore ?
							//       <Text style={{ color: customization.buttonMainColorText, fontFamily: "DemiBold", fontSize: 16 }}>Mas Hilos</Text>
							//       :
							//       <ActivityIndicator color={"white"} />
							//     }
							//   </TouchableOpacity>
							// </View>
							// :
							// <View style={{ marginTop: 35, marginBottom: 15, alignItems: "center" }}>
							//   <Text style={{ color: "gray", fontFamily: "DemiBold", fontSize: 16 }}>No hay mas Hilos</Text>
							// </View>
						}
						<Button
							label="Nuevo Hilo"
							color={customization.mainColor}
							icon="add-circle"
							onPress={() => {
								navigation.navigate("CreateTopicPage", { id: foroID, foroName });
							}}></Button>
					</View>
				</View>
			</ScrollView>
		</View>
	);
};

const styles = {
	topicBox: {
		width: "100%",
		padding: 15,
		display: "flex",
		flexDirection: "row",
		alignItems: "center",
		borderBottomWidth: 1,
		borderColor: "gainsboro",
	},
	imageRedond: {
		height: 30,
		width: 30,
		borderRadius: 15,
		borderWidth: 1,
		borderColor: "gainsboro",
		marginRight: 10,
	},
	btn: {
		width: "100%",
		height: 50,
		flexDirection: "row",
		backgroundColor: "#017676",
		borderRadius: 25,
		justifyContent: "center",
		alignItems: "center",
	},
};
export default SingleForum;
