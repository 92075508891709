import React, { useContext, useState, useEffect } from "react";
import { View, Text, ScrollView, Dimensions, StatusBar, Platform } from "react-native";
import Modal from "modal-react-native-web";

import { Ionicons } from "@expo/vector-icons";
import ImageRel from "../../components/common/ImageRel";
import SafeBottomTextInput from "../../components/common/SafeBottomTextInput";
import LottieView from "lottie-react-native";
import * as Linking from "expo-linking";
import * as WebBrowser from "expo-web-browser";
import * as Animatable from "react-native-animatable";
import PointsContext from "../../context/PointsContext";
import AuthContext from "../../context/AuthContext";


import { TextInput, TouchableOpacity } from "react-native-gesture-handler";
import { addDoc, collection, deleteDoc, doc, getFirestore, increment, limit, onSnapshot, orderBy, query, serverTimestamp, updateDoc } from "firebase/firestore";
import SecureImage from "../../components/common/SecureImage";

if (Platform.OS !== "web") {
	ParsedText = require("react-native-parsed-text").default;
}

let SCREEN_WIDTH = Dimensions.get("window").width;
let SCREEN_HEIGHT = Dimensions.get("window").height;

const comentarios = ({ navigation, id, route }) => {
	const { givePoints } = useContext(PointsContext);

	const [comment, setComment] = useState("");
	const [data, setData] = useState([]);
	const [loading, setLoading] = useState(true);
	const [editModal, toogleEditModal] = useState(false);
	const [deleteModal, toogleDeleteModal] = useState(false);
	const [itemToModify, setItemToModify] = useState(null);
	const [commentEditing, setCommentEditing] = useState("");
	const { userData, tenant } = useContext(AuthContext);
	const db = getFirestore()
	const feed = route.params.feed;
	const postID = route.params.id;

	const loadP = { 0: { opacity: 1 }, 0.5: { opacity: 0.4 }, 1: { opacity: 1 } };

	useEffect(() => {
		setLoading(true);
		const q = query(collection(db, `${tenant.path}/${feed}/${postID}/comments`), orderBy("createdAt", "desc"), limit(10))

		const unsubscribe = onSnapshot(q, (querySnapshot) => {
			const d = querySnapshot.docs.map((doc) => ({ ...doc.data(), id: doc.id, ref: doc.ref }));

			setData(d);
			setLoading(false);
			navigation.setOptions({ title: d.length + " - Comentarios" });
		});

		return () => {
			unsubscribe();
		};
	}, []);

	const sendMessage = () => {
		const variables = {
			message: comment,
			userRef: userData.ref,
			user: {
				name: userData.name,
				image: userData.image,
				surname: userData.surname,
				usergroups: userData?.usergroups
			},
			post: doc(db, `${tenant.path}/${feed}/`, postID),
			createdAt: serverTimestamp(),
			updatedAt: serverTimestamp(),
		};
		const colRef = collection(db, `${tenant.path}/analytics`);
		addDoc(colRef, {
			type: "COMMENT",
			userid: userData?.id,
			createdAt: serverTimestamp(),
			page: "POST",
			pageId: postID,
			usergroups: userData?.usergroups,
		});
		addDoc(collection(db, `${tenant.path}/${feed}/${postID}/comments`), variables).then((res) => {
			const docRef = doc(db, `${tenant.path}/${feed}`, postID);
			updateDoc(docRef, {
				commentsCount: increment(1),
			});
			// givePoints({
			// 	showPoints: true,
			// 	message: "Conseguiste 50 Puntos al dejar un comentario!",
			// 	points: 50,
			// });

			setComment("");
		});
	};

	const updateComment = () => {
		updateDoc(itemToModify, {
			message: commentEditing,
		}).then((res) => {
			setCommentEditing("");
			setItemToModify(null);
			toogleEditModal(false);
		})
			.catch((err) => {
				alert("Ha ocurrido un error al editar el comentario");
			});
	};

	const deleteComment = () => {
		deleteDoc(itemToModify).then((res) => {
			const docRef = doc(db, `${tenant.path}/${feed}`, postID);
			updateDoc(docRef, {
				commentsCount: increment(-1),
			});
			setItemToModify(null);
			toogleDeleteModal(false);
		})
			.catch((err) => {
				alert("Ha ocurrido un error al eliminar el comentario");
			});
	};

	if (loading)
		return (
			<View style={{ flex: 1 }}>
				<View style={{ maxWidth: 900, alignSelf: "center", width: "100%" }}>
					<View style={{ maxWidth: 900, alignSelf: "center", width: "100%", flex: 1 }}>
						<View style={{ paddingHorizontal: 10, flexDirection: "row", marginTop: 10 }}>
							<Animatable.View duration={1000} animation={loadP} easing="linear" iterationCount="infinite" style={{ height: 70, width: 70, marginHorizontal: 10, borderRadius: 35, backgroundColor: "gainsboro" }} />
							<Animatable.View duration={1000} animation={loadP} easing="linear" iterationCount="infinite" style={{ height: 80, width: "70%", borderRadius: 7, marginRight: 10, backgroundColor: "gainsboro" }} />
						</View>
						<View style={{ paddingHorizontal: 10, flexDirection: "row-reverse", marginTop: 10 }}>
							<Animatable.View duration={1000} animation={loadP} easing="linear" iterationCount="infinite" style={{ height: 70, width: 70, marginHorizontal: 10, borderRadius: 35, backgroundColor: "gainsboro" }} />
							<Animatable.View duration={1000} animation={loadP} easing="linear" iterationCount="infinite" style={{ height: 80, width: "70%", borderRadius: 7, marginRight: 10, backgroundColor: "gainsboro" }} />
						</View>
						<View style={{ paddingHorizontal: 10, flexDirection: "row", marginTop: 10 }}>
							<Animatable.View duration={1000} animation={loadP} easing="linear" iterationCount="infinite" style={{ height: 70, width: 70, marginHorizontal: 10, borderRadius: 35, backgroundColor: "gainsboro" }} />
							<Animatable.View duration={1000} animation={loadP} easing="linear" iterationCount="infinite" style={{ height: 80, width: "70%", borderRadius: 7, marginRight: 10, backgroundColor: "gainsboro" }} />
						</View>
						<View style={{ paddingHorizontal: 10, flexDirection: "row-reverse", marginTop: 10 }}>
							<Animatable.View duration={1000} animation={loadP} easing="linear" iterationCount="infinite" style={{ height: 70, width: 70, marginHorizontal: 10, borderRadius: 35, backgroundColor: "gainsboro" }} />
							<Animatable.View duration={1000} animation={loadP} easing="linear" iterationCount="infinite" style={{ height: 80, width: "70%", borderRadius: 7, marginRight: 10, backgroundColor: "gainsboro" }} />
						</View>
					</View>
				</View>
			</View>
		);

	return (
		<View style={{ flex: 1, backgroundColor: "white" }}>
			<StatusBar barStyle="light-content" />
			<ScrollView showsVerticalScrollIndicator={false} contentContainerStyle={{ paddingLeft: 10, paddingTop: 15, flexGrow: 1, maxWidth: 900, alignSelf: "center", width: "100%" }}>
				{data.length === 0 && (
					<View style={{ flex: 1, alignItems: "center", justifyContent: "center" }}>
						{Platform.OS !== "web" && (
							<LottieView
								autoPlay
								style={{
									width: 200,
									height: 200,
									marginBottom: 30,
								}}
								source={require("../../assets/animations/comment.json")}
							/>
						)}
						<Text style={{ fontFamily: "DemiBold", fontSize: 20 }}>Sé el primero en comentar</Text>
					</View>
				)}
				{data.map((comment, e) => {
					return (
						<View
							key={e}
							style={{
								flexDirection: comment.user && comment.user.id.includes(id) ? "row-reverse" : "row",
								marginBottom: 10,
							}}>
							<View style={{ width: 70, height: 70, marginHorizontal: 10 }}>
								<SecureImage
									style={{
										zIndex: -1,
										flex: 1,
										height: null,
										width: null,
										borderRadius: 35,
										overflow: "hidden",
									}}
									resizeMode="cover"
									uri={comment.user && comment.user.image}
								/>
							</View>

							<View
								style={{
									backgroundColor: "#f0f0f0",
									borderRadius: 7,
									padding: 10,
									width: "auto",
									height: "auto",
									maxWidth: SCREEN_WIDTH - 120 > 750 ? 750 : SCREEN_WIDTH - 120,
								}}>
								<Text style={{ fontSize: 10, marginBottom: 5, color: "gray", fontFamily: "Regular" }}>
									{comment.user && comment.user.name} {comment.user && comment.user.surname}
								</Text>
								{editModal && itemToModify?.id === comment?.id ? (
									<TextInput
										multiline={true}
										onChangeText={(text) => setCommentEditing(text)}
										style={{ border: 1, border: "1px solid gray", flex: 1, minWidth: commentEditing.length * 8 > 730 ? 730 : commentEditing.length * 8, maxWidth: SCREEN_WIDTH - 120 > 750 ? 750 : SCREEN_WIDTH - 120, fontFamily: "Regular", fontSize: 16 }}
										value={commentEditing}
									/>
								) : Platform.OS !== "web" ? (
									<ParsedText
										style={{ fontFamily: "Regular", fontSize: 16 }}
										parse={[
											{ type: "url", style: { textDecorationLine: "underline", fontFamily: "DemiBold" }, onPress: async (ev) => await WebBrowser.openBrowserAsync(ev.toLowerCase().includes("://") ? ev : `https://${ev}`) },
											{ type: "phone", style: { textDecorationLine: "underline", fontFamily: "DemiBold" }, onPress: (ev) => Linking.openURL(`tel:${ev}`) },
											{ type: "email", style: { textDecorationLine: "underline", fontFamily: "DemiBold" }, onPress: (ev) => Linking.openURL(`mailto:${ev}`) },
										]}
										childrenProps={{ allowFontScaling: false }}>
										{comment.message}
									</ParsedText>
								) : (
									<Text style={{ fontFamily: "Regular", fontSize: 16 }}>{comment.message}</Text>
								)}
							</View>
							<View style={{ flexDirection: "column" }}>
								{userData.role === "Admin" && !editModal && (
									<View>
										<TouchableOpacity
											onPress={() => {
												toogleEditModal(true);
												setCommentEditing(comment.message);
												setItemToModify(comment.ref);
											}}
											style={{ width: 40, height: 40, alignItems: "center", justifyContent: "center" }}>
											<Ionicons size={24} name="md-pencil" />
										</TouchableOpacity>
									</View>
								)}
								{userData.role === "Admin" && editModal && itemToModify?.id === comment.id && (
									<View>
										<TouchableOpacity
											onPress={() => {
												updateComment();
											}}
											style={{ width: 40, height: 40, alignItems: "center", justifyContent: "center" }}>
											<Ionicons size={24} color="green" name="checkmark-circle" />
										</TouchableOpacity>
									</View>
								)}
								{userData.role === "Admin" && editModal && itemToModify?.id === comment.id && (
									<View>
										<TouchableOpacity
											onPress={() => {
												toogleEditModal(false);
												setItemToModify(null);
											}}
											style={{ width: 40, height: 40, alignItems: "center", justifyContent: "center" }}>
											<Ionicons size={24} color="#d23939" name="close-circle" />
										</TouchableOpacity>
									</View>
								)}
								{userData.role === "Admin" && !editModal && (
									<View>
										<TouchableOpacity
											onPress={() => {
												toogleDeleteModal(true);
												setItemToModify(comment.ref);
											}}
											style={{ width: 40, height: 40, alignItems: "center", justifyContent: "center" }}>
											<Ionicons size={24} color="#d23939" name="trash-bin" />
										</TouchableOpacity>
									</View>
								)}
							</View>
						</View>
					);
				})}
			</ScrollView>
			<Modal areaHideApp={false} animation="slide" transparent={true} visible={deleteModal}>
				<View style={{ flex: 1, backgroundColor: "rgba(0,0,0,.8)", alignItems: "center", justifyContent: "center" }}>
					<View style={{ padding: 15, alignItems: "center", borderRadius: 10, backgroundColor: "white", width: 350 }}>
						<Text style={{ fontFamily: "Regular", fontSize: 16 }}>¿Estas seguro que deseas eliminar este comentario? Esta acción no puede deshacerse</Text>
						<View style={{ flexDirection: "row", marginTop: 20 }}>
							<TouchableOpacity
								onPress={() => {
									toogleDeleteModal(false);
									setItemToModify(null);
								}}
								style={{ marginRight: 10, backgroundColor: "gray", borderRadius: 5, width: 150, height: 40, alignItems: "center", justifyContent: "center" }}>
								<Text style={{ fontFamily: "Regular", fontSize: 16, color: "white" }}>Cancelar</Text>
							</TouchableOpacity>
							<TouchableOpacity
								onPress={() => {
									deleteComment();
								}}
								style={{ backgroundColor: "#d23939", borderRadius: 5, width: 150, height: 40, alignItems: "center", justifyContent: "center" }}>
								<Text style={{ fontFamily: "Regular", fontSize: 16, color: "white" }}>Eliminar</Text>
							</TouchableOpacity>
						</View>
					</View>
				</View>
			</Modal>
			<SafeBottomTextInput input={comment} writeCallback={(ev) => setComment(ev)} sendCallback={() => sendMessage()} />
		</View>
	);
};

export default comentarios;
