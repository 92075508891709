import React, { useState, useEffect, useContext } from "react";
import { View, Text, TouchableOpacity, ScrollView, Platform } from "react-native";
import * as Navigation from "@react-navigation/native";
import { Ionicons } from "@expo/vector-icons";
import { flattenDeep, uniq, get } from "lodash";
import ImageRel from "../../components/common/ImageRel";
import * as Animatable from "react-native-animatable";
import moment from "moment";


import AuthContext from "../../context/AuthContext";

import * as Localization from "expo-localization";
import i18n from "i18n-js";
import translations from "../../locale/translations";
import SecureImage from "../../components/common/SecureImage";
import { collection, collectionGroup, getDocs, getFirestore, limit, onSnapshot, orderBy, query, where } from "firebase/firestore";

i18n.translations = translations;
// Set the locale once at the beginning of your app.
i18n.locale = Localization.locale;
let sub = () => null

const ForumHome = ({ id, route }) => {
	const { userData, tenant } = useContext(AuthContext);
	const db = getFirestore()

	const [refreshing, refetching] = useState(true);
	const [tabSelected, setTabSelected] = useState("mis_hilos");
	const [mySubscriptions, setMySubscriptions] = useState([]);
	// const [limit, setLimit] = useState(4);
	const [limitDM, setLimitDM] = useState(8);
	const [error, setError] = useState(false)
	const [data, setData] = useState([]);
	const [topics, setTopics] = useState([]);
	const [loading, setLoading] = useState(true);
	const [DMTopics, setDMTopics] = useState([]);
	const { navigate } = Navigation.useNavigation();
	const loadP = { 0: { opacity: 1 }, 0.5: { opacity: 0.4 }, 1: { opacity: 1 } };

	useEffect(() => {
		setLoading(true);
		const q = query(collection(db, `${tenant.path}/forums`), where("usergroups", "array-contains-any", userData.usergroups?.length === 0 ? ["public"] : userData.usergroups || ["public"]))
		const forr = onSnapshot(q,
			(querySnapshot) => {

				if (querySnapshot.docs.length > 0) {
					const forums = querySnapshot.docs.map(doc => ({ ...doc.data(), id: doc.id, ref: doc.ref }));
					setData(forums)
					fetchTopics(forums)
					setLoading(false)
				} else {
					setData([])
					setTopics([])
					setError(true);
					setLoading(false);
				}
			},
			(err) => console.log(err)
		);


		return () => {
			forr();
			sub()
		}
	}, [tenant]);

	const fetchTopics = (forums) => {
		const q2 = query(collectionGroup(db, `topics`), where("tenant", "==", tenant), where("forum", "in", forums.map(el => el.ref)), orderBy("responses", "desc"), limit(5))
		sub = onSnapshot(q2, (querySnapshot) => {

			if (querySnapshot.docs.length > 0) {
				const t = querySnapshot.docs.map(doc => ({ ...doc.data(), id: doc.id, ref: doc.ref }));
				console.log(t)
				setTopics(t)
				setLoading(false)
			} else {
				setError(true);
				setLoading(false);
			}
		})
	}

	return (
		<View style={{ flex: 1 }}>
			{/* {Platform.OS === "web" && <HeaderWeb />} */}
			<ScrollView
				showsVerticalScrollIndicator={false}
				contentContainerStyle={{
					flexGrow: 1,
					paddingBottom: 100,
					maxWidth: 800,
					width: "100%",
					alignSelf: "center",
				}}>
				<View>
					<Text style={{ marginHorizontal: 10, marginTop: 20, marginBottom: 20, fontFamily: "Bold", fontSize: 34 }}>{i18n.t("forums")}</Text>

					{/* SECCION FOROS */}
					{loading ? (
						<View style={{ marginLeft: 10, flexDirection: "row" }}>
							<Animatable.View duration={1000} animation={loadP} easing="linear" iterationCount="infinite" style={[styles.forumBox, { marginRight: 10, borderWidth: 0, backgroundColor: "gainsboro" }]} />
							<Animatable.View duration={1000} animation={loadP} easing="linear" iterationCount="infinite" style={[styles.forumBox, { borderWidth: 0, backgroundColor: "gainsboro" }]} />
						</View>
					) : (
						<ScrollView horizontal={true} showsHorizontalScrollIndicator={false} contentContainerStyle={{ paddingLeft: 10 }} stickyHeaderIndices={[1]}>
							<View style={{ flexDirection: "row" }}>
								{data.map((forum, i) => (
									<TouchableOpacity
										onPress={() => {
											navigate("SingleForum", {
												id: forum.id,
											});
										}}
										style={{ marginRight: 10 }}
										key={i}>
										<SecureImage style={{ flex: 1, borderRadius: 10, position: "absolute", top: 0, left: 0, bottom: 0, right: 0 }} imageStyle={{ borderRadius: 10 }} uri={forum.image} />

										<View style={{ flex: 1, backgroundColor: "rgba(0,0,0,0.5)", borderRadius: 10, position: "absolute", top: 0, left: 0, bottom: 0, right: 0 }} />
										<View key={"topic-" + forum.id} style={styles.forumBox}>
											<View style={{ flex: 1, justifyContent: "space-between" }}>
												<Text
													numberOfLines={2}
													style={{
														color: "white",
														fontSize: 18,
														fontFamily: "DemiBold",
													}}>
													{forum.name}
												</Text>

												{/* <View style={{ height: 50, marginTop: 5, flexDirection: "row" }}>
													{!forum.tocpis
														? null
														: uniq(flattenDeep(forum.topics.map((t) => t.user && t.user.image && t.user.image.url))).map((url, i, arr) => {
															return (
																<SecureImage
																	key={"thumbito" + i}
																	style={{
																		borderColor: "white",
																		borderWidth: 2,
																		width: 40,
																		marginLeft: i > 0 ? -15 : 0,
																		height: 40,
																		borderRadius: 20,
																		resizeMode: "cover",
																	}}
																	uri={url ? url : ''}
																/>
															);
														})}
												</View> */}
											</View>
										</View>
									</TouchableOpacity>
								))}
							</View>
						</ScrollView>
					)}
				</View>

				{/* SECCION SELECTOR */}

				<View style={{ flex: 1 }}>
					<View style={{ flexDirection: "row", marginTop: 10 }}>
						<Text style={{ textAlign: Platform.OS === "web" ? "center" : null, marginHorizontal: 10, marginTop: 20, marginBottom: 20, fontFamily: "Bold", fontSize: 34 }}>{i18n.t("popularthreads")}</Text>
					</View>

					{topics.map((h, i) => {
						return (
							<TouchableOpacity
								key={"topic-" + h.id}
								onPress={() =>
									navigate("SingleTopic", {
										id: h.id,
										forumID: h.forum.id,
									})
								}>
								<View
									style={{
										...styles.topicBox,
										borderTopWidth: i === 0 ? 1 : 0,
									}}>
									{/* <Image source={{ uri: h.user && h.user.image && (URL.URL_API + h.user.image.url) }} style={styles.imageRedond}/> */}

									<View style={{ flex: 1 }}>
										<View style={{ flexDirection: "row", justifyContent: "space-between" }}>
											<View style={{ flex: 1 }}>
												<Text style={{ fontSize: 22, fontFamily: "DemiBold" }}>{h.title}</Text>
											</View>
											<View style={{ width: 50, marginTop: 5 }}>
												<Text style={{ fontSize: 12, textAlign: "right", color: "#aaa" }}>{moment(h.lastActive ? h.lastActive.toDate() : "").fromNow("mm:mm")}</Text>
											</View>
										</View>

										<View style={{ flexDirection: "row", alignItems: "center", marginTop: 20 }}>
											<View style={{ flexDirection: "row", alignItems: "center", borderRadius: 15, height: 30, paddingRight: 15, backgroundColor: "#f2f2f2" }}>
												<SecureImage uri={h.user.image} style={{ width: 35, height: 35, borderRadius: 18, marginRight: 10 }} />
												<Text style={{ color: "grey", fontFamily: "Regular" }}>
													{h.user.name}
													{/* {moment(h.createdAt).format("DD/MM/YYYY")} */}
												</Text>
											</View>

											<View
												style={{
													marginLeft: 30,
													display: "flex",
													flexDirection: "row",
													alignItems: "center",
													backgroundColor: "#f2f2f2",
													height: 30,
													borderRadius: 15,
													paddingHorizontal: 15,
												}}>
												<Ionicons name="chatbox-outline" size={15} color="gray" style={{ marginRight: 5 }} />
												<Text style={{ fontSize: 12, fontFamily: "DemiBold" }}>{h.responses}</Text>
											</View>
										</View>
									</View>
								</View>
							</TouchableOpacity>
						);
					})}
				</View>
			</ScrollView>
		</View>
	);
};

const styles = {
	forumBox: {
		borderRadius: 10,
		height: 120,
		width: 240,
		padding: 10,
	},
	tabButton: {
		height: 50,
		width: "100%",
		borderTopLeftRadius: 10,
		borderTopRightRadius: 10,
		borderColor: "#ec0000",
		borderWidth: 1,
		borderBottomWidth: 0,
		display: "flex",
		alignItems: "center",
		justifyContent: "center",
	},
	selected: {
		backgroundColor: "white",
		color: "black",
	},
	notSelected: {
		backgroundColor: "#ec0000",
		color: "white",
	},
	topicWrapper: {
		padding: 15,
		flex: 1,
		backgroundColor: "red",
		paddingBottom: 0,
		borderBottomLeftRadius: 10,
		borderBottomRightRadius: 10,
		borderWidth: 1,
		borderTopWidth: 0,
		borderColor: "#ec0000",
	},
	topicBox: {
		width: "100%",
		padding: 15,
		flexDirection: "row",
		alignItems: "center",
		borderBottomWidth: 1,
		borderColor: "gainsboro",
	},
	imageRedond: {
		height: 60,
		width: 60,
		borderRadius: 20,
		borderWidth: 1,
		resizeMode: "cover",
		borderColor: "gainsboro",
		marginRight: 10,
	},
	btn: {
		width: 250,
		height: 50,
		backgroundColor: "#017676",
		borderRadius: 25,
		justifyContent: "center",
		alignItems: "center",
	},
};

export default ForumHome;
