import { Ionicons } from '@expo/vector-icons';
import { Link, useNavigation } from '@react-navigation/native';
import { WebBrowser } from 'expo';
import { getDoc } from 'firebase/firestore';
import React, { useEffect, useState } from 'react';
import { TouchableOpacity, View, StyleSheet } from 'react-native';
import HTML from 'react-native-render-html';
import SecureImage from '../common/SecureImage';
import StyledText from '../common/StyledText';

const CourseFeaturedContent = ({ id, relatedContent }) => {

    const [data, setData] = useState(null)
    const navigation = useNavigation()

    useEffect(() => {
        if (relatedContent) {
            fetchData()
        }
    }, [relatedContent])

    const fetchData = async () => {
        const d = await getDoc(relatedContent).then(res => ({ ...res.data(), id: res.id, ref: res.ref })).catch(err => { })
        setData(d)
    }

    return (
        <View style={{ marginTop: 30 }}>
            <View style={{ flexDirection: "row", alignItems: "center", justifyContent: "space-between" }}>
                <StyledText style={{ marginRight: 10, fontFamily: "Bold", fontSize: 20 }}>Contenido adicional</StyledText>

                <TouchableOpacity
                    onPress={() => {
                        navigation.navigate("StackSmartNew", { screen: "FeaturedContents", params: { id } })
                    }}
                    style={{ borderWidth: 3, borderRadius: 6, alignItems: "center", justifyContent: "center", width: 100, height: 35 }}>
                    <StyledText style={{ fontFamily: "Bold" }}>Ver Más</StyledText>
                </TouchableOpacity>
            </View>
            {data ? (
                <TouchableOpacity
                    style={[styles.shadow, { marginTop: 10, backgroundColor: "white", borderRadius: 15 }]}
                    onPress={() => {
                        data.ref.path.includes("smartcontents")
                            ? navigation.navigate("StackSmartNew", { screen: "Post", params: { id: data.id } })
                            : navigation.navigate("StackContent", { screen: "Post", params: { id: data.id } });
                    }}>

                    <SecureImage
                        style={{ height: 150, width: "100%", borderRadius: 7 }}
                        uri={data ? (data?.image ? data.image : "https://images.pexels.com/photos/4069292/pexels-photo-4069292.jpeg?auto=compress&cs=tinysrgb&dpr=2&h=750&w=1260") : ""}
                    />
                    <View style={{ padding: 15 }}>
                        <StyledText style={{ marginRight: 10, fontFamily: "Bold", fontSize: 18, marginTop: 10 }}>{data.title}</StyledText>
                        <HTML
                            baseFontStyle={{ fontFamily: "Regular", fontSize: 14, width: "100%", color: "#303030" }}
                            html={data.content ? data.content.slice(0, 150) : "<p></p>"}
                            imagesMaxWidth={800}
                            staticContentMaxWidth={800}
                            onLinkPress={async (evt, href) => await WebBrowser.openBrowserAsync(href.toLowerCase().includes("://") ? href : `https://${href}`)}
                        />
                        <View style={{ marginTop: 10, flexDirection: "row", justifyContent: "space-between", alignItems: "center" }}>
                            {/* <View style={{ flexDirection: "row" }}>
                                <View style={{ flexDirection: "row", alignItems: "center", marginRight: 15 }}>
                                    <Ionicons color="red" name="heart" size={25} />
                                    <StyledText style={{ marginLeft: 5, fontFamily: "Bold", color: "grey", fontSize: 14 }}>{data.likesCount}</StyledText>
                                </View>
                                <View style={{ flexDirection: "row", alignItems: "center" }}>
                                    <Ionicons name="chatbubble-ellipses-outline" size={25} />
                                    <StyledText style={{ marginLeft: 5, fontFamily: "Bold", color: "grey", fontSize: 14 }}>{data.commentsCount}</StyledText>
                                </View>
                            </View> */}
                            {data.contentLength ? <StyledText style={{ fontSize: 14, fontFamily: "Bold" }}>{data.contentLength} min</StyledText> : null}
                        </View>
                    </View>
                </TouchableOpacity>
            ) : (
                <View style={[{ paddingVertical: 10, height: 120, justifyContent: "center", alignItems: "center", paddingHorizontal: 15, borderRadius: 10, width: "100%", backgroundColor: "white", marginTop: 20 }, styles.shadow]}>
                    <StyledText style={{ fontFamily: "DemiBold", opacity: 0.5 }}>Sin contenidos Destacados</StyledText>
                </View>
            )}
        </View>
    );
}

export default CourseFeaturedContent;


const styles = StyleSheet.create({
    shadow: {
        shadowColor: "#000",
        shadowOffset: {
            width: 0,
            height: 5,
        },
        shadowOpacity: 0.1,
        shadowRadius: 6.68,

        elevation: 11,
    },
});
