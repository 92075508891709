import React, { useState } from "react";
import {
  View,
  Text,
  TouchableOpacity,
  StyleSheet,
  Image
} from "react-native";
import { Ionicons } from "@expo/vector-icons";
import moment from "moment";
import SecureImage from "../common/SecureImage";

const CardPost = ({
  pressCallback,
  title = "",
  image = null,
  publishDate = "",
  likes = [],
  comments = [],
}) => {
  const date = moment(publishDate).format("DD MMMM");

  return (

    <TouchableOpacity style={[styles.shadow, { flexDirection: "row", marginHorizontal: 10, flex: 1, backgroundColor: "white", borderRadius: 10, alignItems: "center" }]} onPress={() => pressCallback()}>

      {/* Bloque de Contenido */}
      <View style={{ justifyContent: "space-between", height: 130, flex: 5, paddingBottom: 15, paddingLeft: 20, paddingRight: 10, paddingTop: 15 }}>
        {/* Categorias */}
        {/* <View style={{ flexDirection: "row", flexWrap: "wrap-reverse" }}>
          {categories.map(category => (
            <TouchableOpacity key={category.id} style={styles.categoryContainer} onPress={() => navigate("Category", { id: category.id })}>
              <Text style={styles.categoryText}>{category.name}</Text>
            </TouchableOpacity>
          ))}
        </View> */}

        {/* Titulo del Post */}
        <View style={{ flex: 1 }}>
          <Text style={styles.title} numberOfLines={3} truncate="true">{title}</Text>
        </View>
        {/* Block Likes and Comments and Date */}
        <View
          style={{
            flexDirection: "row",
            justifyContent: "space-between",
            alignItems: "center"
          }}
        >
          <View style={{ flexDirection: "row" }}>
            <View
              style={{
                flexDirection: "row",
                alignItems: "center",
                marginRight: 15
              }}
            >
              <Ionicons
                name="heart"
                size={20}
                style={{ marginRight: 10, color: "#ef0000" }}
              />
              <Text style={{ fontSize: 12, fontFamily: "Regular" }}>
                {likes ? likes.length : 0}
              </Text>
            </View>
            <View style={{ flexDirection: "row", alignItems: "center" }}>
              <Ionicons
                name="md-chatbubbles"
                size={20}
                style={{ marginRight: 10, color: "gray" }}
              />
              <Text style={{ fontSize: 12, fontFamily: "Regular" }}>
                {comments ? comments.length : 0}
              </Text>
            </View>
          </View>

          <View style={styles.dateContainer}>
            <Text style={styles.date}>{date}</Text>
          </View>
        </View>

      </View>

      {/* Bloque de Imagen */}
      <View style={{ flex: 3, overflow: "hidden", borderRadius: 10, height: 120, marginRight: 5, }}>
        <SecureImage
          style={{ flex: 1, borderRadius: 12, resizeMode: "cover", }}
          uri={image}
        />
      </View>




    </TouchableOpacity>
  );
};

export default CardPost;

const styles = StyleSheet.create({
  userInfoContainer: {
    flexDirection: "row",
    height: 45,
    width: "100%",
    marginBottom: 5
  },
  shadow: {
    shadowColor: "#000000",
    shadowOffset: {
      width: 0,
      height: 10,
    },
    shadowOpacity: 0.1,
    shadowRadius: 10.00,
    backgroundColor: "white",

    elevation: 24,
  },
  title: {
    // marginVertical: 20,
    fontSize: 14,
    fontFamily: "DemiBold",
    marginBottom: 15
  },
  dateContainer: {
    backgroundColor: "#f2f2f2",
    padding: 5,
    borderRadius: 5,
    marginLeft: 10,
  },
  date: {
    fontSize: 12,
    color: "gray",
    fontFamily: "Regular",

  },
  likeContainer: {
    position: "absolute",
    right: 0,
    width: 45,
    alignItems: "center",
    flexDirection: "row",
    justifyContent: "center"
  },
  userImage: {
    resizeMode: "cover",
    borderRadius: 20,
    height: 40,
    width: 40
  },
  card: {
    flex: 1,
    minHeight: 130,
    width: "100%",
    backgroundColor: "white",
    borderRadius: 7
  },
  categoryContainer: {
    marginBottom: 5,
    marginRight: 5,
    backgroundColor: "#D82E2C",
    alignSelf: "flex-start",
    borderRadius: 15,
    paddingVertical: 5,
    paddingHorizontal: 10
  },
  categoryText: {
    color: "white",
    fontSize: 10,
    fontFamily: "DemiBold"
  }
});
