import React, { useContext } from "react";
import { View, StatusBar, Platform } from "react-native";

import CommunityFeed from "../../components/contentComponents/CommunityFeed";
import NewsFeed from "../../components/contentComponents/NewsFeed";

import { createMaterialTopTabNavigator } from "@react-navigation/material-top-tabs";
import AuthContext from "../../context/AuthContext";
import Header from "../../components/AppHeader";

const Tab = createMaterialTopTabNavigator();

const ContentHome = ({ route }) => {
	const { customization, userData, tenant } = useContext(AuthContext);
	if (tenant) {
		return (
			<NewsFeed />
			// <Tab.Navigator
			// 	lazy
			// 	initialRouteName="NewsFeed"
			// 	swipeEnabled={Platform.OS !== "web"}
			// 	tabBarOptions={{
			// 		style: { display: userData?.zonas?.community ? "flex" : "none" },
			// 		labelStyle: { fontSize: 18, fontFamily: "DemiBold", textTransform: "none" },
			// 		indicatorStyle: { backgroundColor: customization.mainColor, height: 4 },
			// 	}}>
			// 	<Tab.Screen key={1} options={{ title: "Noticias y Novedades" }} name="NewsFeed" component={NewsFeed} />
			// 	<Tab.Screen key={2} options={{ title: "Comunidad" }} name="CommunityFeed" component={CommunityFeed} />
			// </Tab.Navigator>
		);
	} else {
		return null
	}
};

export default ContentHome;
