import React, { useContext, useEffect, useRef, useState } from "react";
import { ActivityIndicator, ScrollView, StatusBar, StyleSheet, Text, TextInput, TouchableOpacity, TouchableWithoutFeedback, View } from "react-native";



import * as Animatable from 'react-native-animatable';
import CircularIndicator from "../../components/CircularIndicator";
import LinearBarIndicator from '../../components/LinearBarIndicator';

import { useSafeAreaInsets } from 'react-native-safe-area-context';

import AuthContext from "../../context/AuthContext";

import { addDoc, arrayUnion, collection, doc, getDoc, getFirestore, updateDoc } from "firebase/firestore";
import Modal from "../../components/common/Modal";
import PointsContext from "../../context/PointsContext";
import { decideColor } from "../../utils/functions";


const Exam = ({ route, navigation }) => {

    const { userData, customization, tenant } = useContext(AuthContext)
    const { state, dispatch } = useContext(PointsContext);
    const db = getFirestore()

    // VARIABLES BASICAS 
    const { id, courseID } = route.params;

    const [loadIndicator, setLoadIndicator] = useState(false)
    const [examResults, showResults] = useState(false)
    const [results, setResults] = useState({})
    const [tries, setTries] = useState(0)

    const [questions, setQuestions] = useState([])
    const [currentQuestion, setCurrentQuestion] = useState(null)

    const [answers, setAnswers] = useState([])
    const answersRef = useRef([])

    // NUEVOS
    const [selectedAnswer, setSelectedAnswer] = useState(null)
    const [showFeedback, setShowFeedback] = useState(false)
    const [examData, setExamData] = useState({});

    const [advance, setAdvance] = useState({})
    const [activityAdvance, setActivityAdvance] = useState(null)
    const questionRef = useRef()
    const insets = useSafeAreaInsets()





    // ANIMACIONES
    const zoomIn = { 0: { scale: 1 }, 1: { scale: 1.02 } }
    const superPulse = { 0: { scale: 1 }, 1: { scale: 1.3 } }


    useEffect(() => {
        fetchExam()
        return () => {
            setLoadIndicator(false);
        }
    }, [tenant, id, courseID])


    const fetchExam = () => {
        const course = userData?.courses?.find(el => el.id === courseID)
        const ad = userData?.advances?.find(el => courseID === el.courseRef?.id)
        setAdvance(ad)

        const activity = course?.contentDetails?.reduce((acc, cur) => [...acc, ...(cur?.contents || [])], []).find(el => el.ref?.id === id)
        const activityAdance = ad?.advance?.find(el => el.id === id)
        setLoadIndicator(true);

        const docRef = doc(db, `${tenant.path}/exams/`, id);
        getDoc(docRef).then(doc => {
            if (doc.exists()) {
                setExamData({ ...doc.data(), id: doc.id, ref: doc.ref, ...activity });
                setQuestions(doc.data()?.advance || []);

                if (activityAdance) {
                    setActivityAdvance(activityAdance)
                }

                if (activityAdance && activityAdance?.tries?.length >= activity.maxTries) {
                    showResults(true)
                    setTries(activity.maxTries)
                    setResults({
                        percentage: activityAdance?.tries[activityAdance?.tries?.length - 1]?.percentage,
                        correct: activityAdance?.tries[activityAdance?.tries?.length - 1].correct,
                        total: activityAdance?.tries[activityAdance?.tries?.length - 1].total
                    })
                    setCurrentQuestion(0)
                    alert("No tienes mas intentos")
                } else {
                    setCurrentQuestion(0)
                    setTries((activityAdance?.tries?.length || 0) + 1)
                }

            } else {
                alert("No se encontro el examen")
            }

            setLoadIndicator(false);
        }).catch(err => {
            console.log(err)
            alert("Ocurrión un problema cargando el examen")
        })

    }


    const checkAnswer = () => {
        if (questions[currentQuestion].withFeedback || questions[currentQuestion].withGlobalFeedback) {
            setShowFeedback(true)
        } else {
            _nextQuestion()
        }
    }

    const _nextQuestion = () => {
        const currentAnswer = answersRef.current[currentQuestion]
        const nextAnswer = answersRef.current[currentQuestion + 1]

        if (currentAnswer) {
            const arr = [...answersRef.current]
            arr[currentQuestion] = { ...currentAnswer, response: selectedAnswer }
            answersRef.current = [...arr]
            setAnswers([...arr])
        } else {
            answersRef.current = [...answersRef.current, { ...questions[currentQuestion], response: selectedAnswer }]
            setAnswers([...answersRef.current, { ...questions[currentQuestion], response: selectedAnswer }])
        }

        if (questions[currentQuestion + 1]) {
            questionRef.current.fadeOutLeft(200).then(() => {
                setCurrentQuestion(currentQuestion + 1);
                if (nextAnswer) {
                    setSelectedAnswer(answers[currentQuestion + 1].response)
                } else {
                    setSelectedAnswer(null)
                }

                questionRef.current.fadeInRight(200);
            })
        } else {
            finishExam()
            showResults(true)
        }
        setShowFeedback(false)
    }


    // previous question se queda, tal y como funciona
    const previousQuestion = async () => {
        questionRef.current.fadeOutLeft(200).then(() => {
            questionRef.current.fadeInRight(200)
            setCurrentQuestion(currentQuestion - 1)
            setSelectedAnswer(answers[currentQuestion - 1]?.response)
        })
    }
    const finishExam = () => {
        const n = checkExam()
        const percentage = n.correct / answersRef.current.length * 100
        const colRef = collection(db, `${advance.ref.path}/exams`);
        // console.log(examData.percentageToPass, percentage, "APROBADO ?", examData.percentageToPass <= percentage)
        // FIRST CREATE THE EXAM RESULTS SAVED INSIDE THE ADVANCE
        addDoc(colRef, {
            answers: answersRef.current,
            percentage,
            correct: n.correct,
            wrong: n.wrong,
            total: answers.length,
            createdAt: new Date(),
            user: userData.ref,
            advance: advance.ref,
            status: "PENDING",
        }).then(res => {
            // THEN UPDATE THE ADVANCE ITSELF ADDING A TRY
            if (activityAdvance) {
                const arr = [...(activityAdvance.tries || [])]
                arr.push({
                    ref: res,
                    percentage,
                    correct: n.correct,
                    wrong: n.wrong,
                    total: answers.length,
                    createdAt: new Date(),
                })

                const newAdvance = [...advance.advance]

                const advanceToChangeIndex = newAdvance.findIndex(elem => elem.id === activityAdvance.id)

                newAdvance[advanceToChangeIndex] = {
                    ...newAdvance[advanceToChangeIndex],
                    tries: arr,
                    updatedAt: new Date(),
                    completed: !examData?.showResults ? true : percentage >= examData.percentageToPass,
                }

                updateDoc(advance.ref, {
                    advance: newAdvance
                }).catch(err => {
                    console.log(err)
                })
            } else {
                updateDoc(advance.ref, {
                    advance: arrayUnion({
                        id: id,
                        completed: !examData?.showResults ? true : percentage >= examData.percentageToPass,
                        updatedAt: new Date(),
                        createdAt: new Date(),
                        tries: [
                            {
                                ref: res,
                                percentage,
                                correct: n.correct,
                                wrong: n.wrong,
                                total: answers.length,
                                createdAt: new Date(),
                            }
                        ]

                    })
                }).catch(err => {
                    console.log(err)
                })
            }
        })


        examData?.showResults ?
            setResults({ percentage, correct: n.correct, total: answers.length })
            :
            navigation.goBack()
    }



    const restartExam = () => {
        setLoadIndicator(false)
        setResults({})
        setCurrentQuestion(0)
        showResults(false)
        answersRef.current = []
        setAnswers([])
        setSelectedAnswer(null)
        fetchExam()
        
    }

    const checkExam = () => {
        let correct = 0
        let wrong = 0
        answersRef.current.map(question => {
            if (question.answers[question.response]?.correctAnswer) {
                correct++
            } else {
                wrong++
            }
        })
        return {
            correct,
            wrong
        };
    }


    const completeCourse = () => {
        // setLoadIndicator(true)
        navigation.goBack()
    }



    const isAnswerSelected = (i) => {
        if (selectedAnswer === i) {
            return true
        } else {
            if (Array.isArray(selectedAnswer)) {
                return selectedAnswer.includes(i)
            } else {
                return false
            }
        }
    }


    const advancePercentage = currentQuestion / questions.length * 100

    // console.log(currentQuestion, questions.length, examResults, advancePercentage)
    return (
        <View style={{ backgroundColor: customization.mainColor, flex: 1, paddingTop: !examResults ? insets.top + 10 : 10 }}>

            <StatusBar barStyle="light-content" />

            <View style={{ justifyContent: "center", minHeight: 50, alignItems: "center", marginLeft: 70, marginRight: 20 }}>
                <LinearBarIndicator
                    percentage={examResults ? 100 : advancePercentage}
                    backgroundColor="white"
                    gradient={['#B4EC51', '#429321']}
                    width={"100%"}
                    rounded={5}
                    height={10}
                />
            </View>

            {showFeedback && (
                <Modal  >
                    <View style={{ flex: 1, maxWidth: 900, justifyContent: "center", alignItems: "center" }}>
                        <View style={{ padding: 25 }}>
                            <Text style={{ fontSize: 20, fontWeight: "bold", color: "black" }}>{questions[currentQuestion]?.globalFeedback ? questions[currentQuestion]?.globalFeedbackText || "" : questions[currentQuestion].openAnswer ? questions[currentQuestion].openAnswerFeedback : questions[currentQuestion]?.answers[selectedAnswer - 1]?.feedback}</Text>
                        </View>

                        <TouchableOpacity
                            onPress={() => currentQuestion < questions?.length ? _nextQuestion() : finishExam()}
                            style={{ paddingHorizontal: 25, height: 50, backgroundColor: 'white', borderColor: "gainsboro", borderWidth: 4, borderRadius: 7, alignItems: 'center', justifyContent: 'center' }}>
                            <Text style={{ color: 'black', fontFamily: 'DemiBold' }}>Continuar</Text>
                        </TouchableOpacity>

                    </View>
                </Modal>
            )}

            {!examResults ?
                <View style={{ flex: 1 }}>
                    <ScrollView contentContainerStyle={{ flexGrow: 1, width: "100%", maxWidth: 800, alignSelf: "center" }}>
                        <View style={{ width: "100%", paddingTop: 20, paddingHorizontal: 10, justifyContent: 'center', marginBottom: 10 }}>
                            <Text style={{ fontSize: 22, color: decideColor(customization.mainColor), fontFamily: 'DemiBold' }}>{currentQuestion !== null ? questions[currentQuestion]?.question : " "}</Text>
                        </View>

                        <Animatable.View ref={questionRef} duration={250} animation={"fadeIn"} style={{ flex: 1, paddingHorizontal: 10, justifyContent: 'center' }}>
                            {currentQuestion !== null && questions[currentQuestion]?.answers.map((answer, i) => {
                                const selected = isAnswerSelected(i)
                                if (questions[currentQuestion]?.openAnswer) {
                                    return (
                                        <View key={i}>
                                            <Animatable.View duration={200} style={[styles.answer, styles.shadow]} >
                                                <TextInput
                                                    placeholder="Escribe aquí tu respuesta..."
                                                    // disabled={answers?.length === examData.length ? true : false}
                                                    autofocus
                                                    multiline
                                                    numberOfLines={6}
                                                    value={selectedAnswer || ""}
                                                    onChange={(e) => setSelectedAnswer(e.target.value)}>
                                                </TextInput>
                                            </Animatable.View>
                                        </View>
                                    )
                                }
                                return (
                                    <View key={i}>
                                        <TouchableWithoutFeedback
                                            // disabled={(examData[currentQuestion].response)}
                                            accessibilityRole="button"
                                            style={{ cursor: "pointer" }}
                                            onPress={() => {
                                                if (questions[currentQuestion]?.multipleAnswer) {
                                                    selectedAnswer?.includes(i) ?
                                                        setSelectedAnswer(selectedAnswer.filter(answer => answer !== i)?.length === 0 ? null : selectedAnswer.filter(answer => answer !== i))
                                                        :
                                                        setSelectedAnswer([...(selectedAnswer || []), i])
                                                } else {
                                                    setSelectedAnswer(i)
                                                }
                                            }} >
                                            <Animatable.View duration={200} animation={selected ? zoomIn : null} style={[styles.answer, styles.shadow, selected ? styles.active : null]} >
                                                <Text style={{ color: selected ? "white" : "black", fontFamily: selected ? 'Bold' : 'DemiBold' }}>{answer.text}</Text>
                                            </Animatable.View>
                                        </TouchableWithoutFeedback>
                                        {/* {answers?.length === questions.length && answer.correctAnswer &&
                                            <View style={[styles.shadow, { backgroundColor: "white", borderRadius: 20, borderWidth: 2, alignItems: "center", justifyContent: "center", position: "absolute", width: 40, height: 40, zIndex: 20, elevation: 20, top: -10, right: -5 }]}>
                                                <Ionicons size={37} name="ios-checkmark-circle-outline" color="orange" />
                                            </View>
                                        } */}
                                    </View>
                                )
                            })}
                        </Animatable.View>
                    </ScrollView>

                    <View style={{ height: 70, alignItems: 'center', flexDirection: 'row' }}>

                        {currentQuestion > 0 &&
                            <TouchableOpacity onPress={() => { previousQuestion() }} style={{ paddingHorizontal: 25, opacity: .8, flex: 1, position: 'absolute', left: 10, height: 50, bottom: (insets.bottom / 2) + 10, backgroundColor: 'gainsboro', borderRadius: 7, alignItems: 'center', justifyContent: 'center' }}>
                                <Text style={{ color: 'black', fontFamily: 'DemiBold' }}>Anterior</Text>
                            </TouchableOpacity>
                        }
                        {/* currentQuestion === examAdvance.advance.length - 1 ? finishExam() :  */}
                        {selectedAnswer !== null &&
                            <TouchableOpacity
                                // disabled={!loadIndicator}
                                // onPress={() => (examAdvance[currentQuestion]?.response && currentQuestion - 1 !== examAdvance.advance.length) ? checkAnswer() : _nextQuestion()}
                                onPress={checkAnswer}
                                style={{ paddingHorizontal: 25, flex: 1, position: 'absolute', right: 10, height: 50, backgroundColor: 'white', borderColor: "gainsboro", borderWidth: 4, bottom: (insets.bottom / 2) + 10, borderRadius: 7, alignItems: 'center', justifyContent: 'center' }}
                            >
                                {
                                    <Text style={{ color: 'black', fontFamily: 'DemiBold' }}>
                                        Siguiente
                                    </Text>
                                }
                            </TouchableOpacity>
                        }

                        {/* {currentQuestion === answers?.length - 1 &&
                            <TouchableOpacity onPress={() => answers?.length !== examData?.length ? _nextQuestion() : finishExam()} style={{ paddingHorizontal: 25, flex: 1, position: 'absolute', right: -5, height: 50, backgroundColor: 'white', borderColor: "gainsboro", borderWidth: 4, bottom: (insets.bottom / 2) + 10, borderRadius: 7, alignItems: 'center', justifyContent: 'center' }}>
                                <Text style={{ color: 'black', fontFamily: 'DemiBold' }}>Terminar</Text>
                            </TouchableOpacity>
                        } */}
                    </View>


                </View>
                :
                // RESULTADOS EXAMEN
                <View style={{ flex: 1, width: '100%' }}>
                    <ScrollView contentContainerStyle={{ marginHorizontal: 10, flexGrow: 1 }}>
                        <Animatable.View animation="fadeIn" duration={600} style={{ flex: 1, alignItems: "center", justifyContent: "center" }}>
                            <View style={{ maxWidth: 350, alignItems: "center" }}>
                                <Text style={{ fontSize: 36, fontFamily: "DemiBold", textAlign: "center", color: "white" }}>Tu resultado</Text>
                                <View style={[styles.shadow, { backgroundColor: "white", padding: 25, paddingVertical: 10, borderRadius: 7, marginTop: 20, marginBottom: 20 }]}>
                                    <Text style={{ fontSize: 42, textAlign: "center", fontFamily: "DemiBold" }}>
                                        {results.correct} de {questions.length}
                                    </Text>
                                </View>
                            </View>
                            <View style={{ alignItems: "center", justifyContent: "center", marginVertical: 10, marginBottom: 30, width: 220, height: 220 }}>
                                <CircularIndicator r={95} strokeWidth={12} percentage={results.percentage} />
                                <View style={{ position: "absolute", width: "100%", height: "100%", alignItems: "center", justifyContent: "center" }}>
                                    <Text style={{ fontSize: 40, fontFamily: "Bold", color: "white" }}>{Math.round(results.percentage)}%</Text>
                                </View>
                            </View>
                            <View style={{ maxWidth: 900, marginVertical: 15, backgroundColor: "#f5f5f5", width: "100%", paddingVertical: 15, paddingHorizontal: 10, borderRadius: 10 }}>
                                <Text style={{ fontSize: 18, textAlign: "center", fontFamily: "Regular" }}>
                                    {examData.percentageToPass <= results.percentage ?
                                        "Has superado esta evaluación con éxito!"
                                        :
                                        "No has superado la evaluación. Necesitabas al menos contestar correctamente el " + examData.percentageToPass + "% de las preguntas."}
                                </Text>

                                {examData.mandatory && examData.maxTries && examData.percentageToPass >= results.percentage &&
                                    <Text style={{ marginTop: 10, fontSize: 18, textAlign: "center", fontFamily: "Regular" }}>
                                        Llevas {tries} intentos de {examData.maxTries}
                                    </Text>
                                }
                            </View>
                            <View style={{ flexDirection: "row" }}>
                                {examData.mandatory && examData.percentageToPass >= results.percentage && tries < examData.maxTries &&
                                    <TouchableOpacity onPress={() => restartExam()} style={{ marginRight: 15, paddingHorizontal: 40, marginTop: 10, alignItems: "center", justifyContent: "center", backgroundColor: "white", height: 50, borderRadius: 10 }}>
                                        {loadIndicator ?
                                            <ActivityIndicator style={{ height: 20, width: 20 }} color={customization.mainColor} />
                                            :
                                            <Text style={{ fontSize: 16, fontFamily: "DemiBold", color: customization.mainColor }}>Volver a Intentarlo</Text>
                                        }
                                    </TouchableOpacity>
                                }
                                {(!examData.mandatory || examData.percentageToPass <= results.percentage || tries >= examData.maxTries) &&
                                    <TouchableOpacity onPress={() => completeCourse()} style={{ paddingHorizontal: 40, marginTop: 10, alignItems: "center", justifyContent: "center", backgroundColor: "white", height: 50, borderRadius: 10 }}>
                                        {loadIndicator ?
                                            <ActivityIndicator style={{ height: 20, width: 20 }} color={customization.mainColor} />
                                            :
                                            <Text style={{ fontSize: 16, fontFamily: "DemiBold", color: customization.mainColor }}>Continuar</Text>
                                        }
                                    </TouchableOpacity>
                                }
                            </View>
                        </Animatable.View>
                    </ScrollView>
                </View >}
        </View>)
}

export default Exam

const styles = StyleSheet.create({
    active: {
        backgroundColor: '#41C714',
        borderColor: "#119429"
    },
    answer: {
        minHeight: 70,
        justifyContent: "center",
        marginHorizontal: 20,
        padding: 10,
        borderWidth: 4,
        borderColor: "#f5f5f5",
        backgroundColor: "white",
        borderRadius: 15,
        marginBottom: 10
    },
    shadow: {
        shadowColor: "rgba(0, 0, 0, 0.3)",
        borderColor: "#f5f5f5",
        borderWidth: 6,
        shadowOffset: { width: 0, height: 10 },
        shadowOpacity: 0.5,
        shadowRadius: 10,
        elevation: 10
    }
});