import React, { useContext, useEffect, useRef, useState } from "react";

import { Ionicons } from "@expo/vector-icons";
import { useNavigation } from "@react-navigation/native";
import { ActivityIndicator, Linking, Platform, ScrollView, StyleSheet, Text, TouchableOpacity, useWindowDimensions, View } from "react-native";

import LinearBarIndicator from "../../components/LinearBarIndicator";
import AuthContext from "../../context/AuthContext";

import * as WebBrowser from "expo-web-browser";
import MediaViewerManager from "../../components/common/MediaViewerManager";
import PointsContext from "../../context/PointsContext";



import * as Animatable from "react-native-animatable";

import { arrayUnion, getDoc, increment, updateDoc } from "firebase/firestore";
import { getDownloadURL, getStorage, ref, uploadBytes } from "firebase/storage";
import moment from "moment";
import HTML from "react-native-render-html";
import { useSafeAreaInsets } from "react-native-safe-area-context";
import Button from "../../components/common/Button";
import SecureImage from "../../components/common/SecureImage";
import StyledText from "../../components/common/StyledText";
import QuestionComponent from "../../components/contentComponents/QuestionComponent";



const ActivityGroup = ({ route }) => {

	const timeoutRef = useRef(null);
	const navigation = useNavigation();
	const insets = useSafeAreaInsets();
	const sc = useWindowDimensions()
	const { id, index } = route.params;

	const { userData, dispatchUserData, tenant, customization } = useContext(AuthContext);
	const { givePoints } = useContext(PointsContext);
	const mediaHandlerRef = useRef()
	const storage = getStorage()

	const [loading, setLoading] = useState(true);

	const [submitting, setSubmitting] = useState(false);

	const [advance, setAdvance] = useState(null)
	const [advanceRef, setAdvanceRef] = useState(null)
	// const [course, setCourse] = useState(null)
	// const [masterRef, setMasterRef] = useState(null)
	// const [userResponse, setUserResponse] = useState(null);

	const [sectionContents, setSectionContents] = useState([]);

	const [currentContent, setCurrentContent] = useState(0);
	const [currentAdvance, setCurrentAdvance] = useState(null);

	const [progress, setProgress] = useState(0);

	const [fileToUpload, setFileToUpload] = useState(null);

	const [timeLeft, setTimeLeft] = useState(null);
	const [duration, setDuration] = useState(null);


	useEffect(() => {
		document.addEventListener('contextmenu', event => event.preventDefault());

		return () => {
			setLoading(true)
			setAdvance([])
			setAdvanceRef(null)
			setLoading(true)
			timeoutRef.current = null;
			document.removeEventListener('contextmenu', event => null);
		}
	}, [])

	useEffect(() => {
		setLoading(true)
	},[index])

	useEffect(() => {
		if (loading) {
			if (userData?.courses) {
				const d = userData?.courses?.find(el => el?.id === id);
				const a = userData?.advances?.find(el => el?.courseRef?.id === id);

				if (d) {
					if (!d?.ref.path.includes(tenant?.id)) {
						navigation.navigate('SmartHome')
						return
					}
					// setCourse(d)
					// setMasterRef(a?.masterRef);
					setAdvance(a?.advance);
					setAdvanceRef(a?.ref);
					setupData(d, a?.advance);
				} else {
					navigation.navigate('SmartHome')
				}
			} else {
				navigation.navigate('SmartHome', { course: id })
			}
		}
		if (userData?.advances) {
			const a = userData?.advances?.find(el => el?.courseRef?.id === id);
			setAdvance(a?.advance)
		}
	}, [userData, id, index, tenant])

	const setupData = async (d, advance) => {
		let final = d.contentDetails[index]
			? await Promise.all(
				d.contentDetails[index].contents.map(async (content) => {
					const conts = await getDoc(content.ref).then(async (doc) => {
						if (doc.exists()) {
							const d = doc.data();
							let topic;
							if (d.contentType === "FORUM") {
								topic = await getDoc(d.contentDetails.topicRef)
									.then((res) => ({ ...res.data(), id: res.id }))
									.catch((err) => console.log(err));
							}
							return { id: doc.id, ...d, topic };
						} else {
							return {};
						}
					});
					return { ...conts, ...content };
				})
			).then((res) => res)
			: [];

		setLoading(false);

		setSectionContents(final);
		goToLast(final, advance);
	};


	const goToLast = (contents, advance) => {
		const c = contents
			?.map((content) => {
				const d = advance?.find((el) => el.id === content.id);
				return d?.completed;
			})
			.filter((x) => x).length;
		setCurrentContent(c === 0 ? 0 : c === contents.length ? 0 : c);
	};

	useEffect(() => {
		const d = advance?.find((el) => el.id === (sectionContents[currentContent]?.contentType === "EXAM" ? sectionContents[currentContent]?.ref?.id : sectionContents[currentContent]?.id));

		setCurrentAdvance(d);


		if (sectionContents[currentContent]) {
			const t = d?.timeLeft ? d?.timeLeft : sectionContents[currentContent]?.contentLength ? sectionContents[currentContent]?.contentLength * 60 : 30;

			sectionContents[currentContent]?.contentLength && setDuration(sectionContents[currentContent]?.contentLength * 60)

			if (d?.completed) {
				// console.log("COMPLETED")
				setTimeLeft(null);
				setTimer(0, false);
			} else if (sectionContents[currentContent]?.mandatory) {
				if (sectionContents[currentContent]?.contentType === "VIDEO" || sectionContents[currentContent]?.contentType === "AUDIO") {
					// console.log("1")
					if (d?.timeLeft) {
						timeoutRef.current = true
						setTimeLeft(d?.timeLeft)
					}
				} else if (sectionContents[currentContent]?.contentType === "FORUM") {
					// console.log("2")
					// setTimeLeft("WAIT");
				} else if (sectionContents[currentContent]?.contentType === "WEBINAR" || sectionContents[currentContent]?.contentType === "EVENT") {
					// console.log("3")
					if (sectionContents[currentContent]?.date) {
						const d = sectionContents[currentContent]?.date;
						const dif = new Date(d).getTime() - new Date().getTime();
						const s = dif / 1000;
						if (s < 0) {
							setTimeLeft(s);
						} else {
							setTimeLeft("WAIT");
						}
					}
				} else if (sectionContents[currentContent]?.contentType === "EXAM"){
					
				} else {
					// console.log("4")
					if (d?.timeLeft) {
						timeoutRef.current = true
						setTimer(d?.timeLeft, true)
					} else {
						timeoutRef.current = true
						setTimer(t, true)
					}
				}
			} else {
				// console.log("5")
				setTimeLeft(0);
			}
		}

		if (d?.file) {
			const fileRef = ref(storage, d.file)
			getDownloadURL(fileRef)
				.then((res) => setCurrentAdvance({ ...d, file: res }))
				.catch((err) => "");
		}

		setFileToUpload(null)

	}, [sectionContents, currentContent, advance]);


	const uploadFile = async (file) => {
		// let result = await fetch(uri);
		// const blob = await result.blob();
		var storageRef = ref(storage, `${userData.tenants[0].id}/users/${userData.id}/files/${file.name}`)

		const data = uploadBytes(storageRef, file.file)
			.then((snapshot) => snapshot.ref.fullPath)
			.catch((err) => err);
		return data;
	};

	const previousContent = () => {
		//GO BACK TO THE PREVIOUS CONTENT
		setTimeLeft(0)
		timeoutRef.current = false
		currentContent === 0 ? null : setCurrentContent((currentContent) => currentContent - 1);
	};

	const nextContent = (videoEnd) => {
		//Go TO THE NEXT CONTENT
		const content = sectionContents[currentContent];
		if (sectionContents.length - 1 === currentContent) {
			completeSection(currentContent);
		} else {
			completeContent(content, videoEnd);
		}
	};

	const canGoNext = () => {
		// THIS FUNCTION SHOW THE NEXT BUTTON IF AVAILABLE AND MEET CONDITIONS

		if ((userData.role === "User" || userData.role === "Coordinator" || userData.role === "SuperAdmin" || userData.role === "Admin") && sectionContents[currentContent]?.mandatory) {
			if (sectionContents[currentContent]?.contentType === "WEBINAR" || sectionContents[currentContent]?.contentType === "EVENT") {
				if (sectionContents[currentContent]?.contentDetails?.date) {
					return new Date(sectionContents[currentContent]?.contentDetails?.date) - new Date() < 0;
				} else {
					return true;
				}
			} else if (sectionContents[currentContent]?.contentType === "FORUM") {
				return true;
			} else if (sectionContents[currentContent]?.contentType === "UPLOAD") {
				return fileToUpload !== null;
			} else if (sectionContents[currentContent]?.contentType === "EXAM") {
				return currentAdvance?.completed
			} else {
				if (timeLeft === 0) {
					return true;
				} else {
					return false;
				}
			}
		} else if (userData.role === "Admin") {
			return true;
		} else {
			return true;
		}
	};

	const completeContent = async (content, end) => {
		// COMPLETE THE CONTENT IF NEEDED ON THE USERS ADVANCE DATA ARRAY
		const contentAdvance = advance?.find((el) => el.id === content.id);
		const contentAdvanceIndex = advance?.findIndex((el) => el.id === content.id);

		if (contentAdvance) {
			const arr = [...advance];

			if (content.contentType === "UPLOAD" && fileToUpload) {
				setSubmitting(true);


				await uploadFile(fileToUpload)
					.then((res) => {
						arr[contentAdvanceIndex] = {
							...arr[contentAdvanceIndex],
							file: res,
							updatedAt: new Date()
						};
						updateDoc(advanceRef, { advance: [...arr] })
							.then(() => {
								setSubmitting(false);
								setCurrentContent((currentContent) => currentContent + 1);
							})
							.catch((err) => {
								alert("Ha ocurrido un error cargando el archivo, inténtelo de nuevo");
								setSubmitting(false);
								console.log(err);
							});
					})
					.catch((err) => {
						alert("Ha ocurrido un error cargando el archivo, inténtelo de nuevo");
						setSubmitting(false);
						return false;
					});
			} else if ((content.contentType === "VIDEO" || content.contentType === "EMBEDDED") && !arr[contentAdvanceIndex]?.completed) {

				setSubmitting(true);
				arr[contentAdvanceIndex] = {
					...arr[contentAdvanceIndex],
					updatedAt: new Date(),
					completed: true,
					timeLeft: 0
				};

				updateDoc(advanceRef, {
					advance: [...arr],
					...(content?.autoPoints && content?.points && end && { points: increment(content?.points) }),
				})
					.then(() => {
						setSubmitting(false);
						setCurrentContent((currentContent) => currentContent + 1);
					})
					.catch((err) => {
						alert("Ha ocurrido un error cargando el archivo, inténtelo de nuevo");
						setSubmitting(false);
						console.log(err);
					});

			} else {
				setCurrentContent((currentContent) => currentContent + 1);
			}
		} else {
			setSubmitting(true);
			let file;

			if (content.contentType === "UPLOAD" && fileToUpload) {
				file = await uploadFile(fileToUpload)
					.then((res) => res)
					.catch((err) => {
						alert("Ha ocurrido un error cargando el archivo, inténtelo de nuevo");
						setSubmitting(false);
						return false;
					});
			}


			updateDoc(advanceRef, {
				advance: arrayUnion({
					id: content.id,
					completed: true,
					updatedAt: new Date(),
					createdAt: new Date(),
					...(file && { file }),
				}),
				...(content?.autoPoints && content?.points && { points: increment(content?.points) }),
			})
				.then(async (res) => {
					if (content?.autoPoints && content?.points) {

						givePoints({
							showPoints: true,
							points: content?.points,
							message: `¡Enhorabuena! Has conseguido ${content?.points} puntos al completar esta actividad.`,
						});

					}

					setSubmitting(false);
					setCurrentContent((currentContent) => currentContent + 1);
				})
				.catch((err) => {
					setSubmitting(false);
					console.log(err);
				});

		}
	};

	const updateAdvance = () => {
		const activity = sectionContents[currentContent];
		const activityAdvance = advance?.find((el) => el.id === activity?.id);
		const contentAdvanceIndex = advance?.findIndex((el) => el.id === activity.id);
		const advanceToChange = userData.advances.findIndex(el => el.id === advanceRef.id);
		const newAdvances = [...userData.advances]

		if (activityAdvance) {
			const arr = [...advance];
			arr[contentAdvanceIndex] = {
				...arr[contentAdvanceIndex],
				timeLeft
			};

			newAdvances[advanceToChange].advance = [...arr]
			updateDoc(advanceRef, { advance: [...arr] })
				.then(() => {
					dispatchUserData({ type: "UPDATE_DATA", data: { advances: [...newAdvances] } });
				})
				.catch((err) => {
					console.log(err);
				});
		} else {

			updateDoc(advanceRef, {
				advance: arrayUnion({
					id: activity.id,
					completed: false,
					updatedAt: new Date(),
					createdAt: new Date(),
					timeLeft
				})
			})
				.then((res) => {
					// console.log(res);
				})
				.catch((err) => {
					console.log(err);
				});

		}
	}

	const completeSection = async (index) => {
		// COMPLETE THE SECTION AND UPDATE ADVANCE DATA ARRAY ON THE USER IF NECESARY
		const activity = sectionContents[index];
		const activityAdvance = advance?.find((el) => el.id === activity.id);
		const contentAdvanceIndex = advance?.findIndex((el) => el.id === activity.id);
		// console.log("COMPLETNADO SECCION")
		if (activityAdvance) {
			if (activity.contentType === "UPLOAD" && fileToUpload) {
				setSubmitting(true);

				await uploadFile(fileToUpload)
					.then((res) => {
						const arr = advance;
						arr[contentAdvanceIndex] = {
							...arr[contentAdvanceIndex],
							file: res,
							updatedAt: new Date(),
							completed: true,
						};


						updateDoc(advanceRef, { advance: [...arr] })
							.then(() => {
								setSubmitting(false);
								navigation.navigate("StackSmartNew", { screen: "SmartHome" });
							})
							.catch((err) => {
								alert("Ha ocurrido un error cargando el archivo, inténtelo de nuevo");
								setSubmitting(false);
								console.log(err);
							});
					})
					.catch((err) => {
						alert("Ha ocurrido un error cargando el archivo, inténtelo de nuevo");
						setSubmitting(false);
						return false;
					});
			} else {
				navigation.navigate("StackSmartNew", { screen: "SmartHome" });
			}
		} else {
			setSubmitting(true);

			let file;

			if (activity.contentType === "UPLOAD") {
				file = await uploadFile(fileToUpload)
					.then((res) => res)
					.catch((err) => {
						alert("Ha ocurrido un error cargando el archivo, inténtelo de nuevo");
						setSubmitting(false);
						return false;
					});
			}
			const autoPoints = activity?.autoPoints || false

			updateDoc(advanceRef, {
				advance: arrayUnion({
					id: activity.id,
					completed: true,
					updatedAt: new Date(),
					createdAt: new Date(),
					...(file && { file }),
				}),
				...(!autoPoints && { coursePoints: increment(activity?.points) }),
			})
				.then((res) => {

					if (autoPoints) {
						givePoints({
							showPoints: true,
							points: activity?.points,
							message: `¡Enhorabuena! Has conseguido ${activity?.points} puntos al completar esta actividad`,
						});
					}

					setSubmitting(false);
					navigation.navigate("StackSmartNew", { screen: "SmartHome" });
				})
				.catch((err) => {
					setSubmitting(false);
					console.log(err);
				});
		}
	};

	const canNavigateToContent = (index) => {
		// CHECK IF YOU CAN NAVIGATE TO THIS ACTIVITY BASED ON THE OTHER CONTENTS
		if (index === currentContent) {
			return true;
		} else {
			const activity = sectionContents[index];
			const activityAdvance = advance?.find((el) => el.id === activity?.id);
			if (activityAdvance) {
				return true;
			} else {
				const prevActivity = sectionContents[index - 1];
				const prevActivityAdvance = advance?.find((el) => el.id === prevActivity?.id);
				if (prevActivityAdvance?.completed || index === 0) {
					return true;
				} else {
					return false;
				}
			}
		}
	};

	const isActivityCompleted = (index) => {
		// CHECK IF THE ACTIVITY IS COMPLETED, CHECKS ON THE USERS DATA ARRAY IF ITS AN ACTIVITY WITH THE SAME ID
		const activity = sectionContents[index];
		const activityAdvance = advance?.find((el) => el.id === (activity?.contentType === "EXAM" ? activity?.ref?.id : activity?.id));
		if (activityAdvance) {
			return activityAdvance.completed;
		} else {
			// console.log("ACTIVIDAD NO COMPLETADA")
			return false;
		}
	};

	const triesLeft = (index) => {
		// CHECK IF THE ACTIVITY IS COMPLETED, CHECKS ON THE USERS DATA ARRAY IF ITS AN ACTIVITY WITH THE SAME ID
		const activity = sectionContents[index];
		const activityAdvance = advance?.find((el) => el.id === activity?.id);
		if (activityAdvance) {
			return activityAdvance?.tries?.length || 0;
		} else {
			return 0;
		}
	}


	const setTimer = (t, running) => {
		if (t > 0 && running && timeoutRef.current) {
			setTimeout(() => {
				setTimeLeft(t - 1);
				setTimer(t - 1, true);
			}, 1000);
		}
	};

	if (loading) {
		return (
			<View style={{ flex: 1, alignItems: "center", justifyContent: "center" }}>
				<ActivityIndicator />
			</View>
		);
	}

	return (
		// Render de secciones
		<View style={{ flexDirection: "row", maxWidth: 1200, width: "100%", alignSelf: "center", flex: 1 }}>

			{sc.width > 1000 && (
				<View style={{ width: "30%", zIndex: 3 }}>
					<View style={{ width: "100%", marginBottom: 10, marginTop: 10, flexDirection: "row", justifyContent: "space-between" }}>
						<TouchableOpacity
							style={[{ height: 45, width: 45, justifyContent: "center", alignItems: "center", borderRadius: 27, backgroundColor: "gray", marginRight: 10 }]}
							onPress={() => navigation.navigate("StackSmartNew", { screen: "SmartHome", params: { course: id } })}
						>
							<Ionicons name="arrow-back" style={{ marginLeft: 5 }} size={30} color="white" />
						</TouchableOpacity>
						<View style={{ flex: 1, justifyContent: "flex-end", flexDirection: "row" }}>
							{sectionContents.length > 1 && currentContent > 0 && (
								<TouchableOpacity
									style={[{ height: 45, justifyContent: "center", paddingHorizontal: 20, borderRadius: 7, borderColor: customization.mainColor, borderWidth: 4, marginRight: 10 }]}
									onPress={previousContent}
								>
									<StyledText style={{ fontSize: 16 }}>Anterior</StyledText>
								</TouchableOpacity>
							)}
							{(canGoNext() || isActivityCompleted(currentContent)) && (
								<TouchableOpacity
									disabled={submitting}
									style={[{ height: 45, justifyContent: "center", paddingHorizontal: 20, borderRadius: 7, backgroundColor: customization.mainColor }]}
									onPress={nextContent}
								>
									<StyledText style={{ fontSize: 16, color: "white" }}>
										{submitting ? <ActivityIndicator color="white" /> : isActivityCompleted(currentContent) ? "Siguiente" : "Completar"}
									</StyledText>
								</TouchableOpacity>
							)}  
						</View>
					</View>
					<ScrollView showsVerticalScrollIndicator={false} contentContainerStyle={{ paddingBottom: 20, paddingHorizontal: 15 }}>
						{sectionContents.map((content, i) => {
							return (
								<Animatable.View animation="fadeInUp" duration={200} delay={i * 200} key={i}>
									<View style={{ opacity: canNavigateToContent(i) ? 1 : 0.5 }}>
										<TouchableOpacity
											style={[
												styles.shadow,
												{
													marginTop: 10,
													minHeight: 90,
													borderRadius: 7,
													borderRightColor: "orange",
													backgroundColor: "white",
													transform: [{ scale: currentContent === i ? 1.05 : 1 }],
													borderRightWidth: currentContent === i ? 5 : 0,
													width: "100%",
													flexDirection: "row",
													alignItems: "center",
												},
											]}
											disabled={!canNavigateToContent(i)}
											onPress={() => setCurrentContent(i)}
										>
											<View style={{ width: 90, height: "100%", borderRadius: 7 }}>
												{isActivityCompleted(i) && (
													<View style={{ zIndex: 20, position: "absolute", backgroundColor: "green", borderRadius: 7, opacity: 0.5, top: 0, bottom: 0, left: 0, right: 0 }} />
												)}
												{isActivityCompleted(i) && (
													<View style={{ zIndex: 21, position: "absolute", alignItems: "center", justifyContent: "center", top: 0, bottom: 0, left: 0, right: 0 }}>
														<Ionicons name="checkmark-circle-outline" color="white" size={45} />
													</View>
												)}
												<SecureImage
													style={{ width: 90, minHeight: 90, height: "100%", borderRadius: 7, resizeMode: "cover" }}
													uri={content.image ? content.image : "https://images.pexels.com/photos/4069292/pexels-photo-4069292.jpeg?auto=compress&cs=tinysrgb&dpr=2&h=750&w=1260"}
												/>
											</View>
											<View style={{ paddingVertical: 20, flex: 1, height: "100%", justifyContent: "center", paddingHorizontal: 15 }}>
												<Text style={{ fontFamily: "DemiBold", fontSize: 16 }}>{content?.title}</Text>
											</View>
										</TouchableOpacity>
									</View>
								</Animatable.View>
							);
						})}
					</ScrollView>

				</View>
			)}

			{sectionContents[currentContent] ? (
				<View style={{ height: "100%", width: sc.width < 1000 ? "100%" : "70%" }}>
					<View style={{ flex: 1, width: "100%" }}>
						{!isActivityCompleted(currentContent) && timeLeft !== null && timeLeft !== 0 && (
							<Animatable.View
								onLayout={() => !(sectionContents[currentContent]?.contentType === "EMBEDDED" || sectionContents[currentContent]?.contentType === "VIDEO" || sectionContents[currentContent]?.contentType === "AUDIO") && setTimer(timeLeft)}
								animation={timeLeft > 0 ? "fadeInUp" : "fadeOut"}
								style={{ flexDirection: "row", paddingVertical: 10, paddingHorizontal: 15, alignItems: "center", marginTop: 10, marginHorizontal: 10, borderRadius: 9, backgroundColor: "gainsboro" }}>
								<Ionicons style={{ marginRight: 10 }} name="ios-information-circle-outline" size={20} color="black" />
								<View style={{ paddingRight: 20 }}>
									{timeLeft === "WAIT" ? <Text style={{ fontFamily: "Regular", fontSize: 13 }}>El siguiente contenido estará disponible tras la corrección del tutor</Text> : <Text style={{ fontFamily: "Regular", fontSize: 13 }}>Siguiente contenido activo en: {timeLeft ? new Date(timeLeft * 1000).toISOString().substr(11, 8) : 0}</Text>}
								</View>
							</Animatable.View>
						)}
						<ScrollView contentContainerStyle={{ maxWidth: 900, paddingHorizontal: 10, width: "100%", alignSelf: "center", paddingBottom: 50 }} showsVerticalScrollIndicator={false}>
							<View style={[styles.shadow, { zIndex: 1, backgroundColor: "white", height: "100%", borderBottomLeftRadius: 15, borderBottomEndRadius: 15, flex: 1 }]}>
								<View style={{ padding: 15, height: "100%" }}>
									<View style={{ flex: 5 }}>

										{/* <ScormContainer scormCallback={(ev)=> console.log(ev)} loadCallback={() => null}/> */}

										{!(
											sectionContents[currentContent]?.contentType === "EMBEDDED" ||
											sectionContents[currentContent]?.contentType === "VIDEO" ||
											sectionContents[currentContent]?.contentType === "AUDIO" ||
											sectionContents[currentContent]?.contentType === "DOCUMENT"
										) && (
												<SecureImage
													key={sectionContents[currentContent]?.image}
													style={{ width: "100%", minHeight: 300, borderRadius: 7, resizeMode: "cover" }}
													uri={sectionContents[currentContent]?.image
														? sectionContents[currentContent]?.image
														: "https://images.pexels.com/photos/4069292/pexels-photo-4069292.jpeg?auto=compress&cs=tinysrgb&dpr=2&h=750&w=1260"}
												/>
											)}

										{(
											sectionContents[currentContent]?.contentType === "DOCUMENT" ||
											sectionContents[currentContent]?.contentType === "VIDEO" ||
											sectionContents[currentContent]?.contentType === "AUDIO" ||
											sectionContents[currentContent]?.contentType === "EMBEDDED" ||
											sectionContents[currentContent]?.contentType === "UPLOAD") && (
												<MediaViewerManager
													key={currentContent}
													ref={mediaHandlerRef}
													useNativeControls={true} 
													maxHeight={600}
													positionCallback={(ev) => {
														if (sectionContents[currentContent]?.mandatory && !currentAdvance?.completed) {
															const relative = duration * (100 - ev) / 100
															setProgress(ev ? ev : 0)
															Math.round(ev * 10) % 10 === 0 && Math.round(ev * 10) !== 0 && updateAdvance()
															setTimeLeft(relative)

														}
													}}
													playbackCallback={() => null}
													type={sectionContents[currentContent]?.contentType}
													url={sectionContents[currentContent]?.contentType === "UPLOAD" ? isActivityCompleted(currentContent) ? currentAdvance?.file : sectionContents[currentContent]?.media : sectionContents[currentContent]?.contentType === "EMBEDDED" ? sectionContents[currentContent]?.embedded : sectionContents[currentContent]?.media}
													cover={sectionContents[currentContent]?.image}
													videoProps={{ controlsList: ["nodownload", "noremoteplayback", "noprogress"] }}
													onLoadCallback={(ev) => {
														setDuration(ev)
														const d = advance?.find((el) => el.id === sectionContents[currentContent]?.id);
														if (sectionContents[currentContent]?.mandatory && !d?.completed) {
															if (d?.timeLeft) {
																mediaHandlerRef.current.seekTo(ev - d?.timeLeft, "seconds");
															} else {
																setTimeLeft(Math.round(ev))
															}
														}
														// sectionContents[currentContent]?.mandatory && mediaHandlerRef.current.seekTo(ev.getDuration() - timeLeft, "seconds");
													}}
													pauseCallback={() => setTimer(timeLeft, false)}
													endCallback={() => {
														if (sectionContents[currentContent]?.mandatory && !currentAdvance?.completed) {
															nextContent(true)
														}
														setTimeLeft(null)
														setProgress(100)
														// updateAdvance()
													}}
													documentCallback={setFileToUpload}
													mandatory={sectionContents[currentContent]?.mandatory && !isActivityCompleted(currentContent)}
													uploadType={sectionContents[currentContent]?.contentDetails}
													uploadData={sectionContents[currentContent]?.contentType ? "" : ""}
												/>
											)}

										{(sectionContents[currentContent]?.contentType === "VIDEO" ||
											sectionContents[currentContent]?.contentType === "EMBEDDED" ||
											sectionContents[currentContent]?.contentType === "AUDIO") && (
												<>
													<View style={{ flexDirection: "row", alignItems: "center", marginTop: 20 }}>
														<Text style={{ fontFamily: "DemiBold", fontSize: 14, color: "gray", marginRight: 10 }}>Progreso</Text>
														<View style={{ flex: 1 }}>
															<LinearBarIndicator backgroundColor="gainsboro" width={"100%"} rounded={8} height={6} gradient={["#B4EC51", "#429321"]} percentage={progress} />
														</View>
													</View>
												</>
											)}
										<Text style={{ fontFamily: "Bold", fontSize: 34, marginTop: 20, marginBottom: 10 }}>{sectionContents[currentContent]?.title}</Text>
										<HTML
											style={{ height: "auto", width: "90%" }}
											baseFontStyle={{ fontFamily: "Regular", margin: 0, fontSize: 16, color: "#303030" }}
											html={sectionContents[currentContent]?.content ? sectionContents[currentContent]?.content : "<p></p>"}
											onLinkPress={async (evt, href) =>
												Platform.OS === "web"
													? window.open(href.toLowerCase().includes("://") ? href : `https://${href}`, "_blank")
													: await WebBrowser.openBrowserAsync(href.toLowerCase().includes("://") ? href : `https://${href}`)
											}
										/>

										{(sectionContents[currentContent]?.contentType === "EXAM") && (
											<>
												<Text style={{ fontFamily: "DemiBold", fontSize: 18, color: "gray", marginBottom: 15 }}>Detalles del Examen</Text>
												<View style={{ flexDirection: "row", alignItems: "flex-end", justifyContent: "space-between" }}>

													{sectionContents[currentContent]?.showResults && (sectionContents[currentContent]?.percentageToPass || sectionContents[currentContent]?.maxTries) && (
														<View
															style={{
																alignSelf: "flex-start",
																paddingHorizontal: 20,
																borderRadius: 10,
																backgroundColor: "#f3f3f3",
																paddingVertical: 15
															}}
														>

															{sectionContents[currentContent]?.showResults && !isActivityCompleted(currentContent) && sectionContents[currentContent]?.maxTries && <Text style={{ marginBottom: 5, textAlign: "left", fontFamily: "DemiBold", fontSize: 18 }}>
																Intentos Restantes: {sectionContents[currentContent]?.maxTries - (currentAdvance?.tries?.length || 0)}
															</Text>}
															{sectionContents[currentContent]?.showResults && sectionContents[currentContent]?.percentageToPass && <Text style={{ textAlign: "left", fontFamily: "DemiBold", fontSize: 18 }}>
																Porcentaje Para Aprobar: {sectionContents[currentContent]?.percentageToPass}% 
															</Text>}
															{sectionContents[currentContent]?.showResults && currentAdvance?.tries?.length > 0 && <Text style={{ marginTop: 5, textAlign: "left", fontFamily: "DemiBold", fontSize: 18 }}>
																Último Resultado: {Math.round(currentAdvance?.tries[currentAdvance?.tries?.length - 1]?.percentage * 10) / 10}%
															</Text>}
														</View>
													)}

													{!isActivityCompleted(currentContent) && <Button onPress={() => navigation.navigate("Exam", { courseID: id, id: sectionContents[currentContent]?.ref?.id })} label={isActivityCompleted(currentContent) ? "Ver Resultados" : "Comenzar"} height={50} round={7} />}
												</View>
											</>
										)}

										{(sectionContents[currentContent]?.contentType === "WEBINAR" || sectionContents[currentContent]?.contentType === "EVENT") && (
											<>
												<View style={{ marginTop: 20, marginBottom: 20 }}>
													<Text style={{ fontFamily: "DemiBold", fontSize: 20 }}>Información de la sesión</Text>
												</View>

												<View style={{ marginLeft: 15 }}>
													<Text style={{ fontFamily: "DemiBold", fontSize: 18, color: "gray", marginBottom: 10 }}>Fecha y Enlace</Text>
													<View style={{ flexDirection: "row" }}>
														<View
															style={{
																alignItems: "center",
																alignSelf: "flex-start",
																height: 50,
																paddingHorizontal: 20,
																marginBottom: 15,
																borderRadius: 10,
																backgroundColor: "#f3f3f3",
																justifyContent: "center",
																flexDirection: "row",
															}}
														>
															<Ionicons name="calendar-outline" size={30} style={{ marginRight: 10 }} />
															{sectionContents[currentContent]?.contentDetails.noDate ? (
																<Text style={{ fontFamily: "DemiBold", fontSize: 18 }}>Pendiente de confirmar la fecha de la sesión</Text>
															) : (
																<Text
																	style={{ fontFamily: "DemiBold", fontSize: 18 }}
																	onPress={() =>
																		Platform.OS === "web" ? window.open(sectionContents[currentContent]?.link, "_blank") : Linking.openURL(sectionContents[currentContent]?.link)
																	}
																>
																	{moment(sectionContents[currentContent]?.contentDetails?.date).format("DD [de] MMMM [de] YYYY [a las] hh:mm")}
																</Text>
															)}
														</View>

														{sectionContents[currentContent]?.link && (
															<View
																style={{
																	marginLeft: 10,
																	alignItems: "center",
																	alignSelf: "flex-start",
																	height: 50,
																	paddingHorizontal: 20,
																	marginBottom: 15,
																	borderRadius: 10,
																	backgroundColor: "#f3f3f3",
																	justifyContent: "center",
																	flexDirection: "row",
																}}
															>
																<Ionicons name="link" size={30} style={{ marginRight: 10 }} />
																<Text
																	style={{ fontFamily: "DemiBold", fontSize: 18 }}
																	onPress={() =>
																		Platform.OS === "web" ? window.open(sectionContents[currentContent]?.link, "_blank") : Linking.openURL(sectionContents[currentContent]?.link)
																	}
																>
																	Ir a la Sala
																</Text>
															</View>
														)}
													</View>
													{moment().diff(sectionContents[currentContent], "days") > 0 && (
														<>
															<Text style={{ fontFamily: "DemiBold", fontSize: 18, color: "gray", marginBottom: 10 }}>{`Asistirás a este ${sectionContents[currentContent]?.contentType === "WEBINAR" ? "Webinar?" : "Evento?"
																}`}</Text>
															<View style={{ flexDirection: sc.width < 1000 ? "column" : "row", marginTop: 10 }}>
																<TouchableOpacity
																	style={{
																		marginTop: sc.width < 1000 ? 10 : 0,
																		height: 40,
																		width: 170,
																		marginRight: 10,
																		alignItems: "center",
																		borderRadius: 7,
																		backgroundColor: "#afd6e2",
																		justifyContent: "center",
																	}}
																>
																	<Text style={{ fontSize: 18, fontFamily: "DemiBold", color: "white" }}>No</Text>
																</TouchableOpacity>
																<TouchableOpacity
																	style={{
																		marginTop: sc.width < 1000 ? 10 : 0,
																		height: 40,
																		width: 170,
																		marginRight: 10,
																		alignItems: "center",
																		borderRadius: 7,
																		backgroundColor: "#450045",
																		justifyContent: "center",
																	}}
																>
																	<Text style={{ fontSize: 18, fontFamily: "DemiBold", color: "white" }}>Quizás</Text>
																</TouchableOpacity>
																<TouchableOpacity
																	style={{
																		marginTop: sc.width < 1000 ? 10 : 0,
																		height: 40,
																		width: 170,
																		alignItems: "center",
																		borderRadius: 7,
																		backgroundColor: "#1d527f",
																		justifyContent: "center",
																	}}
																>
																	<Text style={{ fontSize: 18, fontFamily: "DemiBold", color: "white" }}>Si</Text>
																</TouchableOpacity>
															</View>
														</>
													)}
												</View>
											</>
										)}

										{sectionContents[currentContent]?.contentType === "LINK" && (
											<>
												<Text style={{ fontFamily: "DemiBold", fontSize: 18, color: "gray", marginBottom: 10 }}>Enlace al contenido</Text>
												<View style={{ flexDirection: "row", width: "80%", alignItems: "flex-start", marginTop: 10 }}>
													<Button onPress={() => Platform.OS === "web" ? window.open(sectionContents[currentContent]?.link, "_blank") : Linking.openURL(sectionContents[currentContent]?.link)} label="Visitar Enlace" height={40} round={7} />
												</View>
											</>
										)}

										{sectionContents[currentContent]?.contentType === "FORUM" && (
											<View>
												<Text style={{ fontFamily: "DemiBold", fontSize: 20 }}>Detalles</Text>
												<View style={{ marginLeft: 10, marginTop: 10 }}>
													<Text style={{ fontFamily: "DemiBold", fontSize: 16, color: "gray", marginBottom: 10 }}>{sectionContents[currentContent]?.topic?.title}</Text>
													<TouchableOpacity
														onPress={() =>
															navigation.navigate("StackForum", {
																screen: "SingleTopic",
																params: { id: sectionContents[currentContent]?.topic.id, forumID: sectionContents[currentContent]?.topic?.forum?.id },
															})
														}
														style={{ height: 40, width: 170, marginRight: 10, alignItems: "center", borderRadius: 7, backgroundColor: "#afd6e2", justifyContent: "center" }}
													>
														<Text style={{ fontSize: 18, fontFamily: "DemiBold", color: "white" }}>Ir al Foro</Text>
													</TouchableOpacity>
												</View>
											</View>
										)}

										{sectionContents[currentContent]?.contentType === "TEST" && (
											<View style={{ backgroundColor: "white", minHeight: 300, borderBottomLeftRadius: 15, borderBottomEndRadius: 15, flex: 1 }}>
												<QuestionComponent question={sectionContents[currentContent]?.contentDetails?.question} answers={sectionContents[currentContent]?.contentDetails?.answers} />
												{/* <TestMission data={testContent} loader={loading} responseCallback={(response) => setUserResponse(response)} userResponse={userResponse} /> */}
											</View>
										)}


									</View>

									{/* {(sectionContents[currentContent]?.contentType === "EXAM" || sectionContents[currentContent]?.contentType === "TEST") && (
										<View>
											<Text style={{ fontFamily: "DemiBold", fontSize: 20, color: "gray", marginBottom: 20 }}>{activeActivity.contents[0]?.name}</Text>
											<View style={{ height: 520 }}>
												<Exam route={route} navigation={navigation} exID={sectionContents[currentContent]?.id} advanceData={sectionContents} />
											</View>
										</View>
									)} */}
								</View>
							</View>
						</ScrollView>
					</View>

				</View>
			) : (
				<View style={{ flexGrow: 1, alignItems: "center", justifyContent: "center" }}>
					<ActivityIndicator size={"large"} />
				</View>
			)
			}


		</View >
	);
};

export default ActivityGroup;

const styles = StyleSheet.create({
	shadow: {
		shadowColor: "#000",
		shadowOffset: {
			width: 0,
			height: 10,
		},
		shadowOpacity: 0.2,
		shadowRadius: 6.68,

		elevation: 11,
	},
});
