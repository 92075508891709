import React from "react";
import { StyleSheet, View, Image } from "react-native";

import Button from "../common/Button";
import SecureImage from "../common/SecureImage";
import StyledText from "../common/StyledText";

export default function SingleSlide({ title = "", subtitle = "", image = "", overlay = "rgba(0,0,0,.6)", buttonText = "Comenzar Hoy", buttonColor = "#006EB5", onPress = () => null }) {
	function stripHtml(html) {
		let tmp = document.createElement("DIV");
		tmp.innerHTML = html;
		return tmp.textContent || tmp.innerText || "";
	}
	return (
		<View style={styles.test}>
			<SecureImage style={{ position: "absolute", top: 0, left: 0, right: 0, bottom: 0, zIndex: 1 }} uri={image} />
			<View style={{ flex: 1, position: "absolute", top: 0, bottom: 0, left: 0, right: 0, zIndex: 2, backgroundColor: overlay }} />
			<View style={{ maxWidth: 1400, width: "100%", paddingHorizontal: 30, height: "100%", zIndex: 3, alignSelf: "center" }}>
				<View style={{ flex: 1, maxWidth: 600, justifyContent: "center", alignItems: "flex-start" }}>
					<StyledText style={{ fontSize: 44, color: "white", fontWeight: "bold", marginBottom: 15 }}>{title}</StyledText>
					{subtitle && <StyledText style={{ fontSize: 20, color: "white", marginBottom: 25 }}>{stripHtml(subtitle).slice(0, 200)}</StyledText>}
					<Button onPress={onPress} label={buttonText} color={buttonColor} round={7} />
				</View>
			</View>
		</View>
	);
};


const styles = StyleSheet.create({
	test: {
		flex: 1,
	},
});
