import React from 'react';
import { TouchableOpacity, View } from 'react-native';
import HTML from 'react-native-render-html';
import SecureImage from '../common/SecureImage';
import StyledText from '../common/StyledText';
import LinearBarIndicator from '../LinearBarIndicator';

const CourseHorizontalCard = ({ onPress, el, course, advance }) => {
    const total = course?.contentDetails?.reduce((acc, cur) => acc + cur.contents.length, 0) || 1
    const completed = advance?.advance?.filter(el => el.completed).length || 0
    return (
        <TouchableOpacity
            key={el.id}
            onPress={onPress}
            style={{
                width: "100%",
                marginBottom: 20,
                borderRadius: 10,
                backgroundColor: "#fff",
                shadowColor: "#000",
                shadowOffset: {
                    width: 0,
                    height: 2,
                },
                shadowOpacity: 0.25,
                shadowRadius: 3.84,
                height: 250
            }}
        >
            <View style={{ flexDirection: "row", alignItems: "center" }}>
                <View style={{ width: "30%", height: "100%", alignItems: "center" }}>
                    <SecureImage
                        style={{
                            width: "100%",
                            height: "100%",
                            borderRadius: 10,
                            height: 250
                        }}
                        uri={el.image}
                    />
                </View>
                <View style={{ width: "70%", padding: 20, height:"100%", flex:1, justifyContent:"space-between" }}>
                    <View>

                        <LinearBarIndicator backgroundColor="gainsboro" width={"100%"} rounded={8} height={15} gradient={["#B4EC51", "#429321"]} percentage={completed / total * 100} styles={{ marginBottom: 20 }} />
                        <StyledText style={{ fontSize: 24, marginBottom: 10 }}>{el.name}</StyledText>
                        <HTML
                            baseFontStyle={{ fontFamily: "Regular", fontSize: 16, color: "#303030", }}
                            html={el.description ? el.description.slice(0, 150) : "<p></p>"}
                            imagesMaxWidth={800}
                            staticContentMaxWidth={800}

                            onLinkPress={async (evt, href) => await WebBrowser.openBrowserAsync(href.toLowerCase().includes("://") ? href : `https://${href}`)}
                        />
                    </View>
                    <View>
                        <StyledText style={{ opacity: .5, textAlign: "right", fontSize: 16, color: "#8E8E8E" }}>{completed} de {total}</StyledText>
                    </View>
                </View>
            </View>
        </TouchableOpacity>
    );
}

export default CourseHorizontalCard;