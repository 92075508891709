import React, { useContext, useEffect, useState } from "react";
import { Platform } from "react-native";
import Header from "../components/AppHeader";
import HeaderWeb from "../components/HeaderWeb";

import Exam from "../screens/stackSmartNew/Exam";
import SmartHome from "../screens/stackSmartNew/SmartHome";

import { createStackNavigator } from "@react-navigation/stack";
import { collectionGroup, getDoc, getFirestore, onSnapshot, orderBy, query, where } from "firebase/firestore";
import AuthContext from "../context/AuthContext";
import ActivityGroup from "../screens/stackSmartNew/ActivityGroup";
import FeaturedContents from "../screens/stackSmartNew/FeaturedContents";
import Post from "../screens/stackSmartNew/Post";
import SingleForum from "../screens/stackSmartNew/SingleForum";
import SingleTopic from "../screens/stackSmartNew/SingleTopic";


const Stack = createStackNavigator();

const StackSmartNew = ({ index }) => {
	const { userData, tenant, dispatchUserData } = useContext(AuthContext)
	const [loading, setLoading] = useState(true)
	const db = getFirestore()


	useEffect(() => {
		let unsubscribe = null;
		if (userData?.id) {
			const q = query(collectionGroup(db, "courseavs"), where("tenant", "==", tenant), where("userRef", "==", userData.ref), orderBy("initDate", "asc"))
			unsubscribe = onSnapshot(q,
				async (querySnapshot) => {
					const courses = await Promise.all(
						querySnapshot.docs.map(async doc => {
							const course = await getDoc(doc.data().courseRef).then(res => ({ ...res.data(), id: res.id, ref: res.ref }))
							return course
						})
					)
					const advances = querySnapshot.docs.map(doc => ({ ...doc.data(), id: doc.id, ref: doc.ref }))
					// console.log("ACTUALIZANDO AVANCES")
					dispatchUserData({ type: "UPDATE_DATA", data: { courses, advances } });
					setLoading(false)
				},
				(err) => {
					console.log("ERROR", err)
					setLoading(false)
				}
			);
		}

		return () => unsubscribe && unsubscribe();
	}, [tenant]);

	return (
		<Stack.Navigator
			detachInactiveScreens={true}
			detachPreviousScreen={true}
			headerMode="screen"
			screenOptions={{
				
				unmountOnBlur: true,
				title: "Cursos",
				cardStyle: { flex: 1 },
				header: ({ scene, previous, navigation }) => {
					return Platform.OS === "web" ? <HeaderWeb index={index} /> : <Header logo={previous ? false : true} backCallback={() => navigation.goBack()} backButton={previous ? true : false} />;
				},
			}}>
			<Stack.Screen name="SmartHome" component={SmartHome} />
			<Stack.Screen name="SingleTopic" component={SingleTopic} />
			<Stack.Screen name="SingleForum" component={SingleForum} />
			<Stack.Screen name="ActivityGroup" component={ActivityGroup} />
			<Stack.Screen name="Post" component={Post} />
			<Stack.Screen name="FeaturedContents" component={FeaturedContents} />
			{/* <Stack.Screen name="CompleteCourse" component={CompleteCourse} options={{ header: () => null }} /> */}
			<Stack.Screen
				name="Exam"
				component={Exam}
				options={{
					headerTransparent: true,
					header: ({ scene, previous, navigation }) => <Header backButton={true} backCallback={() => navigation.goBack()} minimal />,
				}}
			/>
		</Stack.Navigator>
	);
};

export default StackSmartNew;
