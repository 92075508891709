import { useNavigation } from "@react-navigation/native";
import React, { useContext, useEffect, useState } from "react";
import { ActivityIndicator, Dimensions, ScrollView, StyleSheet, Text, View, useWindowDimensions } from "react-native";

import * as Animatable from "react-native-animatable";


import { getDoc, getFirestore } from "firebase/firestore";
import Button from "../../components/common/Button";
import SecureImage from "../../components/common/SecureImage";
import StyledText from "../../components/common/StyledText";
import AuthContext from "../../context/AuthContext";

const screenWidth = Dimensions.get("window").width;

const ProfileHome = ({ navigation }) => {
	const { userData, tenant, customization } = useContext(AuthContext);
	const sc = useWindowDimensions()
	const db = getFirestore()
	const [data, setData] = useState({});
	const [loading, setLoading] = useState(true);
	const [percentage, setPercentage] = useState(0)
	const { navigate } = useNavigation();

	useEffect(() => {
		getDoc(userData.ref)
			.then(async (doc) => {

				setData({ id: doc.id, ...doc.data(), ref: doc.ref });
				const userPoints = doc.data()?.points || 0
				const prevLevel = customization?.levels?.sort((a, b) => a.pointsToAchieve - b.pointsToAchieve).filter(el => el.pointsToAchieve <= userPoints).pop()?.pointsToAchieve || 0
				const nextLevel = customization?.levels?.sort((a, b) => a.pointsToAchieve - b.pointsToAchieve).find(el => el.pointsToAchieve > userPoints)?.pointsToAchieve || 0
				setPercentage((userPoints - prevLevel) / (nextLevel - prevLevel) * 100)
				setLoading(false);
			})
			.catch((err) => console.log(err));
	}, [tenant, userData]);

	if (loading)
		return (
			<View style={{ flex: 1, alignItems: "center", justifyContent: "center" }}>
				<ActivityIndicator size="large" />
			</View>
		);

	return (
		<ScrollView contentContainerStyle={{ paddingHorizontal: 10, width: "100%", maxWidth: 1200, alignSelf: "center" }} showsVerticalScrollIndicator={false}>
			<View style={{ flexDirection: sc.width < 1000 ? "column" : "row" }}>

				<View style={{ flex: sc.width < 1000 ? null : 1, height: "auto", paddingTop: 20 }}>
					<Animatable.View animation={"fadeInUp"} style={{ width: "100%" }}>
						<View style={[styles.shadow, { backgroundColor: "white", borderRadius: 20 }]}>
							<SecureImage
								key={data.image}
								style={{
									width: "100%",
									height: 300,
									resizeMode: "cover",
									borderRadius: 15,
									backgroundColor: customization.mainColor,
								}}
								uri={data.image}
							/>
							<View style={{ padding: 20 }}>
								<View style={{ marginTop: 0 }}>
									<StyledText style={{ fontSize: 32, fontWeight: "bold", color: "black" }}>
										{data.name} {data.surname}
									</StyledText>
									<StyledText style={{ fontSize: 22, marginBottom: 10, fontFamily: "DemiBold", color: "darkgray" }}>{data.email}</StyledText>

									{data.bio && <StyledText style={{ marginVertical: 10, fontSize: 16, fontFamily: "Regular", color: "black" }}>{data.bio === "" || data.bio === null ? "Sin Biografía" : data.bio}</StyledText>}
								</View>

								<View style={{ marginTop: 15 }}>
									<Button round={7} onPress={() => navigation.navigate("EditProfile")} style={{ borderRadius: 5 }} label="Editar Perfil" />
								</View>
							</View>
						</View>
					</Animatable.View>


					{/* <Animatable.View animation={"fadeInUp"} style={{ width: "100%", marginTop: 20 }}>
						<View style={[styles.shadow, { backgroundColor: "white", paddingHorizontal: 20, paddingVertical: 20, borderRadius: 20, }]}>
							<View style={{ marginBottom: 20 }}>
								<Text style={{ fontSize: 24, fontFamily: "DemiBold", color: "darkgray", borderRadius: 7 }}>Mis Estadísticas</Text>
							</View>
							<View style={{ flexDirection: "row", flexWrap: "wrap" }}>
								<View style={{ marginHorizontal: "1%", marginVertical: "1%", backgroundColor: "#F9F9F9", paddingVertical: 20, paddingHorizontal: 10, borderRadius: 15, width: "31%", alignItems: "center", justifyContent: "center" }}>
									<View style={{ position: "absolute", top: -15, left: -5, transform: [{ rotateZ: "0deg" }] }}>
										<Text style={{ fontSize: 36 }}>🔥</Text>
									</View>
									<StyledText style={{ color: customization.mainColor, fontSize: 50, fontWeight: "bold", color: "black" }}>
										0
									</StyledText>
									<StyledText style={{ textAlign: "center" }}>
										Racha de Días Activo
									</StyledText>
								</View>
								<View style={{ marginHorizontal: "1%", marginVertical: "1%", backgroundColor: "#F9F9F9", paddingVertical: 20, paddingHorizontal: 10, borderRadius: 15, width: "31%", alignItems: "center", justifyContent: "center" }}>
									<View style={{ position: "absolute", top: -15, left: -5, transform: [{ rotateZ: "0deg" }] }}>
										<Text style={{ fontSize: 40 }}>🎓</Text>
									</View>
									<StyledText style={{ color: customization.mainColor, fontSize: 50, fontWeight: "bold", color: "black" }}>
										0
									</StyledText>
									<StyledText style={{ textAlign: "center" }}>
										Contenidos Visualizados
									</StyledText>
								</View>
								<View style={{ marginHorizontal: "1%", marginVertical: "1%", backgroundColor: "#F9F9F9", paddingVertical: 20, paddingHorizontal: 10, borderRadius: 15, width: "31%", alignItems: "center", justifyContent: "center" }}>
									<View style={{ position: "absolute", top: -15, left: -5, transform: [{ rotateZ: "0deg" }] }}>
										<Text style={{ fontSize: 36 }}>❤️</Text>
									</View>
									<StyledText style={{ color: customization.mainColor, fontSize: 50, fontWeight: "bold", color: "black" }}>
										0
									</StyledText>
									<StyledText style={{ textAlign: "center" }}>
										Likes en Contenidos
									</StyledText>
								</View>
								<View style={{ marginHorizontal: "1%", marginVertical: "1%", backgroundColor: "#F9F9F9", paddingVertical: 20, paddingHorizontal: 10, borderRadius: 15, width: "31%", alignItems: "center", justifyContent: "center" }}>
									<View style={{ position: "absolute", top: -15, left: -5, transform: [{ rotateZ: "0deg" }] }}>
										<Text style={{ fontSize: 36 }}>💬</Text>
									</View>
									<StyledText style={{ color: customization.mainColor, fontSize: 50, fontWeight: "bold", color: "black" }}>
										0
									</StyledText>
									<StyledText style={{ textAlign: "center" }}>
										Comentarios en Contenidos
									</StyledText>
								</View>
							</View>
						</View>

					</Animatable.View> */}

				</View>
				{/* {sc.width > 1000 && <View style={{ width: 15 }} />}
				<View style={{ flex: sc.width < 1000 ? null : 1, height: "auto", paddingTop: 20 }}>

					<View style={[styles.shadow, { padding: 20, borderRadius: 25, width: "100%", backgroundColor: "white" }]}>
						{customization?.levels?.length > 0 &&
							<View style={{ paddingHorizontal: 10, paddingBottom: 15 }}>
								<LinearBarIndicator backgroundColor="gainsboro" rounded={6} height={12} gradient={["#B4EC51", "#429321"]} width={"95%"} percentage={percentage} />
							</View>
						}
						<View style={{ flexDirection: "row", }}>

							{customization?.levels?.length > 0 &&
								<SecureImage style={{ width: 80, height: 80, marginRight: 20 }} uri={customization?.levels?.filter(el => el.pointsToAchieve <= (data.points || 0)).sort((a, b) => a.pointsToAchieve - b.pointsToAchieve)[0]?.image} />
							}
							<View>
								<StyledText style={{ fontSize: 45, fontWeight: "bold" }}>{data.points ? data.points : 0} <StyledText style={{ fontSize: 20 }}>Puntos</StyledText></StyledText>
								{customization?.levels?.length > 0 &&
									<StyledText style={{ fontSize: 22, fontFamily: "DemiBold", color: customization.mainColor }}>
										Nivel {customization?.levels?.filter(el => el.pointsToAchieve <= (data.points || 0)).sort((b, a) => a.pointsToAchieve - b.pointsToAchieve)[0]?.name}
									</StyledText>
								}
							</View>

							<TouchableOpacity style={{ alignItems: "center" }}>
								<Text style={{ fontFamily: "DemiBold", fontSize: 45, color: customization.accentColor }}>1</Text>
								<Text style={{ fontFamily: "Regular", marginTop: -10, fontSize: 18 }}>Ranking</Text>
							</TouchableOpacity>
						</View>
					</View>

					<View style={{ flexDirection: "row", justifyContent: "space-around", marginTop: 10 }}>

						<Button onPress={() => navigation.navigate("MyReviews")} style={{ margin: 5 }} label="Valoraciones" />
					</View>

					{customization?.achievements?.length > 0 &&
						<Animatable.View animation={"fadeInUp"} style={{ width: "100%", marginTop: 20 }}>
							<View style={[styles.shadow, { backgroundColor: "white", paddingHorizontal: 20, paddingVertical: 20, borderRadius: 20, height: "auto" }]}>

								<View style={{ marginBottom: 20 }}>
									<Text style={{ fontSize: 24, fontFamily: "DemiBold", color: "darkgray", borderRadius: 7 }}>Logros</Text>
								</View>

								<FlatList
									data={customization?.achievements}
									renderItem={({ item, index }) => (
										<View
											style={{
												opacity: data.points ? (data.points < item.pointsToAchieve ? 0.4 : 1) : 0.4,
												flex: 1,
												margin: 10,
												backgroundColor: "#f2f2f2",
												paddingVertical: 15,
												paddingHorizontal: 15,
												borderRadius: 10,
												flexDirection: "row",
												alignItems: "center",
												justifyContent: "flex-start"
											}}>
											<SecureImage
												animation="bounceInLeft"
												duration={700}
												delay={100 * index}
												easing="ease-in"
												style={{ height: 80, width: 80 }}
												uri={item.image || "https://www.flaticon.com/svg/vstatic/svg/1170/1170611.svg?token=exp=1618222951~hmac=13b361bb681557e55c6ad7298d603ebf"}
											/>
											<View style={{ marginLeft: 15 }}>
												<Text numberOfLines={2} style={{ fontSize: 20, marginVertical: 10, fontFamily: "DemiBold", color: "darkgray" }}>{item.name}</Text>
												<Text style={{ fontFamily: "DemiBold", fontSize: 20, color: customization.mainColor }}>{item.pointsToAchieve}</Text>
											</View>
										</View>
									)}
									numColumns={1}
									keyExtractor={(item, index) => item.id}
								/>
							</View>
						</Animatable.View>
					}

				</View> */}
			</View>
			<View style={{ padding: 5, marginHorizontal: 10, marginTop: 30, alignItems: "center", justifyContent: "center", borderRadius: 10, backgroundColor: "white" }}>
				<Text style={{ fontFamily: "Regular", textAlign: "center", fontSize: 16, color: "gray", marginVertical: 10 }}>App Versión: 1.2.1</Text>
			</View>
		</ScrollView >
	);
};

ProfileHome.navigationOptions = {
	header: null,
};

const styles = StyleSheet.create({
	container: {
		flex: 1,
		backgroundColor: "#fff",
		marginBottom: 60,
	},
	shadow: {
		shadowColor: "#000",
		shadowOffset: {
			width: 0,
			height: 5,
		},
		shadowOpacity: 0.2,
		shadowRadius: 10.5,
		elevation: 10,
	},
	imageRedond: {
		height: 40,
		width: 40,
		borderRadius: 20,
		resizeMode: "cover",
		borderWidth: 1,
		borderColor: "gainsboro",
		marginRight: 10,
	},
	topicBox: {
		width: 300,
		padding: 15,
		marginBottom: 10,
		display: "flex",
		flexDirection: "row",
		alignItems: "center",
		borderWidth: 1,
		borderRadius: 4,
		borderColor: "gainsboro",
	},
	slideIn: {
		0: {
			opacity: 0.3,
			scale: 0.7,
		},
		0.5: {
			opacity: 0.7,
			scale: 0.8,
		},
		1: {
			opacity: 1,
			scale: 1,
		},
	},
});

export default ProfileHome;
