import React, { useContext, useEffect, useState } from "react";

import { Image, StyleSheet, TouchableOpacity, useWindowDimensions, View } from "react-native";
import Svg, { Path } from "react-native-svg";


import { Ionicons } from "@expo/vector-icons";
import { useNavigation } from "@react-navigation/native";
import LottieView from 'lottie-react-native';
import HTML from "react-native-render-html";
import AuthContext from "../../context/AuthContext";
import Modal from "../common/Modal";
import SecureImage from "../common/SecureImage";
import StyledText from "../common/StyledText";

const PathContainer = ({ course }) => {
	const { customization, userData, dimensions } = useContext(AuthContext);
	const screenWidth = useWindowDimensions().width;
	const screenHeight = useWindowDimensions().height;


	const [pathWidth, setPathWidth] = useState(0);
	const [visible, setVisible] = useState(false);
	const [advance, setAdvance] = useState(null)
	const [masterAdvance, setMasterAdvance] = useState(null)
	const [loading, setLoading] = useState(false);
	const [percentage, setPercentage] = useState(0);

	const navigation = useNavigation();

	const [selectedSection, setSelectedSection] = useState({
		name: "",
		description: "",
		content: [],
		sectionIndex: 0,
		advanceRef: course?.advanceID || "",
	});

	useEffect(() => {
		if (course) {
			const d = userData.advances.find(a => a.courseRef?.id === course.id);

			if (d) {
				setMasterAdvance(d)
				setAdvance(d.advance)
			} else {

			}
		}
	}, [course])

	const handleActiveModal = (selected, index) => {
		const s = {
			name: selected.name,
			description: selected.description,
			contents: selected.contents,
			image: selected.image,
			sectionIndex: index,
			advanceRef: course?.id,
		};
		setSelectedSection(s);
		// setVisible(true);
		navigation.navigate("ActivityGroup", { id: course?.id, index: s?.sectionIndex })
	};

	const checkIfCompleted = (section) => {

		if (section?.contents.length > 0) {
			const c = section?.contents
				?.map((content) => {
					const d = advance.find((el) => el.id === (content?.contentType === "EXAM" ? content?.ref?.id : content?.id));
					return d?.completed;
				})
				.filter((x) => x).length;
			return c === section.contents.length;
		} else {
			return false;
		}
	};

	const swapIcon = (iconName) => {
		switch (iconName) {
			case "TEXT":
				return { icon: "book-outline", name: "Lectura" };
			case "LINK":
				return { icon: "link-outline", name: "Enlace" };
			case "VIDEO":
				return { icon: "videocam-outline", name: "Video" };
			case "EMBEDDED":
				return { icon: "logo-youtube", name: "Youtube" };
			case "AUDIO":
				return { icon: "ear-outline", name: "Audio" };
			case "WEBINAR":
				return { icon: "desktop-sharp", name: "Webinar" };
			case "EVENT":
				return { icon: "calendar-sharp", name: "Evento" };
			case "UPLOAD":
				return { icon: "cloud-upload", name: "Subir" };
			case "TEST":
				return { icon: "document-text", name: "Encuesta" };
			case "EXAM":
				return { icon: "medal", name: "Cuestionario" };
			case "DOCUMENT":
				return { icon: "document", name: "Lectura" };
			case "FORUM":
				return { icon: "people", name: "Participar en el foro" };
			default:
				return { icon: "medal", name: "Cuestionario" };
		}
	};

	const polarToCartesian = (centerX, centerY, radius, angleInDegrees) => {
		var angleInRadians = (angleInDegrees - 90) * Math.PI / 180.0;
		return {
			x: centerX + (radius * Math.cos(angleInRadians)),
			y: centerY + (radius * Math.sin(angleInRadians))
		};
	}

	const describeArc = (x, y, radius, startAngle, endAngle) => {
		var start = polarToCartesian(x, y, radius, endAngle);
		var end = polarToCartesian(x, y, radius, startAngle);

		var largeArcFlag = endAngle - startAngle <= 180 ? "0" : "1";

		var d = [
			"M", start.x, start.y,
			"A", radius, radius, 0, largeArcFlag, 0, end.x, end.y
		].join(" ");

		return d;
	}






	if (loading || advance === null) {
		return null;
	}

	return (
		<>
			<View
				style={[
					styles.shadow,
					{
						width: "100%",
						backgroundColor: "white",
						borderRadius: 7,
						borderTopColor: customization.accentColor,
						borderTopWidth: 8,
						padding: 15,
						justifyContent: "space-between",
					},
				]}
			>
				<StyledText style={{ color: "black", fontSize: 20, fontFamily: "DemiBold" }}>{course?.name}</StyledText>
				<HTML baseFontStyle={{ fontFamily: "Regular", margin: 0, fontSize: 14 }} html={course?.description ? `${course?.description}` : "<p/>"} />
				{/* <View style={{ flexDirection: "row", alignItems: "center" }}>
					<StyledText style={{ fontFamily: "DemiBold", fontSize: 14, color: "gray", marginRight: 10 }}>Progreso</StyledText>
					<View style={{ flex: 1 }}>
						<LinearBarIndicator backgroundColor="gainsboro" width={"100%"} rounded={8} height={6} gradient={["#B4EC51", "#429321"]} percentage={totalCompleted()} />
					</View>
				</View> */}
			</View>


			<View
				style={{ marginTop: 80, marginHorizontal: 20, paddingBottom: 80 }}
				onLayout={({
					nativeEvent: {
						layout: { width },
					},
				}) => setPathWidth(width)}
			>
				{course?.contentDetails?.length &&
					course?.contentDetails?.map((section, i, arr) => {
						const { name } = section;
						const acumulatedDays = course?.contentDetails.slice(0, i + 1).reduce((acc, cur) => acc + (cur.startAfter || 0), 0)
						const initDate = masterAdvance.initDate ? masterAdvance.initDate?.toDate() : null;
						const dateSectionOpen = initDate ? new Date(initDate)?.setDate(new Date(initDate)?.getDate() + acumulatedDays)?.valueOf() : null;
						const previousSection = i > 0 ? arr[i - 1] : null;

						const previousSectionCompleted =
							checkIfCompleted(section) ||
							((i === 0 || checkIfCompleted(previousSection)) && ((initDate === null || section.startAfter === 0) || (dateSectionOpen < new Date().valueOf())) && userData.role === "User") ||
							((i === 0 || checkIfCompleted(previousSection)) && ((initDate === null || section.startAfter === 0) || (dateSectionOpen < new Date().valueOf())) && userData.role === "SuperAdmin") ||
							userData.role === "Admin" ||
							userData.role === "Teacher" ||
							userData.role === "Coordinator"
						// userData.role === "SuperAdmin" 

						const completed = section.contents.reduce((acc, cur) => (advance.find(el => el.id === cur?.id) ? 1 : 0) + acc, 0)
						const total = section.contents.length

						const longMultiplier = (i === arr.length - 1 ? 0.4 : 0.8)

						const firstPart = completed / (total * longMultiplier) * 100 > 100 ? 100 : completed / (total * longMultiplier) * 100
						const secondPart = (completed - (total * longMultiplier)) / (total * 0.2) * 100 < 0 ? 0 : (completed - (total * longMultiplier)) / (total * 0.2) * 100 > 100 ? 100 : (completed - (total * longMultiplier)) / (total * 0.2) * 100
						const lastPart = i === arr.length - 1 ? ((completed - (total * (longMultiplier + .2))) / (total * longMultiplier) * 100) < 0 ? 0 : ((completed - (total * (longMultiplier + .2))) / (total * longMultiplier) * 100) : 0

						const p = `M ${i % 2 ? 85 : pathWidth - 85} 5 h ${(i % 2 ? 1 : -1) * (pathWidth - 170)}  ${describeArc((i % 2 ? pathWidth - 85 : 85), 85, 80, i % 2 ? 0 : 180, i % 2 ? 180 : 0)} ${i === arr.length - 1 ? `M ${i % 2 ? pathWidth - 85 : 85} 165 h ${(i % 2 ? -1 : 1) * (pathWidth - 170)}` : ""} `

						const p2 = firstPart > 0 ? ` 
						M ${i % 2 ? 85 : pathWidth - 85} 5 
						h ${(i % 2 ? 1 : -1) * (pathWidth - 170) * firstPart / 100} 
						${secondPart > 0 && describeArc((i % 2 ? pathWidth - 85 : 85), 85, 80, i % 2 ? 0 : 180 * secondPart / 100, i % 2 ? 180 * secondPart / 100 : 0)} ${i === arr.length - 1 ? `M ${i % 2 ? pathWidth - 85 : 85} 165 h ${(i % 2 ? -1 : 1) * (pathWidth - 170) * lastPart / 100}` : ""}` : ""

						return (
							<TouchableOpacity
								accessibilityRole="button"
								disabled={!previousSectionCompleted}
								onPress={() => (previousSectionCompleted ? handleActiveModal(section, i) : null)}
								key={i}
								style={{ opacity: previousSectionCompleted ? 1 : 0.4, width: "100%", height: 170, marginTop: -10, position: "relative" }}
							>
								{i === 0 &&
									<LottieView
										autoPlay={true}
										loop={true}
										style={{ position: "absolute", zIndex: 10, right: 20, top: -60, width: 140, height: 140, transform: [{ rotate: "-130deg" }] }}
										source={require('../../assets/animations/rocket.json')}
									/>
								}
								<View style={{ width: "100%", height: 170, position: "relative" }}>
									<Svg width="100%" height="180px" style={{ position: "absolute" }}>
										<Path
											d={p}
											stroke="gainsboro"
											strokeLinecap="round"
											strokeWidth="10"
											fill="none"
										/>

										{firstPart > 0 && <Path
											d={p2}
											stroke={customization.mainColor}
											strokeLinecap="round"
											strokeWidth="10"
											fill="none"
										/>}
									</Svg>
									<View style={{ flexDirection: "row", alignItems: "center", flex: 1 }} >
										{checkIfCompleted(section) && i % 2 == 0 && (
											<View
												style={{
													position: "absolute",
													alignItems: "center",
													justifyContent: "center",
													zIndex: 10,
													left: 30,
													height: 120,
													width: 120,
													borderRadius: 60,
													backgroundColor: "rgba(25, 150, 25, 0.7)",
												}}
											>
												<Ionicons name="checkmark-circle-outline" color="white" size={50} />
											</View>
										)}
										{i % 2 == 0 ? (

											<SecureImage
												accessibilityRole="none"
												key={i}
												style={{ marginLeft: 30, height: 120, width: 120, borderRadius: 60 }}
												uri={section.image ? section.image : "https://images.pexels.com/photos/4069292/pexels-photo-4069292.jpeg?auto=compress&cs=tinysrgb&dpr=2&h=750&w=1260"}
											/>
										) : null}
										<View accessibilityRole="none" style={{ marginHorizontal: 10, flex: 1 }}>
											<StyledText style={i % 2 == 1 ? { marginRight: 10, fontFamily: "Bold", fontSize: 20, textAlign: "right" } : { marginRight: 10, fontFamily: "Bold", fontSize: 20 }}>
												{name}
											</StyledText>
											<StyledText
												style={
													i % 2 == 1
														? { marginRight: 15, fontFamily: "Bold", color: "grey", fontSize: 18, marginTop: 5, textAlign: "right" }
														: { marginRight: 15, fontFamily: "Bold", color: "grey", fontSize: 18, marginTop: 5 }
												}
											>
												{section.contents.length > 0 && `${completed} de ${total} Completado`}
											</StyledText>
										</View>
										{checkIfCompleted(section) && i % 2 == 1 && (
											<View
												accessibilityRole="none"
												style={{
													position: "absolute",
													alignItems: "center",
													justifyContent: "center",
													zIndex: 10,
													right: 40,
													height: 120,
													width: 120,
													borderRadius: 60,
													backgroundColor: "rgba(25, 150, 25, 0.7)",
												}}
											>
												<Ionicons name="checkmark-circle-outline" color="white" size={50} />
											</View>
										)}
										{i % 2 == 1 ? (
											<SecureImage
												key={i}
												style={{ marginRight: 40, height: 120, width: 120, borderRadius: 60 }}
												uri={section.image ? section.image : "https://images.pexels.com/photos/4069292/pexels-photo-4069292.jpeg?auto=compress&cs=tinysrgb&dpr=2&h=750&w=1260"}
											/>
										) : null}
									</View>
								</View>

								{i === arr.length - 1 && (
									<View style={[styles.shadow, { backgroundColor: "white", position: "absolute", zIndex: 10, right: 20, left: i % 2 ? 0 : null, top: 90, borderRadius: 70, width: 140, height: 140 }]}>
										<Image style={{ flex: 1, resizeMode: "contain" }} source={require("../../assets/images/success.png")} />
									</View>
								)}
							</TouchableOpacity>
						);
					})}
			</View>

			{visible && (
				<Modal
					onClickOutside={() => setVisible(false)}
					padding={0}
					modalContainerStyle={{
						borderRadius: 10,
						width: dimensions.width > 1000 ? 550 : "90%",
						flexDirection: "column",
					}}
				>
					<View style={{ width: '100%', height: 200 }}>
						<View style={{ position: "absolute", alignItems: "center", justifyContent: "space-between", flexDirection: "row", top: 10, left: 10, right: 10, zIndex: 10 }}>
							<TouchableOpacity
								onPress={() => setVisible(false)}
								style={{
									marginBottom: -25,
									width: 40,
									height: 40,
									alignItems: "center",
									justifyContent: "center",
								}}
							>
								<Ionicons name="close-circle" color="white" size={30} />
							</TouchableOpacity>


						</View>
						<SecureImage
							style={{ resizeMode: "cover", borderRadius: 7, width: "100%", height: "100%" }}
							uri={selectedSection.image
								? selectedSection.image
								: "https://images.pexels.com/photos/4069292/pexels-photo-4069292.jpeg?auto=compress&cs=tinysrgb&dpr=2&h=750&w=1260"}
						/>
					</View>
					<View style={{ backgroundColor: "white", borderRadius: 15 }}>
						<View style={{ padding: 15, paddingVertical: 20 }}>

							<StyledText style={{ fontFamily: "Bold", fontSize: 24 }}>{selectedSection?.name}</StyledText>

							{selectedSection.description && <HTML
								baseFontStyle={{ fontFamily: "Regular", fontSize: 16, color: "#303030" }}
								html={selectedSection.description ? selectedSection.description : "<p></p>"}
								imagesMaxWidth={800}
								staticContentMaxWidth={800}
								onLinkPress={async (evt, href) => await WebBrowser.openBrowserAsync(href.toLowerCase().includes("://") ? href : `https://${href}`)}
							/>}
						</View>

						<View style={{ padding: 20, backgroundColor: "#f2f2f2" }}>
							{selectedSection?.contents?.map((section, i) => {
								return (
									<View key={i} style={{ flexDirection: "row", marginVertical: 5, alignItems: "center" }}>
										<View
											style={{
												height: 35,
												flexDirection: "row",
												marginRight: 10,
												backgroundColor: "#F66653",
												alignItems: "center",
												justifyContent: "center",
												width: 150,
												borderRadius: 7,
											}}
										>
											<Ionicons name={swapIcon(section?.contentType).icon} color="white" size={24} style={{ marginRight: 5 }} />
											<StyledText style={{ fontFamily: "DemiBold", color: "white" }}>{swapIcon(section?.contentType).name}</StyledText>
										</View>
										<StyledText style={{ fontFamily: "DemiBold", fontSize: 16 }}>{section.name}</StyledText>
									</View>
								);
							})}
						</View>

						<TouchableOpacity
							onPress={() => {
								navigation.navigate("ActivityGroup", { id: course?.id, index: selectedSection?.sectionIndex })
								setVisible(false);
							}}
							style={{
								width: 200,
								height: 40,
								alignItems: "center",
								justifyContent: "center",
								borderRadius: 7,
								backgroundColor: "#1d527f",
							}}
						>
							<StyledText style={{ fontFamily: "DemiBold", color: "white", fontSize: 16 }}>Comenzar</StyledText>
						</TouchableOpacity>
					</View>
				</Modal>
			)}
		</>
	);
};

export default PathContainer;

const styles = StyleSheet.create({
	shadow: {
		shadowColor: "#000",
		shadowOffset: {
			width: 0,
			height: 5,
		},
		shadowOpacity: 0.1,
		shadowRadius: 6.68,

		elevation: 11,
	},
});
