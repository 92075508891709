import React from "react";

const UserDataReducer = (prevState, action) => {

	switch (action.type) {
		case "UPDATE_DATA":
			return {
				...prevState,
				...action.data,
			};
		case "CLEAN_DATA":
			return {
				...action.data,
			};
	}
};

export default UserDataReducer;
