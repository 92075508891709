import React, { useState, useContext, useRef, useEffect } from "react";
import { View, Image, Text, Linking, StyleSheet, Dimensions, TouchableOpacity } from "react-native";
import { Ionicons } from "@expo/vector-icons";
import AuthContext from "../context/AuthContext";
import { useNavigationState, useNavigation, Link as LinkNav, StackActions } from "@react-navigation/native";
import { useSafeArea } from "react-native-safe-area-context";
import * as Animated from "react-native-animatable";

import { useHover, useFocus, useActive } from "react-native-web-hooks";
import Portal from "@burstware/react-native-portal/build/Portal";
import { TouchableWithoutFeedback } from "react-native-gesture-handler";
import SecureImage from "./common/SecureImage";

const Link = ({ children, to = "/#", action, onPress, style = {} }) => {
	const { customization } = useContext(AuthContext);
	// Create a ref to bind the hooks to
	const ref = useRef(null);
	// Pass that ref to the hooks...
	const isHovered = useHover(ref);
	const isFocused = useFocus(ref);
	const isActive = useActive(ref);

	return (
		<View ref={ref}>
		<LinkNav
			accessibilityRole="link"
			draggable={false}
			to={to}
			tabIndex={0}
			action={action}
			onPress={onPress}
			style={[
				{
					color: customization.headerElementsColor,
					fontSize: 14,
				},
				style,
				isHovered && { color: customization.accentColor },
				isFocused && { color: customization.accentColor },
				isActive && { color: customization.accentColor },
			]}>
			{children}
		</LinkNav>
		</View>
	);
};





const HeaderWeb = ({ navigation, toogleSmartCallback, smartContents, onlyLogout = false }) => {
	const { authFunctions, userData, customization, setTenantSelector, dimensions } = useContext(AuthContext);

	const insets = useSafeArea();

	const [zonas, setZonas] = useState(userData.zonas);
	const [menu, showMenu] = useState(false);
	const [toogled, setToogled] = useState(false);

	return (
		<View style={[styles.shadow, { width: "100%", height: dimensions.width > 1000 ? 70 : 50, alignItems: "center", backgroundColor: customization.mainColor }]}>
			<View style={{ flex: 1, paddingLeft: 15, width: "100%", flexDirection: "row", alignItems: "center", justifyContent: "space-between" }}>
				<View style={{ height: dimensions.width > 1000 ? 70 : 50, width: (dimensions.width > 1000 ? 55 : 45) * customization.logoRatio, alignItems: "center", justifyContent: "center" }}>
					<SecureImage style={{ height: dimensions.width > 1000 ? 50 : 40, width: (dimensions.width > 1000 ? 150 : 140), resizeMode: "contain" }} uri={customization.mainLogo} />
				</View>

				{/* {screenWidth <= 800 &&
                    <TouchableOpacity style={{ height: 50, width: 50, alignItems: "center", justifyContent: "center" }} onPress={() => showMenu(!menu)}>
                        <Ionicons color="white" size={35} name={menu ? "md-close" : "md-menu"} />
                    </TouchableOpacity>
                } */}
				{dimensions.width >= 1000 && <MenuLinks onlyLogout={onlyLogout} direction="row" zonas={zonas} userData={userData} authFunctions={authFunctions} />}

				{userData.tenants?.length > 1 && <TouchableOpacity
					onPress={() => {
						setTenantSelector(true)
					}}
					style={{ borderRadius: 25, height: 45, flexDirection: "row", width: 50, alignItems: "center", justifyContent: "center" }}>
					{/* <Text style={{ fontFamily: 'DemiBold', marginRight:10 }}>{!toogled ? "Ver Contenidos" : "Ocultar Contenidos"}</Text> */}
					<Ionicons color={customization.headerElementsColor} name="apps" size={32} />
				</TouchableOpacity>}

				{/* {dimensions.width <= 1000 && smartContents && (
					<TouchableOpacity
						onPress={() => {
							toogleSmartCallback();
							setToogled(!toogled);
						}}
						style={{ borderRadius: 25, height: 45, flexDirection: "row", width: 50, alignItems: "center", justifyContent: "center" }}>
						<Text style={{ fontFamily: 'DemiBold', marginRight:10 }}>{!toogled ? "Ver Contenidos" : "Ocultar Contenidos"}</Text>
						<Ionicons color={customization.headerElementsColor} name={toogled ? "md-close" : "md-more"} size={32} />
					</TouchableOpacity>
				)} */}
				{/* {menu &&
                    <Portal>
                        <Animated.View style={{ position: "absolute", top: 70, right: 0, backgroundColor: "black", alignItems: "flex-end", justifyContent: "flex-start" }}>
                            <MenuLinks direction="column" zonas={zonas} clickCallback={() => showMenu(!menu)} />
                        </Animated.View>
                    </Portal>
                } */}
			</View>
		</View>
	);
};

const MenuLinks = ({ authFunctions, direction, clickCallback, onlyLogout }) => {
	// console.log("MENU", userData.course.forumRef.id)
	const { userData } = useContext(AuthContext);
	const navigation = useNavigation()
	const [zonas, setZonas] = useState({})
	useEffect(() => {
		setZonas(userData.zonas || [])
	}, [userData])
	return (
		<View style={{ flex: 1, alignItems: direction === "row" ? "center" : "flex-end", justifyContent: "flex-end", flexDirection: direction }}>
			{zonas?.content && (
				<Link to="/StackPublic/HomeScreen" onPress={clickCallback} style={{ flexDirection: "row", display: "flex", height: 70, alignItems: "center", justifyContent: "center", marginHorizontal: 10 }}>
					<Text style={{ fontFamily: "DemiBold" }}>Inicio</Text>
					<Ionicons style={{ marginLeft: 15 }} name="md-home" size={30} />
				</Link>
			)}
			{zonas?.content && (
				<Link to="/StackContent/ContentHome" onPress={clickCallback} style={{ flexDirection: "row", display: "flex", height: 70, alignItems: "center", justifyContent: "center", marginHorizontal: 10 }}>
					<Text style={{ fontFamily: "DemiBold" }}>Contenidos</Text>
					<Ionicons style={{ marginLeft: 15 }} name="md-newspaper" size={30} />
				</Link>
			)}

			{zonas?.smartlearning &&
				<Link to="/StackSmartNew/SmartHome" onPress={clickCallback} style={{ flexDirection: "row", display: "flex", height: 70, alignItems: "center", justifyContent: "center", marginHorizontal: 20 }}>
					<Text style={{ fontFamily: "DemiBold" }}>Mis Cursos</Text>
					<Ionicons style={{ marginLeft: 15 }} name="md-book" size={30} />
				</Link>
			}

			{zonas?.forum && (
				<Link to="/StackForum/ForumHome" onPress={clickCallback} style={{ flexDirection: "row", display: "flex", height: 70, alignItems: "center", justifyContent: "center", marginHorizontal: 10 }}>
					<Text style={{ fontFamily: "DemiBold" }}>Foros</Text>
					<Ionicons style={{ marginLeft: 15 }} name="md-chatbubbles" size={30} />

				</Link>
			)}


			{zonas?.profile &&
				<Link to="/StackProfile/ProfileHome" onPress={clickCallback} style={{ flexDirection: "row", display: "flex", height: 70, alignItems: "center", justifyContent: "center", marginHorizontal: 10 }}>
					<Text style={{ fontFamily: "DemiBold" }}>Mi Perfil</Text>
					<Ionicons style={{ marginLeft: 15 }} name="person-circle" size={30} />
				</Link>
			}

			<Link to="/login" onPress={authFunctions.signOut} style={{ flexDirection: "row", display: "flex", height: 70, alignItems: "center", justifyContent: "center", marginHorizontal: 10 }}>
				<Text style={{ fontFamily: "DemiBold" }}>Salir</Text>
				<Ionicons style={{ marginLeft: 15 }} name="exit" size={30} />
			</Link>
		</View>
	);
};

const styles = StyleSheet.create({
	shadow: {
		shadowColor: "rgba(0, 0, 0, 0.3)",
		shadowOffset: { width: 0, height: 2 },
		shadowOpacity: 0.4,
		shadowRadius: 3,
		elevation: 10,
	},
});

export default HeaderWeb;
