import React from "react";
import {
  View,
  Text,
  TouchableHighlight,
  Dimensions,
  TouchableOpacity,
  Platform,
  Image,
  StyleSheet,
  ImageBackground
} from "react-native";

import Element from "../../constants/Elements";
import * as Animatable from "react-native-animatable";
import { useNavigation } from '@react-navigation/native';
import moment from "moment";
import ImageRel from "../common/ImageRel";
import { Ionicons } from "@expo/vector-icons";
import SecureImage from "../common/SecureImage";



const postdestacado = ({
  position,
  categories = [],
  id = "",
  title = "",
  image = null,
  publishDate = "",
  last,
  onlyCard,
  pressCallback,
  comments = [],
  likes = [],
  maxWidth = 900

}) => {
  const date = moment(publishDate).format("DD MMMM");

  const { navigate } = useNavigation();

  return (

    <TouchableOpacity
      onPress={pressCallback}
      key={position}
      style={[styles.shadow, {
        width: onlyCard ? Dimensions.get("window").width - 20 : 250,
        height: 300,
        maxWidth: maxWidth,
        marginRight: last ? 0 : 10,
        backgroundColor: "#fff",
        justifyContent: "space-between",
        borderRadius: 15,
      }]}
    >

      <View style={{ flex: 1, justifyContent: "flex-end", padding: 7 }} imageStyle={{ borderRadius: 15 }} source={{ uri: image }}>
        {/* <ImageRel style={styles.featuredImage} resizeMode="cover" source={image} /> */}
        <SecureImage uri={image} style={styles.featuredImage} resizeMode="cover" key={image} />
        <View style={{ backgroundColor: "rgba(0,0,0,.0)", borderRadius: 15, position: "absolute", top: 0, bottom: 0, left: 0, right: 0, zIndex: 1, elevation: 1 }} />

        <View style={styles.contentInfoContainer}>

          {/* {categories.map((category, i) => (
            <TouchableHighlight key={i} style={styles.categoryContainer} onPress={(e) => {
              e && navigate("Category", { id: category.id })
            }}>
              <Text style={styles.categoryText}>{category.name}</Text>
            </TouchableHighlight>
          ))} */}

          <Text truncate="true" numberOfLines={2} style={{ fontSize: 16, marginTop: 10, fontFamily: "DemiBold" }}>{title}</Text>


          {/* Block Likes and Comments and Date */}
          <View style={{ flexDirection: "row", marginTop: 15, justifyContent: "space-between", alignItems: "center" }}>
            <View style={{ flexDirection: "row" }}>
              <View style={{ flexDirection: "row", alignItems: "center", marginRight: 15 }}>
                <Ionicons name="heart" size={20} style={{ marginRight: 10, color: "#ef0000" }} />
                <Text style={{ fontSize: 12, fontFamily: "Regular" }}>{likes ? likes.length : 0}</Text>
              </View>
              <View style={{ flexDirection: "row", alignItems: "center" }}>
                <Ionicons name="md-chatbubbles" size={20} style={{ marginRight: 10, color: "gray" }} />
                <Text style={{ fontSize: 12, fontFamily: "Regular" }}>{comments ? comments.length : 0}</Text>
              </View>
            </View>

            <Text style={styles.date}>{date}</Text>
          </View>

        </View>
      </View>

    </TouchableOpacity>
  );
};

export default postdestacado;


const styles = StyleSheet.create({
  featuredImage: {
    flex:1,
    borderRadius: 10
  },
  contentInfoContainer: {
    paddingVertical: 10,
    maxWidth: 430,
    zIndex: 2,
    borderRadius: 10,
    paddingHorizontal: 15,
    justifyContent: "center",
    backgroundColor: "white",
    width: "100%"
  },
  categoryContainer: {
    marginBottom: 5,
    marginRight: 5,
    backgroundColor: "#D82E2C",
    alignSelf: "flex-start",
    borderRadius: 15,
    paddingVertical: 5,
    paddingHorizontal: 10
  },
  categoryText: {
    color: "white",
    fontSize: 10,
    fontFamily: "DemiBold"
  },
  date: {
    fontSize: 12,
    color: "gray",
    fontFamily: "Regular"
  },
  likeContainer: {
    position: "absolute",
    right: 0,
    width: 45,
    alignItems: "center",
    flexDirection: "row",
    justifyContent: "center"
  },
  shadow: {
    shadowColor: "#000000",
    shadowOffset: {
      width: 0,
      height: 10,
    },
    shadowOpacity: 0.1,
    shadowRadius: 10.00,
    backgroundColor: "white",

    elevation: 24,
  }
})