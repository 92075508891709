import React from "react";
import { View, TouchableOpacity, Platform } from "react-native";

const GrowingElement = ({ onPress, zoom = true, opacity = true, style = {}, children, ...rest }) => {
	const [isHovered, setIsHovered] = React.useState(false);

	if (Platform.OS === "web") {
		return (
			<View
				accessible
				dataFocusable={true}
				onClick={onPress}
				onMouseEnter={() => setIsHovered(true)}
				onMouseLeave={() => setIsHovered(false)}
				style={{ ...style, cursor: "pointer", transitionDuration: "150ms", transform: [{ scale: isHovered && zoom ? 1.05 : 1 }], zIndex: isHovered ? 10 : 1, opacity: isHovered && opacity ? 0.8 : 1 }}
				{...rest}
			>
				{children}
			</View>
		);
	}

	return (
		<TouchableOpacity onPress={onPress} {...rest}>
			{children}
		</TouchableOpacity>
	);
};

export default GrowingElement;
