import React from 'react';
import { TouchableOpacity, View } from 'react-native';
import StyledText from '../common/StyledText';


const QuestionComponent = ({ question = "", answers = [], answerCallback, feedback }) => {
    return (
        <View>
            <StyledText>{question}</StyledText>
            {answers.map((answer, i) => {
                return (
                    <TouchableOpacity key={i} onPress={() => answerCallback(answer)}>
                        <StyledText>{answer.text}</StyledText>
                    </TouchableOpacity>
                )
            })}
        </View>
    );
}

export default QuestionComponent;