import { getDownloadURL, getStorage, ref } from 'firebase/storage';
import React, { useEffect, useState } from 'react';
import { Image } from "react-native"

const SecureImage = ({ uri, style, ...props }) => {
    const [signed, setSigned] = useState("")
    const storage = getStorage()

    useEffect(() => {
        return () => setSigned("")
    }, [])
    useEffect(() => {

        if (uri) {
            if (uri.includes("http") || uri.includes("data:image")) {
                setSigned(uri)
            } else {
                const imageRef = ref(storage, uri)
                getDownloadURL(imageRef).then(url => {
                    setSigned(url)
                }).catch(err => {
                    setSigned("")
                })
            }
        }
    }, [uri])
    return (
        <Image source={{ uri: signed }} style={style} {...props} />
    );
}

export default SecureImage;