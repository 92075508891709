import React, { useState, useEffect, useContext, useRef } from "react";
import { View, Text, TouchableOpacity, ScrollView, StyleSheet, Platform, FlatList, Image, Dimensions } from "react-native";
import PostDestacado from "./CardFeaturedPost";
import CardPost from "./CardPost";
import { useNavigation } from "@react-navigation/native";
import LottieView from "lottie-react-native";
import * as Animatable from "react-native-animatable";
import { Line, Element, Block, Paragraph, MagicBox, LoadAnimation } from "../../components/placeholders/MagicBox";


import AuthContext from "../../context/AuthContext";



import * as Localization from "expo-localization";
import i18n from "i18n-js";
import translations from "../../locale/translations";
import { useSafeAreaInsets } from "react-native-safe-area-context";

import Button from "../common/Button";
import { Ionicons } from "@expo/vector-icons";
import { collection, getFirestore, onSnapshot, orderBy, query, where, limit } from "firebase/firestore";
import SecureImage from "../common/SecureImage";
import Swiper from "react-native-web-swiper";
import SingleSlide from "./SingleSlide"
import StyledText from "../common/StyledText";
import GrowingElement from "../common/GrowingElement";
import CourseCardVertical from "./CourseCardVertical";

i18n.translations = translations;
// Set the locale once at the beginning of your app.
i18n.locale = Localization.locale;
i18n.fallbacks = true;

let unsubscribeContents = () => null
let unsubscribeFeaturedContents = () => null
let unsubscribeCategories = () => null

const NewsFeed = ({ id, today, route, navigation }) => {

	const { navigate } = useNavigation();
	const db = getFirestore()
	const { userData, tenant, customization, dimensions } = useContext(AuthContext);

	const insets = useSafeAreaInsets();
	const catRef = useRef();
	// const [limit, setLimit] = useState(6);
	const [categories, setCategories] = useState([]);
	const [loading, setLoading] = useState(false);
	const [data, setData] = useState([]);
	const [dataFeatured, setDataFeatured] = useState([]);
	const error = false;

	useEffect(() => {
		setLoading(true);
		getCategories()
		return () => {
			unsubscribeCategories()
			unsubscribeContents();
			unsubscribeFeaturedContents();
		};
	}, [tenant]);

	const getCategories = () => {
		try {
			const q = query(collection(db, `${tenant.path}/categories`), where("usergroups", "array-contains-any", userData.usergroups?.length === 0 ? ["public"] : userData.usergroups))
			unsubscribeCategories = onSnapshot(q, (querySnapshot) => {
				const cat = querySnapshot.docs.map((doc) => ({ id: doc.id, ...doc.data(), ref: doc.ref }));
				setCategories(cat);
				getFeaturedContents();
				getContents();

			});
		} catch (err) {
			console.log(err)
			setLoading(false)
		}
	}

	const getContents = () => {
		try {
			// var d = new Date();
			// d.setDate(d.getDate() - 7);
			const q = query(
				collection(db, `${tenant.path}/contents`),
				where("usergroups", "array-contains-any", userData.usergroups?.length === 0 ? ["public"] : userData.usergroups),
				where("featured", "==", false),
				where("published", "==", true),
				// where("publishDate", ">", d),
				orderBy("publishDate", "desc"),
				limit(9)
			);
			unsubscribeContents = onSnapshot(q, (querySnapshot) => {
				const res = querySnapshot.docs.map((doc) => ({ ...doc.data(), id: doc.id, ref: doc.ref }));
				setData(res);
				setLoading(false);
				// limit > res.length && setIsMoreData(false);
			})
		} catch (err) {
			setLoading(false)
		}
	};

	const getFeaturedContents = () => {
		try {
			// var d = new Date();
			// d.setDate(d.getDate() - 7);
			const q = query(
				collection(db, `${tenant.path}/contents`),
				where("usergroups", "array-contains-any", userData.usergroups?.length === 0 ? ["public"] : userData.usergroups),
				where("featured", "==", true),
				orderBy("publishDate", "desc"),
				limit(5),
			);
			unsubscribeFeaturedContents = onSnapshot(q, (querySnapshot) => {
				const res = querySnapshot.docs.map((doc) => ({ ...doc.data(), id: doc.id, ref: doc.ref }));
				setDataFeatured(res);
				setLoading(false);
				// limit > res.length && setIsMoreData(false);
			})
		} catch (err) {
			console.log(err)
			setLoading(false)
		}

	};

	if (error) {
		console.log(error);
		return (
			<View style={{ flex: 1, alignItems: "center", justifyContent: "center", padding: 10 }}>
				{Platform.OS !== "web" && (
					<LottieView
						autoPlay
						style={{
							width: 200,
							height: 200,
							marginBottom: 30,
						}}
						source={require("../../assets/animations/error.json")}
					/>
				)}
				<Text style={{ fontFamily: "DemiBold", fontSize: 16 }}>Ops Ha ocurrido un Error, intentelo más tarde</Text>
			</View>
		);
	}

	return (
		<ScrollView>
			{dataFeatured.length > 0 &&
				<View style={{ height: dimensions.height / 2, backgroundColor: "#1e4876" }}>
					<Swiper
						autoplay
						style={styles.wrapper}
						showsButtons={true}
						springConfig={{ speed: 10, bounciness: 0 }}
						loop
						Controls={(props) => {
							return (
								<View
									style={{
										height: 50,
										width: "100%",
										alignItems: "center",
										justifyContent: "center",
										flexDirection: "row",
										position: "absolute",
										bottom: 10,
									}}
								>
									<TouchableOpacity
										onPress={() => props.goToPrev()}
										style={{ marginRight: 10, height: 40, width: 40, borderRadius: 20, alignItems: "center", justifyContent: "center", backgroundColor: "white" }}
									>
										<Ionicons name="chevron-back" size={35} color="#0682C5" />
									</TouchableOpacity>
									{[...Array(props.count).keys()].map((i) => (
										<TouchableOpacity
											key={i}
											onPress={() => props.goTo(i)}
											style={{
												marginHorizontal: 5,
												width: props.activeIndex === i ? 20 : 7,
												height: 7,
												backgroundColor: props.activeIndex === i ? "#0682C5" : "white",
												borderRadius: 4,
											}}
										/>
									))}
									<TouchableOpacity
										onPress={() => props.goToNext()}
										style={{ marginLeft: 10, height: 40, width: 40, borderRadius: 20, alignItems: "center", justifyContent: "center", backgroundColor: "white" }}
									>
										<Ionicons name="chevron-forward" size={35} color="#0682C5" />
									</TouchableOpacity>
								</View>
							);
						}}
					>
						{dataFeatured.map((el, i) =>
							<SingleSlide
								title={el.title}
								subtitle={el.content}
								image={el.image}
								key={i}
								buttonText={"Leer Más"}
								onPress={() => navigate("Post", { id: el.id })}
							/>
						)}

					</Swiper>
				</View>}

			<View style={{ flexDirection: dimensions.width > 1000 ? "row" : "column", width: "100%", padding: 20, maxWidth: 1400, alignSelf: "center" }}>
				<View style={{ width: dimensions.width > 1000 ? "25%" : "100%", borderRightWidth: 2, borderRightColor: "gainsboro", padding: 15 }}>
					<StyledText style={{ fontSize: 34, marginBottom: 15 }}>Categorías</StyledText>

					{categories.map((el, i) => (
						<GrowingElement
							onPress={() => navigate("Category", { id: el.id })}
							key={i}
							style={{
								marginBottom: 5,
								backgroundColor: "black",
								overflow: "hidden",
								paddingHorizontal: 10,
								borderRadius: 7,
								height: 50,
								width: "100%",
								justifyContent: "center",
							}}
							opacity={false}
						>
							<SecureImage
								uri={el.image}
								style={{ position: "absolute", borderRadius: 7, flex: 1, position: "absolute", top: 0, bottom: 0, left: 0, right: 0, zIndex: 0 }}
							/>
							<View style={{ flex: 1, position: "absolute", top: 0, bottom: 0, left: 0, right: 0, zIndex: 2, backgroundColor: "rgba(0,0,0,.6)" }} />
							<StyledText style={{ color: "white", fontSize: 20, zIndex: 3 }}>{el.name}</StyledText>
						</GrowingElement>
					))}
				</View>
				<View style={{ padding: 15, width: dimensions.width > 1000 ? "75%" : "100%" }}>
					<View style={{ flexDirection: "row" }}>
						<View style={{ flex: 1 }}>
							<StyledText style={{ fontSize: 34, marginBottom: 15 }}>Últimos Contenidos</StyledText>
						</View>
						{/* <Button label="Ver todos" color="#006EB5" round={7} /> */}
					</View>

					<View style={{ flexDirection: "row", marginTop: 10, flexWrap: "wrap" }}>
						{data.map((el, i) => (
							<CourseCardVertical
								key={i}
								onPress={() => navigate("Post", { id: el.id })}
								image={el.image}
								title={el.title}
								summary={el.content}
								// teacher="Profesor Maestro"
								isNew={el.publishDate.toDate() > new Date(new Date().setDate(new Date().getDate() - 7))}
							/>
						))}
					</View>
				</View>
			</View>
		</ScrollView>
	);
};

export default NewsFeed;

const styles = StyleSheet.create({
	wrapper: {},
	container: {
		flex: 1,
		alignItems: "center",
		justifyContent: "center",
	},

	buttonTop: {
		backgroundColor: "white",
		borderColor: "#679BA2",
		width: "50%",
		height: 55,
		alignItems: "center",
		justifyContent: "center",
	},
	buttonTopText: {
		fontFamily: "DemiBold",
		fontSize: 18,
	},

	shadow: {
		shadowColor: "#000000",
		shadowOffset: {
			width: 0,
			height: 10,
		},
		shadowOpacity: 0.1,
		shadowRadius: 10.0,
		backgroundColor: "white",

		elevation: 24,
	},
	drawer: {
		shadowColor: "#000000",
		shadowOpacity: 0.8,
		shadowRadius: 3,
		elevation: 2,
	},
	main: { paddingLeft: 3 },
});
