// ECMA Script Polyfills:
import "core-js/stable";
import "regenerator-runtime/runtime";
// Needed for the generator functions which are transpiled from your async await keywords
import AppLoading from 'expo-app-loading';
import * as Font from 'expo-font';
import React, { useState } from 'react';


import moment from "moment";
import "moment/locale/es";

import AppNavigator from './navigation/AppNavigator';


import { initializeApp } from "firebase/app";

const firebaseConfig = {
  apiKey: "AIzaSyAGzgWg2SZ0lTY0NL_RHKMq6UEsNOsljSs",
  authDomain: "unoworknext.firebaseapp.com",
  projectId: "unoworknext",
  storageBucket: "unoworknext.appspot.com",
  messagingSenderId: "368461868490",
  appId: "1:368461868490:web:a8759340d2e0e69c663333",
  measurementId: "G-YS5DSRZ2P0",
  experimentalForceLongPolling: true 
};

const app = initializeApp(firebaseConfig);
const db = initializeFirestore(app, { experimentalForceLongPolling: true })

// Before rendering any navigation stack
import { initializeFirestore } from 'firebase/firestore';
import { enableScreens } from 'react-native-screens';
enableScreens();

moment().locale("es");

moment.updateLocale('es', {
  relativeTime: {
    future: 'en %s',
    past: '%s',
    s: 'ahora',
    ss: '%ss',
    m: 'un minuto',
    mm: '%dm',
    h: 'una hora',
    hh: '%dh',
    d: 'un día',
    dd: '%dd',
    M: 'un mes',
    MM: '%dM',
    y: 'un año',
    yy: '%dY'
  }
});



export default function App(props) {

  const [isLoadingComplete, setLoadingComplete] = useState(false);

  if (!isLoadingComplete && !props.skipLoadingScreen) {

    return (
      <AppLoading
        startAsync={loadResourcesAsync}
        onError={handleLoadingError}
        onFinish={() => handleFinishLoading(setLoadingComplete)}
      />
    );
  } else {
    return (
      <AppNavigator />
    );
  }
}

async function loadResourcesAsync() {
  await Promise.all([
    Font.loadAsync({
      'Regular': require("./assets/fonts/MRR.otf"),
      'DemiBold': require("./assets/fonts/MRSB.otf"),
      'Bold': require("./assets/fonts/MRB.otf"),
    }),
  ]);
}

function handleLoadingError(error) {
  // In this case, you might want to report the error to your error reporting
  // service, for example Sentry
  console.warn(error);
}

function handleFinishLoading(setLoadingComplete) {
  setLoadingComplete(true);
}