import React from 'react';

const translations = {
    en: {
        categories: 'Categories',
        featured: 'Featured',
        contents: 'Contents',
        share: 'Share',
        sharesomething: 'Share something new!',
        publish: 'Publish',
        nocontent: 'There is no stories',
        mycourses: "My Courses",
        nocourses: "Soon you will see your programs here",
        completed: "Completed",
        forums: "Forums",
        popularthreads: "Popular Threads",
        ranking: "Rankings",
        news: "News",
        "No Courses Available": "No Courses Available",
        "Go Back": "Go Back",
    },
    es: {
        categories: 'Categorías',
        featured: 'Destacado',
        contents: 'Contenidos',
        share: 'Compartir',
        sharesomething: 'Comparte algo nuevo!',
        publish: 'Publicar',
        nocontent: 'No hay contenidos',
        mycourses: "Mis Módulos",
        nocourses: "No tienes cursos activos actualmente",
        completed: "Completados",
        forums: "Foros",
        popularthreads: "Hilos Populares",
        ranking: "Rankings",
        news: "Novedades",
        "No Courses Available": "No hay cursos disponibles",
        "Go Back": "Volver",
    },
};

export default translations