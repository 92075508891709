import Constants from "expo-constants";
import * as Notifications from "expo-notifications";
import * as Permissions from "expo-permissions";
import React, { useEffect, useMemo, useReducer, useRef, useState } from "react";
import { Alert, AppState, Dimensions, Platform, Vibration, View } from "react-native";

import { DefaultTheme, NavigationContainer, useLinking } from "@react-navigation/native";
import { createStackNavigator } from "@react-navigation/stack";
import AuthContext from "../context/AuthContext";
import Login from "../screens/stackLogin/login";
import MainTabNavigator from "./MainTabNavigator";
// import * as Analytics from "expo-firebase-analytics";
import * as Linking from "expo-linking";

import { getAuth, onAuthStateChanged, signInWithEmailAndPassword, signOut } from "firebase/auth";
import { addDoc, collection, doc, getDoc, getFirestore, increment, serverTimestamp, updateDoc } from "firebase/firestore";
import { getStorage } from "firebase/storage";
import AuthReducer from "../reducers/AuthReducer";

import UserDataReducer from "../reducers/UserDataReducer";
import Success from "../screens/stackLogin/success";

const Stack = createStackNavigator();

const prefix = Linking.makeUrl("/");

// NAVEGACION
const config = {
	HomeScreen: "home",
	PreviewCourseScreen: "curso/:id",
	MainTabNavigator: {
		path: "",
		name: "Home",
		screens: {
			StackPublic: {
				path: "",
				screens: {
					HomeScreen: {
						path: "",
					},
					PreviewCourseScreen: {
						path: "c/:id",
					}
				},
			},
			StackContent: {
				path: "content",
				screens: {
					ContentHome: {
						path: "",
						screens: {
							NewsFeed: "noticias",
							CommunityFeed: "social",
						},
					},
					Category: {
						path: "categoria/:id",
					},
					Post: {
						path: "post/:id",
					},
					Comments: {
						path: "comentarios/:feed/post/:id",
					},
				},
			},
			StackSmartNew: {
				path: "courses",
				screens: {
					SmartHome: "",
					ActivityGroup: "/:id/section/:index/",
					Course: {
						path: "/:id/",
					},
					Post: {
						path: "curso/:advanceID/post/:id/",
					},
					CompleteCourse: {
						path: "/:id/completado/",
					},
					Exam: {
						path: "/:courseID/exam/:id/",
					},
					SingleTopic: {
						path: "/:courseID/topic/:id/",
					},
					FeaturedContents: {
						path: "/:id/featuredcontents",
					},
				},
			},
			StackItinerary: {
				path: "itinerarios",
				screens: {
					TrainingHome: {
						path: "itinerarios",
					},
					Itinerary: {
						path: "itinerarios/:id",
					},
				},
			},
			StackForum: {
				path: "foros",
				screens: {
					ForumHome: "",
					SingleForum: "foros/:id",
					SingleTopic: "foros/:forumID/tema/:id",
					CreateTopicPage: "foros/:id/nuevohilo",
				},
			},
			StackProfile: {
				path: "perfil",
				screens: {
					ProfileHome: "",
					EditProfile: "/editar",
				},
			},
			// StackRanking:{
			//   path:"",
			//   screens: {
			//     SocialAppraisal:"rankings"
			//   }
			// }
		},
	},
	Login: "login",
	Success: "success",
	ResetPass: "reset",
	Lost: "lost",
	Loading: "loading",
};

const initialWidth = Dimensions.get("window").width;
const initialHeight = Dimensions.get("window").height;

Notifications.setNotificationHandler({
	handleNotification: async () => ({
		shouldShowAlert: true,
		shouldPlaySound: false,
		shouldSetBadge: false,
	}),
});

let userSubscription = () => null

const AppNavigator = ({ navigation }) => {
	// auth().tenantId = 'Brain-bt699'

	const routeNameRef = useRef();
	const navigationRef = useRef();
	const auth = getAuth()
	const db = getFirestore()
	const storage = getStorage()

	const initialState = {
		isLoading: true,
		authed: false,
		resetPass: false,
	};

	const [initRoute, setInitRoute] = useState();
	const [isReady, setIsReady] = useState(false);
	const [logging, setLogging] = useState(false);
	const [error, setError] = useState(false);
	const [state, dispatch] = useReducer(AuthReducer, initialState);
	const [userData, dispatchUserData] = useReducer(UserDataReducer, {});
	const [tenantSelector, setTenantSelector] = useState(false)
	const [tenants, setTenants] = useState([])
	const [tenant, setTenant] = useState(null)
	const [expoPushToken, setExpoPushToken] = useState("");
	const [notification, setNotification] = useState({});
	const [session, setSession] = useState(null);
	const [isActive, setIsActive] = useState(true);
	const activeRef = useRef(true);
	const [dimensions, setDimensions] = useState({
		width: initialWidth,
		height: initialHeight,
	});

	const [customization, setCustomization] = useState({
		logo: "",
		placeholder: "https://avirato.com/wp-content/uploads/2020/02/placeholder.png",
		mainColor: "black",
		secondaryColor: "red",
		headerButtonColor: "white",
		achievements: [],
	});

	const { getInitialState } = useLinking(navigationRef, {
		prefixes: [prefix],
		config,
	});

	const onFocus = (ev) => {
		setIsActive(ev === "active");
		activeRef.current = ev === "active";
	};


	useEffect(() => {
		bootstrapAsync();
		AppState.addEventListener("change", onFocus)

		//Esta funcion se lanzará cuando se interactue con la notificación
		Notifications.addNotificationResponseReceivedListener(handleNotificationResponse);
		return () => {
			AppState.addEventListener("change", () => null)
		};
	}, []);


	// useEffect(() => {
	// 	if (userData?.id) {
	// 		Platform.OS !== "web" && registerForPushNotificationsAsync();
	// 	}
	// }, [userData]);

	const handleNotificationResponse = (response) => {
		if (response?.notification?.request?.content?.data) {
			const { screen, params } = response?.notification?.request?.content?.data;
			navigationRef.current.navigate(screen, {
				...params,
			});
		}
	};

	const registerForPushNotificationsAsync = async () => {
		if (Constants.isDevice) {
			const { status: existingStatus } = await Permissions.getAsync(Permissions.NOTIFICATIONS);
			let finalStatus = existingStatus;
			if (existingStatus !== "granted") {
				const { status } = await Permissions.askAsync(Permissions.NOTIFICATIONS);
				finalStatus = status;
			}
			if (finalStatus !== "granted") {
				alert("Failed to get push token for push notification!");
				return;
			}
			const token = await Notifications.getExpoPushTokenAsync();
			// firestore().collection()
			// console.log({ id: userData?.id, token })
			firebase.firestore().collection("users").doc(userData?.id).update({ token: true, exponentPushToken: token.data });
		} else {
			alert("Must use physical device for Push Notifications");
		}

		if (Platform.OS === "android") {
			Notifications.createChannelAndroidAsync("default", {
				name: "default",
				sound: true,
				priority: "max",
				vibrate: [0, 250, 250, 250],
			});
		}
	};

	const handleNotification = (notification) => {
		Vibration.vibrate();
		setNotification(notification);
		if (notification.origin === "selected") {
			const { screen, params } = notification.data;
			if (screen) {
				if (screen === "Conversation") {
					navigationRef.current.navigate("Forum", {
						screen: screen,
						params: params,
					});
				} else if (screen === "CourseActive") {
					// console.log(screen)
					navigationRef.current.navigate("Learning", {
						screen: screen,
						params: params,
					});
				} else {
					navigationRef.current.navigate(screen, {
						...params,
					});
				}
			}
		}
	};

	const changeFavicon = (customization) => {
		document.head || (document.head = document.getElementsByTagName("head")[0]);

		var link = document.createElement("link"),
			oldLink = document.getElementById("dynamic-favicon");

		link.id = "dynamic-favicon";
		link.rel = "shortcut icon";
		link.href = customization.logo;

		document.title = "Uno.Work";

		if (oldLink) {
			document.head.removeChild(oldLink);
		}
		document.head.appendChild(link);
	};

	const bootstrapAsync = async () => {
		onAuthStateChanged(auth, async (user) => {
			if (user) {
				const docRef = doc(db, `users`, user.uid);
				await getDoc(docRef)
					.then(async (res) => {
						if (res.exists()) {
							if (res.data().tenants.length > 1) {
								// GET ALL TENANTS DATA
								dispatchUserData({ type: "UPDATE_DATA", data: { ...res.data(), id: res.id, ref: res.ref } });
								const t = await Promise.all(res.data().tenants.map(async el => {
									const t = await getDoc(el);
									return { ...t.data(), id: t.id, ref: t.ref }
								}))
								setTenants(t)
								// const data = { usergroups: [""], ...doc.data(), id: doc.id, ref: doc.ref };
								// dispatchUserData({ type: "UPDATE_DATA", data });
								setTenantSelector(true)
							} else {
								const tenant = res.data().tenants[0];

								// GET THE ONLY TENANT DATA

								// GET USER DATA ON TENANT
								const docRef = doc(db, `${tenant.path}/users`, user.uid);
								await getDoc(docRef).then(doc => {
									if (doc.exists()) {
										const data = { ...doc.data(), id: doc.id, ref: doc.ref };
										dispatchUserData({ type: "UPDATE_DATA", data });
									}
								}).catch(err => {
									console.log(err)
								})

								await getDoc(tenant).then((doc) => {
									setTenant(tenant)
									setTenants([{ ...doc.data(), id: doc.id, ref: doc.ref }])
									setCustomization({ ...doc.data().customization });
								});
								// Platform.OS !== "web" && registerForPushNotificationsAsync()
								// const notificationSubscription = Notifications.addListener(handleNotification);
								trackSession(docRef.path, null);
							}
						} else {
							Alert.alert("No se ha encontrado ningún usuario con estas credenciales");
							authFunctions.signOut();
						}
					})
					.then(() => dispatch({ type: "RESTORE_TOKEN", authed: true, resetPass: false }))
					.catch((err) => console.log(err));
			} else {
				// No user is signed in.
				dispatch({ type: "RESTORE_TOKEN", resetPass: false });
			}
		});
	};

	const getMeasurements = () => {
		setDimensions({
			width: Dimensions.get("window").width,
			height: Dimensions.get("window").height,
		});
	};

	const authFunctions = useMemo(
		() => ({
			signIn: async (form) => {
				const email = form.identifier.trim().toLocaleLowerCase();
				const password = form.password.trim();
				setLogging(true);
				signInWithEmailAndPassword(auth, email, password)
					.then(async (re) => {
						// await Analytics.setUserId(re.user.uid);
						setLogging(false);
					})
					.then(() => dispatch({ type: "RESTORE_TOKEN", authed: true, resetPass: false }))
					.catch((err) => {
						setLogging(false);
						setError(err);
					});
			},
			signOut: async (data) => {
				signOut(auth)
					.then(async () => {
						dispatchUserData({ type: "CLEAN_DATA", data: {} });
						setTimeout(() => {
							dispatch({ type: "SIGN_OUT" });
						});
					})
					.catch((error) => {
						Alert.alert(error.message);
					});
			},
			changeTenant: async (tenant, user) => {

				setCustomization({ ...tenant.customization, ref: tenant.ref, id: tenant.id });

				const docRef = doc(db, `${tenant.ref.path}/users`, user.id);
				await getDoc(docRef).then(doc => {
					if (doc.exists()) {
						const data = { ...doc.data(), id: doc.id, ref: doc.ref };
						dispatchUserData({ type: "UPDATE_DATA", data });
					}
				})
				trackSession(docRef.path, null);
				setTenantSelector(false)
				setTenant(tenant.ref)
			},
			changeConfirmed: async (data) => {
				dispatch({ type: "CHANGE_CONFIRMED", confirmed: data });
			},
		}),
		[]
	);

	useEffect(() => {
		Platform.OS === "web" &&
			getInitialState()
				.catch(() => { })
				.then((state) => {
					if (state !== undefined) {
						setInitRoute(state);
					}
					setIsReady(true);
				});
	}, [state]);

	// Gets the current screen from navigation state
	const getActiveRouteName = (state) => {
		const route = state.routes[state.index];
		if (route.state) {
			// Dive into nested navigators
			return getActiveRouteName(route.state);
		}

		return {
			name: route.name,
			id: route.params ? route.params.id : null,
			...route,
		};
	};

	// const customAnalytics = async (currentScreen) => {
	// 	const user = await AsyncStorage.getItem("user");
	// 	if (user) {
	// 		const variables = {
	// 			pageName: currentScreen.name,
	// 			pageId: currentScreen.id,
	// 			createdAt: firebase.firestore.FieldValue.serverTimestamp(),
	// 		};
	// 		firebase
	// 			.firestore()
	// 			.collection("users")
	// 			.doc(JSON.parse(user).id)
	// 			.collection("activity")
	// 			.doc(`${moment().valueOf()}`)
	// 			.set(variables)
	// 			.then((res) => { })
	// 			.catch((err) => { });
	// 	}
	// };

	const trackSession = async (user, session) => {
		const colRef = collection(db, `${user}/sessions`);
		if (session === null) {
			console.log("START SESION")
			const date = new Date();
			const variables = {
				user: doc(db, user),
				startTimestamp: date.valueOf(),
				createdAt: serverTimestamp(),
				start: serverTimestamp(),
				end: serverTimestamp(),
				totalTime: 0,
				tracks: [],
			};
			addDoc(colRef, variables)
				.then((res) => {
					setTimeout(() => {
						trackSession(user, res.id);
					}, 20000);
				})
				.catch((err) => {
					console.log(err);
				});
		} else {
			const docRef = doc(db, `${user}/sessions`, session);
			activeRef.current &&
				updateDoc(docRef, {
					totalTime: increment(20),
					end: serverTimestamp(),
				})
					.then((res) => {
						// console.log("20 SECS", res);
					})
					.catch((err) => {
						console.log(err);
					});

			setTimeout(() => {
				trackSession(user, session);
			}, 20000);
		}
	};

	const theme = {
		...DefaultTheme,
		colors: {
			...DefaultTheme.colors,
			primary: "white",
			background: "ghostwhite",
			card: "rgb(255, 255, 255)",
			text: "rgb(28, 28, 30)",
			border: "rgb(199, 199, 204)",
		},
	};

	if (state.isLoading) {
		return null;
	}
	return (
		<NavigationContainer
			theme={theme}
			ref={navigationRef}
			initialState={initRoute}
			onStateChange={(state) => {
				const previousRouteName = routeNameRef.current;
				const currentRouteName = getActiveRouteName(state);
				if (previousRouteName !== currentRouteName.name) {
					// customAnalytics(currentRouteName)
					// Analytics.setCurrentScreen(currentRouteName.name, currentRouteName.name);
				}
				routeNameRef.current = currentRouteName.name;
			}}
		>
			<View style={{ flex: 1 }} onLayout={getMeasurements}>

				<AuthContext.Provider value={{ customization, authFunctions, userData, dispatchUserData, tenantSelector, setTenantSelector, tenant, tenants, logging, error, dimensions }}>
					<Stack.Navigator headerMode="none" mode="modal">

						{!state.authed && <Stack.Screen name="Login" options={{ animationTypeForReplace: state.authed ? "pop" : "push" }} component={Login} />}
						{!state.authed && <Stack.Screen name="Success" options={{ animationTypeForReplace: state.authed ? "pop" : "push" }} component={Success} />}
						{state.authed && <Stack.Screen name="MainTabNavigator" component={MainTabNavigator} />}
						{/* {!state.authed && <Stack.Screen name="Lost" component={Lost} />} */}
					</Stack.Navigator>
				</AuthContext.Provider>
			</View>
		</NavigationContainer>
	);
};

export default AppNavigator;

const LoadingScreen = () => {
	return <View style={{ flex: 1, backgroundColor: "black" }} />;
};
