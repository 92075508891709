import React, { useEffect, useState, useContext } from "react";
import {
  View,
  TouchableOpacity,
  Dimensions,
  Text,
  StyleSheet,
} from "react-native";
import { Ionicons } from "@expo/vector-icons";
import * as Animatable from "react-native-animatable";
import { useSafeArea } from 'react-native-safe-area-context';
import AuthContext from '../context/AuthContext'
import { Link, useLinkTo } from "@react-navigation/native";



// Gets the current screen from navigation state
const getActiveRouteName = state => {
  const route = state.routes[state.index];
  if (route) {
    if (route.state) {
      // Dive into nested navigators
      return getActiveRouteName(route.state);
    }

    return route.name;
  } else {
    return null
  }
};

const getActiveRouteIndex = state => {
  return state.index;
};

const MainBottomNavBar = ({ titles, state, navigation }) => {

  const { authFunctions, userData, dimensions, customization } = useContext(AuthContext);
  const currentRouteName = getActiveRouteName(state);
  const currentRouteIndex = getActiveRouteIndex(state);

  const insets = useSafeArea();

  const [zonas, setZonas] = useState({})

  const footerBackgroundColor = customization.footerBackgroundColor || "white"
  const footerButtonColorActive = customization.headerElementsColor
  const footerButtonColorInctive = customization.accentColor


  useEffect(() => {
    getZonas()
  }, [])

  const getZonas = async () => {

    const zonas = userData.zonas

    if (zonas === null) {
      authFunctions.signOut()
    } else {
      setZonas(zonas)
    }
  }

  return (
    zonas &&
      zonas.content &&
      !zonas.farming &&
      !zonas.training &&
      !zonas.smartlearning ? null : (
      <Animatable.View
        duration={300}
        animation={(
          currentRouteName === "Post" ||
          currentRouteName === "Comments" ||
          currentRouteName === "PostSmart" ||
          currentRouteName === "CourseActive" ||
          currentRouteName === "Course" ||
          currentRouteName === "Exam" ||
          currentRouteName === "SingleTopic" ||
          currentRouteName === "Mission" ||
          currentRouteName === "ChallengePage" ||
          currentRouteName === "Conversation" ||
          currentRouteName === "CompleteCourse") ? "fadeOutDown" : "fadeInUp"}
      >
        <View
          style={[{
            backgroundColor: footerBackgroundColor,
            flexDirection: "row",
            justifyContent: "space-between",
            width: insets.left === 0 ? "100%" : null,
            borderRadius: 0,
            marginLeft: insets.left === 0 ? "0%" : insets.left,
            marginRight: insets.right === 0 ? "0%" : insets.right,
            position: 'absolute',
            bottom: 0,
            paddingBottom: insets.bottom === 0 ? 0 : insets.bottom
          }, styles.shadow]}>

          {zonas.content && (
            <TouchableOpacity
              onPress={() => {
                navigation.jumpTo("StackContent", { screen: "ContentHome" })
              }}
              style={{
                // backgroundColor: currentRouteIndex === 0 ? mainColor : "white",
                borderRadius: 0,
                flex: 1,
                // height: 50,
                alignItems: "center",
                justifyContent: "center"
              }}
            >
              <Ionicons
                name={currentRouteIndex !== 1 ? "md-home" : "md-home"}
                size={27}
                style={{
                  opacity: currentRouteIndex !== 1 ? 0.6 : 1,
                  color: currentRouteIndex !== 1 ? footerButtonColorInctive : footerButtonColorActive
                }}
              />
              {titles && <Text style={{ fontFamily: "Regular", textAlign: "center", fontSize: 8, color: currentRouteIndex === 1 ? footerButtonColorActive : footerButtonColorInctive }}>Micro Learning</Text>}
              {/* tintColor: this.state.focus!=='Contenidos'?'gray' : constantes('secondarycolor'), */}
            </TouchableOpacity>
          )}






          {zonas.smartlearning && (
            <TouchableOpacity
              onPress={() => {
                navigation.jumpTo("StackSmartNew", { screen: "SmartHome" })
              }}
              style={{
                height: 50,
                flex: 1,
                alignItems: "center",
                justifyContent: "center",
                borderRadius: 0,
                // backgroundColor: currentRouteIndex === 2 ? mainColor : "white",
              }}
            >
              <Ionicons
                name={
                  currentRouteIndex !== 2 ? "md-book" : "md-book"
                }
                size={27}
                style={{
                  marginTop: 0,
                  opacity: currentRouteIndex !== 2 ? 0.6 : 1,
                  color: currentRouteIndex !== 2 ? footerButtonColorInctive : footerButtonColorActive
                }}
              />
              {titles && <Text style={{ fontFamily: "Regular", textAlign: "center", fontSize: 8, color: currentRouteIndex !== 2 ? footerButtonColorInctive : footerButtonColorActive }}>Smart Learning</Text>}
            </TouchableOpacity>
          )}

          {zonas.forum && (
            <TouchableOpacity
              onPress={() => { navigation.jumpTo("StackForum") }}
              style={{
                height: 50,
                flex: 1,
                alignItems: "center",
                justifyContent: "center",
                borderRadius: 0,
                // backgroundColor: currentRouteIndex === 4 ? mainColor : "white",
              }}
            >
              <Ionicons
                name={
                  currentRouteIndex !== 4 ? "md-chatbubbles" : "md-chatbubbles"
                }
                size={27}
                style={{
                  marginTop: 3,
                  opacity: currentRouteIndex !== 4 ? 0.6 : 1,
                  color: currentRouteIndex !== 4 ? footerButtonColorInctive : footerButtonColorActive
                }}
              />
              {titles && <Text style={{ fontFamily: "Regular", textAlign: "center", fontSize: 8, color: currentRouteIndex !== 4 ? footerButtonColorInctive : footerButtonColorActive }}>Foros</Text>}
            </TouchableOpacity>
          )}


          {zonas.profile && (
            <TouchableOpacity
              onPress={() => { navigation.jumpTo("StackProfile") }}
              style={{
                flex: 1,
                // backgroundColor: currentRouteIndex === 5 ? mainColor : "white",
                height: 50,
                borderRadius: 0,
                alignItems: "center",
                justifyContent: "center"
              }}
            >
              <Ionicons
                name={currentRouteIndex !== 6 ? "person-circle" : "person-circle"}
                size={30}
                style={{
                  marginTop: 0,
                  opacity: currentRouteIndex !== 6 ? 0.6 : 1,
                  color: currentRouteIndex !== 6 ? footerButtonColorInctive : footerButtonColorActive
                  // : constantes("secondarycolor")
                }}
              />
              {titles && <Text style={{ fontFamily: "Regular", textAlign: "center", fontSize: 8, color: currentRouteIndex !== 6 ? footerButtonColorInctive : footerButtonColorActive }}>Mi Perfil</Text>}
            </TouchableOpacity>
          )}

        </View>
      </Animatable.View>
    )
  );
}

const styles = StyleSheet.create({
  shadow: {
    shadowColor: "black",
    shadowOpacity: 0.15,
    shadowOffset: { width: 0, height: -3 },
    shadowRadius: 10,
    elevation: 10
  }
});

export default MainBottomNavBar;
