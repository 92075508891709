import React, { useContext, useEffect, useRef } from "react";
import { View, Image, StyleSheet, TouchableWithoutFeedback } from "react-native";
import { LinearGradient } from "expo-linear-gradient";
import { useHover, useFocus, useActive } from "react-native-web-hooks";
import StyledText from "../common/StyledText";
import SecureImage from "../common/SecureImage";
import AuthContext from "../../context/AuthContext";


const CourseCardVertical = ({ image = "", isNew = false, title = "", teacher = "", summary = "", category = null, height = 430, style = {}, onPress = () => null }) => {
	const ref = useRef(null);
	const { dimensions } = useContext(AuthContext)
	const isHovered = useHover(ref);

	function stripHtml(html) {
		let tmp = document.createElement("DIV");
		tmp.innerHTML = html;
		return tmp.textContent || tmp.innerText || "";
	}
	

	return (
		<TouchableWithoutFeedback onPress={onPress}>
			<View
				ref={ref}
				style={[styles.shadow, { transform: [{ scale: isHovered ? 1.05 : 1 }], cursor: "pointer", transitionProperty: "all", transitionDuration: "200ms", marginHorizontal: ".5%", marginBottom: 10, height: height, backgroundColor: "white", width: dimensions.width > 1000 ? "31%" : "95%", borderRadius: 7, ...style }]}
			>
				<View style={{ height: "55%", width: "100%" }}>
					<SecureImage
						style={{ borderTopLeftRadius: 7, borderTopRightRadius: 7, flex: 1, position: "absolute", top: 0, left: 0, right: 0, bottom: 0, zIndex: 1 }}
						uri={image}
					/>
					{category && (
						<View
							style={{
								height: 30,
								zIndex: 2,
								paddingHorizontal: 15,
								borderRadius: 5,
								backgroundColor: "white",
								position: "absolute",
								justifyContent: "center",
								top: 10,
								left: 10,
							}}
						>
							<StyledText style={{ fontSize: 14 }}>{category}</StyledText>
						</View>
					)}
				</View>
				<View style={{ padding: 15, flex: 1, justifyContent: "space-between" }}>
					<View style={{ flex: 1 }}>
						<StyledText numberOfLines={2} style={{ fontSize: 24, marginBottom: 10 }}>
							{title}
						</StyledText>
						{summary && <StyledText numberOfLines={3} style={{ fontSize: 16 }}>
							{stripHtml(summary)}
						</StyledText>}
					</View>
					<View style={{ flexDirection: "row", justifyContent: "space-between", alignItems: "center" }}>
						<StyledText style={{ fontSize: 16, color: "grey" }}>{teacher}</StyledText>

						{isNew && (
							<LinearGradient colors={["#F55058", "#F77D4D"]} style={{ height: 30, justifyContent: "center", borderRadius: 5, paddingHorizontal: 10 }}>
								<StyledText style={{ color: "white", fontSize: 16 }}>Nuevo</StyledText>
							</LinearGradient>
						)}
					</View>
				</View>
			</View>
		</TouchableWithoutFeedback>
	);
};

export default CourseCardVertical;

const styles = StyleSheet.create({
	shadow: {
		shadowColor: "rgba(0, 0, 0, 1)",
		shadowOffset: { width: 0, height: 7 },
		shadowOpacity: 0.1,
		shadowRadius: 10,
		elevation: 10,
	},
});
