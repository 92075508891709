import { Ionicons } from "@expo/vector-icons";
import { getAuth, sendSignInLinkToEmail, signInWithEmailAndPassword } from "firebase/auth";
import React, { useContext, useEffect, useState } from "react";
import { ActivityIndicator, Alert, Image, ImageBackground, KeyboardAvoidingView, Text, TextInput, TouchableOpacity, View } from "react-native";
import logo from "../../assets/images/logo.png";
import StyledText from "../../components/common/StyledText";
import AuthContext from "../../context/AuthContext";

const login = ({ navigation }) => {
	const [checking, setChecking] = useState(false);
	const [sent, setSent] = useState(false);
	const [error, setError] = useState(null);
	const { authFunctions, userData, logging } = useContext(AuthContext);
	const auth = getAuth()
	const [form, setForm] = useState({
		identifier: "",
		password: "",
	});

	const [withPassword, setWithPassword] = useState(false);

	const actionCodeSettings = {
		url: "https://inserta.hub7s.com/success",
		// url: "https://unoworknext.web.app/success",
		// url: "http://localhost:19006/success",
		handleCodeInApp: true,
	};

	useEffect(() => {
		userData.id && navigation.navigate("App");
	}, [])

	const handleLogin = async () => {
		// await Analytics.logEvent("Login", {
		// 	name: "login",
		// 	screen: "login",
		// 	purpose: "Login into the app",
		// });

		setChecking(true);
		if (withPassword) {
			signInWithEmailAndPassword(auth, form.identifier, form.password)
				.then(async (re) => {
					if (!re.user.emailVerified) {
						navigation.navigate("SmartHome")
					}
				}).catch(err => {
					const code = err.code;
					if (code === "auth/wrong-password") {
						setError({ message: "Usuario o Contraseña Incorrectos. Si es la primera vez que accedes a la nueva plataforma de ONFormación, pero ya estás registrado, deberás recuperar contraseña antes de acceder o emplear el botón de acceso sin contraseña" });
					} else if (code === "auth/invalid-email") {
						setError({ message: "Introduce un email válido" });
					} else if (code === "auth/user-not-found") {
						setError({ message: "Usuario o Contraseña Incorrectos" });
					} else if (code === "auth/too-many-requests") {
						setError({ message: "Demasiados intentos. Inténtalo más tarde" });
					} else {
						setError({ message: "Ocurrió un error, inténtalo de nuevo" });
					}
					setChecking(false)
				})
		} else {
			sendSignInLinkToEmail(auth, form.identifier, actionCodeSettings)
				.then(() => {
					window.localStorage.setItem("emailForSignIn", form.identifier);
					setChecking(false);
					setSent(true);
				})
				.catch((error) => {
					const errorMessage = error.message;
					Alert.alert(errorMessage);
					setChecking(false);
					setSent(false);
					setError(errorMessage);
				});
		}


		// authFunctions.signIn({ identifier: form.identifier, password: form.password });
	};

	return (
		<KeyboardAvoidingView style={{ flex: 1, alignItems: "center", backgroundColor: "white", height: "100%" }} behavior="padding" enabled>
			<View style={{ position: "absolute", filter: "hue-rotate(290deg)", zIndex: 1, height: "100%", width: "100%", top: 0, left: 0, right: 0, bottom: 0 }}>
				{/* <Video
					source={{ uri: "https://firebasestorage.googleapis.com/v0/b/uno-work.appspot.com/o/general%2Fintrum.mp4?alt=media&token=ff8a9e38-8cfd-4284-b0ca-e40b19f210bb" }}
					useNativeControls={false}
					shouldPlay={true}
					resizeMode="cover"
					isLooping={true}
					style={{ flex: 1, height: "100%" }}
				/> */}
			</View>
			<ImageBackground
				style={{
					flex: 1,
					width: "100%",
					alignItems: "center",
					justifyContent: "center",
					zIndex: 2,
				}}
				source={{ uri: "https://images.pexels.com/photos/572897/pexels-photo-572897.jpeg?auto=compress&cs=tinysrgb&w=1260&h=750&dpr=2" }}
				resizeMethod="resize">
				<View style={{ width: "100%", maxWidth: 600, flex: 1, alignItems: "center", paddingHorizontal: 20, justifyContent: "center" }}>
					<View style={{ height: 150, width: 300, marginBottom: 20 }}>
						<Image style={{ resizeMode: "contain", height: 150, width: 300 }} source={logo} />
					</View>
					{sent ?
						<View>
							<StyledText style={{ marginBottom: 20, textAlign: "center", fontSize: 20, color: "white" }}>Se ha enviado un correo de verificación para acceder a la plataforma, si no lo ha recibido compruebe la carpeta de Spam</StyledText>
							<TouchableOpacity
								style={{
									height: 50,
									marginTop: 5,
									paddingHorizontal: 50,
									borderRadius: 25,
									alignItems: "center",
									justifyContent: "center",
									backgroundColor: "black",
								}}
								onPress={() => setSent(false)}>

								<Text
									style={{
										color: "white",
										fontSize: 18,
										fontFamily: "DemiBold",
									}}>
									Volver a Enviar
								</Text>
							</TouchableOpacity>
						</View>
						:
						<>
							<TextInput
								textContentType="emailAddress"
								clearButtonMode="while-editing"
								autoCapitalize="none"
								placeholder="Email"
								keyboardType="email-address"
								placeholderTextColor={"gainsboro"}
								style={{
									borderColor: error ? "#E9312B" : "gainsboro",
									color: "black",
									height: 55,
									width: "100%",
									backgroundColor: "white",
									fontFamily: "Regular",
									fontSize: 18,
									padding: 15,
									borderRadius: 15,
									borderWidth: error ? 2 : 1,
									outlineWidth: 0,
								}}
								onChangeText={(text) => setForm({ ...form, identifier: text.toLowerCase().trim() })}
								value={form && form.identifier}
							/>
							{withPassword ? <TextInput
								textContentType="password"
								autoCapitalize="none"
								placeholder="Contraseña"
								placeholderTextColor={"gainsboro"}
								style={{
									marginTop:15,
									borderColor: error ? "#E9312B" : "gainsboro",
									color: "black",
									height: 55,
									width: "100%",
									backgroundColor: "white",
									fontFamily: "Regular",
									fontSize: 18,
									padding: 15,
									borderRadius: 15,
									borderWidth: error ? 2 : 1,
									outlineWidth: 0,
								}}
								onChangeText={(text) => setForm({ ...form, password: text })}
								value={form && form.password}
								secureTextEntry={true}
							/> : null}

							<Text style={{ fontFamily: "Regular", marginTop: 15, marginBottom: 10 }}>{error && error.message}</Text>
							<TouchableOpacity
								style={{
									height: 50,
									marginTop: 5,
									paddingHorizontal: 50,
									borderRadius: 25,
									alignItems: "center",
									justifyContent: "center",
									backgroundColor: "black",
								}}
								onPress={handleLogin}>
								{checking ? (
									<ActivityIndicator color={"white"} />
								) : (
									<Text
										style={{
											color: "white",
											fontSize: 18,
											fontFamily: "DemiBold",
										}}>
										Acceder
									</Text>
								)}
							</TouchableOpacity>

						</>}

					<TouchableOpacity style={{ flexDirection: "row", alignItems: "center" }} onPress={() => {
						setError(false)
						setWithPassword(!withPassword)
						// setResetPassword(false)
					}}>
						<Ionicons name={!withPassword ? "lock-closed-outline" : "mail-outline"} style={{ marginRight: 5 }} color="white" size={25} />
						<Text style={{ color: "white", fontSize: 18, fontFamily: "DemiBold" }}>{!withPassword ? "Acceso con Email / Contraseña" : "Acceso sin Contraseña"}</Text>
					</TouchableOpacity>

				</View>
			</ImageBackground>
		</KeyboardAvoidingView>
	);
};

export default login;
